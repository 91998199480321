import React from "react";
import "./orderTableCell.scss";
import OrderTableLabel from "../OrderTableLabel/OrderTableLabel";
import OrderTableProduct from "../OrderTableProduct/OrderTableProduct";
import OrderPopOver from "../../components/atoms/OrderPopOver/OrderPopOver";
import { MobileView } from "react-device-detect";

const OrderTableCell = ({
  order,
  buttons,
  breakM,
  setActiveItem,
  setDeleteItem,
}) => {
  return (
    <div className={`order-table-cell ${breakM && "break-m"}`}>
      <div className="left">
        <OrderTableProduct />
        <div className="labels">
          <div className="label">
            <div className="txt">{order.name}</div>
            <OrderTableLabel txt={order.tracking_number} />
          </div>
        </div>
        <MobileView>
          {order.status === "AWAITING" && (
            <OrderPopOver
              item={order}
              setActiveItem={setActiveItem}
              setDeleteItem={setDeleteItem}
            />
          )}
        </MobileView>
      </div>

      <div className="right">{buttons}</div>
    </div>
  );
};

export default OrderTableCell;
