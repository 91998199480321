import React, { useState } from "react";
import "./cardDetails.scss";
import ListItem from "../../../ds/ListItem/ListItem";
import { usePaymentCardsQuery } from "../../../hooks/cards";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as MasterCardIcon } from "../../../icons/mc.svg";
import { ReactComponent as AmexIcon } from "../../../icons/amex.svg";
import { ReactComponent as VisaIcon } from "../../../icons/visa.svg";
import { ReactComponent as UnknownIcon } from "../../../icons/unknown.svg";
import { ReactComponent as UpArrowIcon } from "../../../icons/up-a.svg";
import { ReactComponent as DownArrowIcon } from "../../../icons/down-a.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check-g.svg";

const CardDetails = ({ cardInfo, setCardInfo }) => {
  const [clicked, setClicked] = useState(false);
  const { data: paymentCards } = usePaymentCardsQuery();

  const detectIcon = (card) => {
    if (card?.cardType === "mc") {
      return <MasterCardIcon />;
    } else if (card?.cardType === "amex") {
      return <AmexIcon />;
    } else if (card?.cardType === "visa") {
      return <VisaIcon />;
    } else {
      return <UnknownIcon />;
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className={`card-details ${clicked && "active"}`}>
      {paymentCards !== undefined && paymentCards.length > 0 && (
        <ListItem
          hasBorder={clicked ? false : true}
          hasPadding={clicked ? false : true}
          hasIcon={true}
          hasHover={clicked ? false : true}
          headline={`*** ${cardInfo?.mask?.slice(-4)}`}
          icon={detectIcon(cardInfo)}
          buttons={
            <>
              {cardInfo.isDefault && (
                <OrderTableLabel
                  txt={Returntext(contentManagementData, "default", lang)}
                />
              )}
              {clicked ? <UpArrowIcon /> : <DownArrowIcon />}
            </>
          }
          onClick={() => setClicked(!clicked)}
        />
      )}

      {clicked &&
        paymentCards !== undefined &&
        paymentCards.length > 0 &&
        paymentCards.map((card, i) => (
          <React.Fragment key={i}>
            <div className="break" />
            <ListItem
              hasBorder={false}
              hasPadding={false}
              hasIcon={true}
              hasHover={false}
              headline={`*** ${card.mask.slice(-4)}`}
              icon={detectIcon(card)}
              onClick={() => {
                setCardInfo(card);
                setClicked(false);
              }}
              buttons={
                <>
                  {card.isDefault && (
                    <OrderTableLabel
                      txt={Returntext(contentManagementData, "default", lang)}
                    />
                  )}
                  {card.id === cardInfo.id && <CheckIcon />}
                </>
              }
            />
          </React.Fragment>
        ))}
    </div>
  );
};

export default CardDetails;
