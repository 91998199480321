import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";
import refreshAccessToken from "./refresh";

const fetchOrderPrices = async (orderData) => {
  try {
    const access_token = Cookies.get("access_token");
    const refresh_token = Cookies.get("refresh_token");

    if (refresh_token && access_token) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/getOrderPrices?accessToken=${access_token}`,
        orderData
      );
      return response.data;
    } else if (refresh_token) {
      refreshAccessToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/getOrderPrices?accessToken=${access_token}`,
        orderData
      );
      return response.data;
    } else {
      localStorage.setItem("user", null);
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      return null;
    }
  } catch (err) {
    console.log(err);
  }
};

const useOrderPrices = (orderData) => {
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["orderPrices", orderData],
    () => fetchOrderPrices(orderData)
  );

  const refetchOrderPrices = () => {
    refetch();
  };

  return { data, isLoading, isError, error, refetchOrderPrices };
};

export default useOrderPrices;
