import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";

const fetchPaymentCards = async () => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_PAYMENTS_URL
    }/payments/cards?accessToken=${Cookies.get("access_token")}`,
    {
      headers: {
        "x-public-key": process.env.REACT_APP_PUBLIC_KEY,
      },
    }
  );
  return response.data;
};

const deletePaymentCard = async (cardId) => {
  const response = await axios.delete(
    `${
      process.env.REACT_APP_PAYMENTS_URL
    }/payments/card/${cardId}?accessToken=${Cookies.get("access_token")}`,
    {
      headers: {
        "x-public-key": process.env.REACT_APP_PUBLIC_KEY,
      },
    }
  );
  return response.data;
};

export const usePaymentCardsQuery = () => {
  const queryKey = [
    "paymentCards",
    Cookies.get("access_token"),
    process.env.REACT_APP_PUBLIC_KEY,
  ];
  const query = useQuery(queryKey, () =>
    fetchPaymentCards(
      Cookies.get("access_token"),
      process.env.REACT_APP_PUBLIC_KEY
    )
  );

  const refetchPaymentCards = async () => {
    await query.refetch();
  };

  return { ...query, refetchPaymentCards };
};

export const useDeletePaymentCardMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((cardId) => deletePaymentCard(cardId), {
    onSuccess: () => {
      // Refetch payment cards after successful deletion
      queryClient.invalidateQueries([
        "paymentCards",
        Cookies.get("access_token"),
        process.env.REACT_APP_PUBLIC_KEY,
      ]);
    },
  });
};
