import React from "react";
import "./personalDetailsBox.scss";
import AccountDetailsBoxTitle from "../../atoms/AccountDetailsBoxTitle/AccountDetailsBoxTitle";
import ListItem from "../../../ds/ListItem/ListItem";

const txtStyle = {
  color: "#000",
  textAlign: "right",
  fontFamily: "Helvetica Neue LT GEO",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};

const CompanyDetailsBox = () => {
  const userData = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="company-details-box">
      <div className="personal">
        <AccountDetailsBoxTitle main={"Company details"} sub={""} />

        <ListItem
          hasBorder={false}
          hasPadding={false}
          hasIcon={false}
          headline={"Company ID"}
          description={""}
          buttons={<span style={txtStyle}>{userData.company_id}</span>}
        />
        <div className="break" />

        <ListItem
          hasBorder={false}
          hasPadding={false}
          hasIcon={false}
          headline={"Company Info"}
          description={""}
          buttons={<span style={txtStyle}>{userData.company_info}</span>}
        />
      </div>
    </div>
  );
};

export default CompanyDetailsBox;
