import React from "react";
import "./infoBox.scss";
import InfoBoxItem from "../../atoms/InfoBoxItem/InfoBoxItem";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";

const InfoBox = () => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="info-box">
      <InfoBoxItem
        main={Returntext(contentManagementData, "Signup_righttext_1", lang)}
        sub={Returntext(contentManagementData, "Signup_righttext_1_2", lang)}
      />

      <InfoBoxItem
        main={Returntext(contentManagementData, "Signup_righttext_2_1", lang)}
        sub={Returntext(contentManagementData, "Signup_righttext_2_2", lang)}
      />

      <InfoBoxItem
        main={Returntext(contentManagementData, "Signup_righttext_3_1", lang)}
        sub={Returntext(contentManagementData, "Signup_righttext_3_2", lang)}
      />

      <InfoBoxItem
        main={Returntext(contentManagementData, "Signup_righttext_4_1", lang)}
        sub={Returntext(contentManagementData, "Signup_righttext_4_2", lang)}
      />

      <InfoBoxItem
        main={Returntext(contentManagementData, "Signup_righttext_5_1", lang)}
        sub={Returntext(contentManagementData, "Signup_righttext_5_2", lang)}
      />
    </div>
  );
};

export default InfoBox;
