import { useEffect, useRef } from "react";
import BoldTextLanding from "../LandingText/BoldtextLanding";
import { deliveryArrow, map } from "../../../assets/landing";
function DeliveryMap({ text }) {
  const arrowRef = useRef(null);
  useEffect(() => {
    const currentArrow = arrowRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (currentArrow) {
      observer.observe(currentArrow);
    }

    return () => {
      if (currentArrow) {
        observer.unobserve(currentArrow);
      }
    };
  }, []);

  return (
    <div className="mapContainer">
      <BoldTextLanding text={text} text_color="#000000" text_size="20px" />
      <div className="mapcontainer_child">
        <img
          ref={arrowRef} // Use the ref here
          className="arrow" // Initial class without animation
          alt=""
          src={deliveryArrow}
        />
        <img alt="" src={map} />
      </div>
    </div>
  );
}

export default DeliveryMap;
