import React from "react";
import "./signInFormHeaderSubTxt.scss";
import { Link } from "react-router-dom";

const SignInFormHeaderSubTxt = ({ txt, linkTxt, link }) => (
  <span className="sign-in-form-header-sub-txt">
    <div className="txt">{txt}</div>
    <Link to={link} className="link">
      {linkTxt}
    </Link>
  </span>
);

export default SignInFormHeaderSubTxt;
