import React from "react";
import "./listItem.scss";

const ListItem = ({
  icon,
  headline,
  description,
  buttons,
  hasBorder,
  hasPadding,
  isTransparent,
  hasHover,
  hasIcon,
  style,
  onClick,
  mode,
  gray,
  active,
}) => {
  return (
    <div
      className={`list-item ${hasBorder && "has-border"} ${
        hasPadding && "has-padding"
      } ${hasHover && "has-hover"} ${mode === "active" && "active"} ${
        gray && "gray"
      } ${active && "active"}`}
      style={style}
      onClick={onClick}
    >
      <div className="list-item-wrap">
        <div className="info">
          {hasIcon && icon}
          <div className="txts">
            <div className="headline">{headline}</div>
            {description !== "" && (
              <div className="description">{description}</div>
            )}
          </div>
        </div>

        <div className="buttons">{buttons}</div>
      </div>
    </div>
  );
};

export default ListItem;
