import React from "react";
import AuthFormHeader from "../AuthFormHeader/AuthFormHeader";
import SetPassword from "../SetPassword/SetPassword";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";

const SignUpSetPassword = ({
  password,
  setPassword,
  passwordCheck,
  setPasswordCheck,
  email,
  phone,
  activeMode,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <>
      <AuthFormHeader
        mainTxt={Returntext(contentManagementData, "SignUpSetPassword", lang)}
        subTxt={Returntext(
          contentManagementData,
          "PasswordRecoveryTitleStep2Sub",
          lang
        )}
      />
      <SetPassword
        email={email}
        phone={phone}
        password={password}
        setPassword={setPassword}
        passwordCheck={passwordCheck}
        setPasswordCheck={setPasswordCheck}
        activeMode={activeMode.toUpperCase()}
      />
    </>
  );
};

export default SignUpSetPassword;
