import React, { useState } from "react";
import "./accountSettings.scss";
import AccountSettingsTitle from "../../components/atoms/AccountSettingsTitle/AccountSettingsTitle";
import AccountDetailsBox from "../../components/molecules/AccountDetailsBox/AccountDetailsBox";
import PersonalDetailsBox from "../../components/molecules/PersonalDetailsBox/PersonalDetailsBox";
import CompanyDetailsBox from "../../components/molecules/PersonalDetailsBox/CompanyDetailsBox";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../Landings/common/returnText";
import ServiceBox from "../../components/molecules/ServiceBox/ServiceBox";
import { ReactComponent as RepacIcon } from "../../icons/repac.svg";
import { useUserData } from "../../hooks/users";
import Cookies from "js-cookie";
import axios from "axios";

const AccountSettings = ({ setPopUpActive }) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const [promo, setPromo] = useState(userData?.marketing_permissions);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const { refetch } = useUserData();
  const access_token = Cookies.get("access_token");

  const updateCustomerPromo = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/customers/updateCustomer/${userData.id}?accessToken=${access_token}`,
        {
          marketing_permissions: !promo,
        }
      );
      console.log("Customer updated successfully!");
      refetch();
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };

  return (
    <div className="account-settings">
      <AccountSettingsTitle
        main={Returntext(contentManagementData, "settingsHeadline", lang)}
        sub={Returntext(contentManagementData, "settingsSubheadline", lang)}
      />
      <div className="main-content">
        <AccountDetailsBox setPopUpActive={setPopUpActive} />
        {userData.customerType === "PERSONAL" ? (
          <PersonalDetailsBox />
        ) : (
          <CompanyDetailsBox />
        )}
        <ServiceBox
          main={Returntext(contentManagementData, "PromoHeadline1", lang)}
          sub={Returntext(contentManagementData, "PromoBody1", lang)}
          value={promo}
          setter={setPromo}
          updateCustomerData={updateCustomerPromo}
        />
      </div>
    </div>
  );
};

export default AccountSettings;
