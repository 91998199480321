import React from "react";
import "./actionTabBtnV2.scss";
import ActionTabBtnCount from "../ActionTabBtnCount/ActionTabBtnCount";

const ActionTabBtnV2 = ({
  txt,
  svg: SvgIcon,
  svgActive: SvgActiveIcon,
  count,
  active,
  onClick,
  inactive,
}) => {
  return (
    <div
      className={`action-tab-btn-v2 ${active ? "active" : ""}`}
      onClick={onClick}
    >
      <div className="icon-container">
        <SvgActiveIcon className={`icon ${!active && "dis"}`} />
        <SvgIcon className={`icon ${active && "dis"}`} />
      </div>
      <div className="bottom">{txt}</div>
      <ActionTabBtnCount
        inactive={inactive}
        active={active ? "active" : ""}
        count={count}
      />
    </div>
  );
};

export default ActionTabBtnV2;
