import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import styled from "styled-components";
import { LoadScript } from "@react-google-maps/api";
import store from "./redux/store";
import { Provider } from "react-redux";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#6AD67D",
    color: "#000",
    fontFamily: "Helvetica Neue LT GEO",
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#E5484D",
    color: "#FFFDFA",
    fontFamily: "Helvetica Neue LT GEO",
  },
}));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="800290427904-1096a6nl0kruts33r9o73s4rjtkm5hif.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        autoHideDuration={2000}
        maxSnack={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <LoadScript
          googleMapsApiKey="AIzaSyBnt_0w-bvEwV86R3tNOoW4qAcUzWYFGRI"
          libraries={["places"]}
          disableDefaultUI={true}
          loadingElement={() => <></>}
        >
          <Provider store={store}>
            <App />
          </Provider>
        </LoadScript>
      </SnackbarProvider>
    </QueryClientProvider>
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);
