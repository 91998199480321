import React from "react";
import "./orderTableLabel.scss";
const OrderTableLabel = ({ txt }) => {
  return (
    <div className="order-table-label">
      <div className="txt-l">{txt}</div>
    </div>
  );
};

export default OrderTableLabel;
