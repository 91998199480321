import { useQuery } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";

export const useContent = () => {
  const access_token = Cookies.get("access_token");

  const { isLoading, error, data, refetch } = useQuery(
    "orders",
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/content-management?accessToken=${access_token}`
      );
    },
    {
      staleTime: Infinity, // Never refetch unless the query is invalidated or the page is reloaded
      cacheTime: Infinity, // Keep the cache data as long as possible
    }
  );

  let contentManagementData = data ? data.data : [];
  let sliderData = contentManagementData
    ? contentManagementData.filter((item) => item.typeOfInput === "slider")
    : [];

  let restrictedData = contentManagementData
    ? contentManagementData.filter(
        (item) =>
          item.page === "Restricted_Items" &&
          item.identifier !== "Restricted_Items_Footer" &&
          item.identifier !== "Restricted_Items_Header" &&
          item.identifier !== "Restricted_Items_SubHeader"
      )
    : [];

  let acordeonData = contentManagementData
    ? contentManagementData.filter((item) => item.typeOfInput === "acordeon")
    : [];

  return {
    contentManagementData,
    sliderData,
    restrictedData,
    acordeonData,
    contentIsLoading: isLoading,
    contentIsError: error,
    refetchContent: refetch, // Return refetch function if needed
  };
};

export const useLanguages = () => {
  const initialLanguage = localStorage.getItem("language") || "ka";
  const { data, refetch } = useQuery(
    "lang",
    () => {
      return initialLanguage;
    },
    {
      staleTime: Infinity, // Never refetch unless the query is invalidated or the page is reloaded
      cacheTime: Infinity, // Keep the cache data as long as possible
    }
  );

  let lang = data;
  return {
    lang,
    refetchLang: refetch, // Return refetch function if needed
  };
};
