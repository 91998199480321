import React from "react";
import "./shippingAddressHeader.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as USAIcon } from "../../../icons/country/usa.svg";

const ShippingAddressHeader = () => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="shipping-address-header">
      <div className="title">
        <div className="main">
          {Returntext(contentManagementData, "ShippingAddressTitle", lang)}
        </div>
        <div className="sub">
          {Returntext(contentManagementData, "ShippingAddressSub", lang)}
        </div>
      </div>

      <div className="countries">
        <BtnStandardIconText
          mode={"active"}
          txt={Returntext(contentManagementData, "usa", lang)}
          disabled={false}
          size={"standard"}
          icon={true}
          svg={<USAIcon />}
          iconPosition={"left"}
          mainColor={"grayBorder"}
        />
        {/* <BtnStandardIconText
          mode={country === "turkey" ? "active" : ""}
          txt={"Turkey"}
          disabled={false}
          size={"standard"}
          icon={true}
          svg={TurkeyIcon}
          iconPosition={"left"}
          mainColor={"grayBorder"}
          onClick={() => setCountry("turkey")}
        />
        <BtnStandardIconText
          mode={country === "china" ? "active" : ""}
          txt={"China"}
          disabled={false}
          size={"standard"}
          icon={true}
          svg={ChinaIcon}
          iconPosition={"left"}
          mainColor={"grayBorder"}
          onClick={() => setCountry("china")}
        /> */}
      </div>
    </div>
  );
};

export default ShippingAddressHeader;
