import React, { useEffect, useRef, useState } from "react";
import KeyNumberItem from "../../../../components/atoms/LandingKeyNumber/KeyNumberItem";
import BoldTextLanding from "../../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../../components/atoms/LandingText/LightTextLanding";
import LandingContainer from "../../../../components/atoms/landingContainer/landingContainer";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";
import Lottie from "lottie-react";
import carAnimation from "../../../../assets/animations/Car Icon/data.json";
import mapAnimation from "../../../../assets/animations/Map Icon/data.json";

function SimpleDelivery() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const carRef = useRef(null);
  const [loopCar, setLoopCar] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !loopCar) {
            setLoopCar(true);
            setTimeout(() => {
              setLoopCar(false);
            }, 10);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (carRef.current) observer.observe(carRef.current);

    return () => observer.disconnect();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="simpleDelivery">
      <div className="container_inside">
        <div className="text boldFont">
          {Returntext(contentManagementData, "prices_delivery-title", lang)
            .split(" ")
            .map((word, index) => {
              if (index === 2) {
                return (
                  <React.Fragment key={index}>
                    <span style={{ color: "#6AD67D" }}>{" " + word}</span>
                  </React.Fragment>
                );
              }
              return <span key={index}> {word}</span>;
            })}
        </div>

        <div className="card1 flex_between">
          <LandingContainer
            customStyles={{
              backgroundColor: "#FFFDFA",
            }}
          >
            <KeyNumberItem
              customStyles={{ backgroundColor: "#F3F1EC" }}
              text={Returntext(
                contentManagementData,
                "prices_delivery-box1_title",
                lang
              )}
            />
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "prices_delivery-box1_text1",
                lang
              )}
              text_color="#000000"
              text_size="24px"
            />
            <LightTextLanding
              text_color="#525252"
              text={Returntext(
                contentManagementData,
                "prices_delivery-box1_text2",
                lang
              )}
              text_size="16px"
            />
          </LandingContainer>
          <div ref={carRef} id="carAnimation" style={{ width: "30%" }}>
            <Lottie
              animationData={carAnimation}
              loop={loopCar}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="card2 flex_between">
          <LandingContainer
            customStyles={{
              backgroundColor: "#6AD67D",
              padding: "2%",
              borderRadius: "16px",
            }}
          >
            <KeyNumberItem
              customStyles={{ backgroundColor: "#F3F1EC" }}
              text={Returntext(
                contentManagementData,
                "prices_delivery-box2_title",
                lang
              )}
            />
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "prices_delivery-box2_text1",
                lang
              )}
              text_color="#000000"
              text_size="24px"
            />
            <LightTextLanding
              text_color="#525252"
              text={Returntext(
                contentManagementData,
                "prices_delivery-box2_text2",
                lang
              )}
              text_size="16px"
            />
          </LandingContainer>
          <div id="mapAnimation" style={{ width: "30%" }}>
            <Lottie
              animationData={mapAnimation}
              loop={loopCar}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleDelivery;
