import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import Cookies from "js-cookie"; // Make sure js-cookie is installed and imported

export const useAddCard = () => {
  const queryClient = useQueryClient();

  const addCardMutation = useMutation(
    async (data) => {
      const accessToken = Cookies.get("access_token");
      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENTS_URL}/payments/orders/saveCardOrder?accessToken=${accessToken}`,
        data,
        {
          headers: {
            "x-public-key": `${process.env.REACT_APP_PUBLIC_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Use the Bearer token for Authorization
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        return data;
      },
      onError: (error) => {
        console.error(
          "Error adding card:",
          error.response ? error.response.data : error
        );
      },
    }
  );

  const handleAddCard = async (data) => {
    try {
      return await addCardMutation.mutateAsync(data); // Execute the mutation
    } catch (error) {}
  };

  return {
    handleAddCard,
    isAddingCard: addCardMutation.isLoading,
    addCardError: addCardMutation.error,
  };
};
