import React from "react";
import "./vatPInfo.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import StepHeader from "../../molecules/StepHeader/StepHeader";
import ListItem from "../../../ds/ListItem/ListItem";
import CopyToClipBoard from "../../atoms/CopyToClipBoard/CopyToClipBoard";
import UploadBox from "../../../ds/UploadBox/UploadBox";
import PInfoFooter from "../../molecules/PInfoFooter/PInfoFooter";
import VatPInfoFooter from "../../molecules/VatPInfoFooter /VatPInfoFooter";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const VatPInfo = ({
  cancel,
  setTipsDrawerActive,
  SetVatPInfoDrawer,
  setVatDrawer,
}) => {
  return (
    <div className="vat-p-info">
      <div className="shadow" onClick={cancel}></div>
      <div className="drawer-wrap">
        <div className="header">
          <div className="left"></div>

          <div className="middle">Custom</div>

          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />}
            disabled={false}
            mode={"active"}
            onClick={cancel}
          />
        </div>

        <div className="body">
          <StepHeader
            step={2}
            main={"Pay directly for custom services"}
            sub={
              "We can pay custom service fee instead of you, while you can still pay manually from your account and let us know. Also on this step we check if additional documents are required."
            }
            onClick={() => setTipsDrawerActive(true)}
          />

          <div className="body-content">
            <div className="content-info-box">
              <div className="main">Pay via Bank Transfer</div>
              <div className="sub">
                Please send via bank transfer 70₾ to custom account number and
                upload proof of payment as a screenshot or pdf receipt file
              </div>
            </div>

            <div className="pdb">
              <div className="pdbt">
                <div className="lt">Payment details</div>
              </div>

              <div className="pdbi">
                <ListItem
                  hasBorder={false}
                  hasPadding={true}
                  hasIcon={true}
                  headline={"Declaration document"}
                  description={`declarationgm2321.pdf`}
                  icon={"/images/icons/dec.svg"}
                  buttons={
                    <BtnCircular
                      size={"sm"}
                      mainColor={"grayBorder"}
                      svg={"/images/icons/download.svg"}
                      disabled={false}
                      // onClick={handleCopy}
                    />
                  }
                />

                <div className="break" />

                <ListItem
                  hasBorder={false}
                  hasPadding={true}
                  hasIcon={false}
                  headline={"Copy and enter document number"}
                  description={`RS132133021`}
                  buttons={<CopyToClipBoard textToCopy={`RS132133021`} />}
                />

                <div className="break" />

                <ListItem
                  hasBorder={false}
                  hasPadding={true}
                  hasIcon={false}
                  headline={"Send 20₾ to this account number"}
                  description={`1208080`}
                  buttons={<CopyToClipBoard textToCopy={`1208080`} />}
                />
              </div>
            </div>

            <div className="upload-wrap">
              <div className="txts">
                <div className="main">Upload payment invoice</div>
              </div>
              <UploadBox />
            </div>
          </div>
        </div>

        <div className="footer">
          <VatPInfoFooter
            SetVatPInfoDrawer={SetVatPInfoDrawer}
            setVatDrawer={setVatDrawer}
          />
        </div>
      </div>
    </div>
  );
};

export default VatPInfo;
