import React, { useState } from "react";
import "./customFeeBox.scss";
import ListItem from "../../../ds/ListItem/ListItem";
import CircularCheckBox from "../../atoms/CircularCheckBox/CircularCheckBox";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const CustomFeeBox = ({ notPaid, setNotPaid, activeOrder }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="custom-fee-box">
      <div className="title">
        {Returntext(contentManagementData, "customDrawerStep1SH", lang)}
      </div>

      {/* <div className="qa">How to transfer fee manually?</div> */}

      <ListItem
        hasBorder={true}
        hasPadding={true}
        hasIcon={false}
        headline={`${
          activeOrder?.custom_fee?.custom_fee_amount?.split(".")[0]
        }₾${Returntext(
          contentManagementData,
          "ErrorMessages-customs20gel4",
          lang
        )} ${Returntext(
          contentManagementData,
          "ErrorMessages-customs20gel",
          lang
        )}`}
        active={notPaid ? true : false}
        description={Returntext(
          contentManagementData,
          "customDrawerStep1B1S",
          lang
        )}
        buttons={
          <CircularCheckBox
            onClick={() => setNotPaid(true)}
            status={notPaid ? true : false}
          />
        }
      />

      <ListItem
        hasBorder={true}
        hasPadding={true}
        hasIcon={false}
        headline={`${
          activeOrder?.custom_fee?.custom_fee_amount?.split(".")[0]
        }₾${Returntext(
          contentManagementData,
          "ErrorMessages-customs20gel4",
          lang
        )} ${Returntext(
          contentManagementData,
          "ErrorMessages-customs20gel3",
          lang
        )}`}
        description={Returntext(
          contentManagementData,
          "customDrawerStep1B2T",
          lang
        )}
        active={notPaid ? false : true}
        buttons={
          <CircularCheckBox
            onClick={() => setNotPaid(false)}
            status={notPaid ? false : true}
          />
        }
      />
      <div className="a-info">
        {Returntext(contentManagementData, "customDrawerStep1CF", lang)}
      </div>
    </div>
  );
};

export default CustomFeeBox;
