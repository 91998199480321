import React from "react";
import CustomContentS0 from "./CustomContentS0";
import CustomContentS3 from "./CustomContentS3";
import CustomContentS2 from "./CustomContentS2";
import CustomContentS1 from "./CustomContentS1";

const RenderLogic = ({
  step,
  cancelClick,
  setTipsDrawerActive,
  setStep,
  activeOrder,
  setActiveOrder,
  setPInfoDrawer,
  update,
  update3,
  files,
  setFiles,
}) => {
  return (
    <>
      {step === "0" && (
        <CustomContentS0
          cancelClick={cancelClick}
          setStep={setStep}
          activeOrder={activeOrder}
          setActiveOrder={setActiveOrder}
        />
      )}
      {step === "1" && (
        <CustomContentS1
          cancelClick={cancelClick}
          setTipsDrawerActive={setTipsDrawerActive}
          setStep={setStep}
          setPInfoDrawer={setPInfoDrawer}
          activeOrder={activeOrder}
          update={update}
        />
      )}
      {(step === "2" || step === "3") && (
        <CustomContentS2
          cancelClick={cancelClick}
          setTipsDrawerActive={setTipsDrawerActive}
          activeOrder={activeOrder}
          step={step}
          setStep={setStep}
          files={files}
          setFiles={setFiles}
          setActiveOrder={setActiveOrder}
        />
      )}
      {(step === "4" || step === "5" || step === "8") && (
        <CustomContentS3
          cancelClick={cancelClick}
          setTipsDrawerActive={setTipsDrawerActive}
          step={step}
          activeOrder={activeOrder}
        />
      )}
    </>
  );
};

export default RenderLogic;
