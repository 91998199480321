import "./keyNumber.scss";
function KeyNumberItem({ text, image, customStyles = {} }) {
  return (
    <div className="keyNumber boldFont" style={{ ...customStyles }}>
      {text && text}
      {image && <img src={image} width="70px" alt="" />}
    </div>
  );
}
export default KeyNumberItem;
