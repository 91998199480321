import React, { useRef } from "react";
import "./uploadBox.scss";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";
import ListItem from "../ListItem/ListItem";

// Import the SVGs as React components
import { ReactComponent as PingIcon } from "../../icons/ping.svg";
import { ReactComponent as UploadIcon } from "../../icons/upload.svg";
import BtnCircular from "../BtnCircular/BtnCircular";
import { ReactComponent as DeleteWhiteIcon } from "../../icons/deleteWhite.svg";

const UploadBox = ({
  files = [],
  setFiles,
  activeOrder,
  type,
  declareShow,
  setDeleteItem,
  setActiveFile,
  Delete,
}) => {
  const fileInputRef = useRef(null);

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    setFiles(files);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div
      className={
        activeOrder?.declarationFiles?.filter((fl) => fl.fileType === type)
          .length
          ? "upload-box-wrap active"
          : "upload-box-wrap"
      }
    >
      {activeOrder?.declarationFiles?.length > 0 &&
        activeOrder?.declarationFiles
          ?.filter((fl) => fl.fileType === type)
          ?.map((fl) => (
            <React.Fragment key={fl?.original_file_name}>
              <ListItem
                hasBorder={false}
                hasPadding={false}
                hasIcon={true}
                icon={<PingIcon />}
                headline={fl?.original_file_name}
                description={Returntext(
                  contentManagementData,
                  "AttachInvoice_uploaded",
                  lang
                )}
                buttons={
                  !Delete && (
                    <BtnCircular
                      size={"sm"}
                      mainColor={"red"}
                      svg={<DeleteWhiteIcon />} // Use the SVG component here
                      disabled={false}
                      mode={""}
                      onClick={() => {
                        setDeleteItem(true);
                        setActiveFile(fl);
                      }}
                    />
                  )
                }
              />
              <div className="break" />
            </React.Fragment>
          ))}
      {!declareShow && (
        <div
          className={
            activeOrder?.declarationFiles?.filter((fl) => fl.fileType === type)
              .length === 0
              ? "upload-box active"
              : "upload-box"
          }
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onClick={handleUploadClick}
        >
          <UploadIcon className="icon" />
          <div className="inputs">
            <input
              type="file"
              ref={fileInputRef}
              multiple
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />
            {files.length === 0 && (
              <>
                <p className="upload-field">
                  {Returntext(contentManagementData, "upload", lang)}
                </p>
                <p className="sup">
                  {Returntext(contentManagementData, "imageType", lang)}
                </p>
              </>
            )}
            {files.length !== 0 && (
              <p className="sup">
                {files.map((fl) => (
                  <span key={fl.name}>{fl.name},</span>
                ))}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadBox;
