import React, { useState } from "react";
import "./signIn.scss";
import SignInForm from "../../components/organisms/SignInForm/SignInForm";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="sign-in">
      <div className="form-wrap">
        <SignInForm
          phone={phone}
          setPhone={setPhone}
          password={password}
          setPassword={setPassword}
          email={email}
          setEmail={setEmail}
        />
      </div>
    </div>
  );
};

export default SignIn;
