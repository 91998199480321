import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../icons/logo.svg";

const HeaderLogoAuthed = () => {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate("/orders/AWAITING")}>
      <Logo className="logo" style={{ width: "40vw" }} />
    </div>
  );
};

export default HeaderLogoAuthed;
