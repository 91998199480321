import React from "react";
import "./sidebarNavigation.scss";
import SidebarBtn from "../SidebarBtn/SidebarBtn";
import { useLocation } from "react-router-dom";

const SidebarNavigation = ({ navButtons }) => {
  const location = useLocation();
  const isOnboardingRoute = location.pathname.includes("onboarding");
  return (
    <div className="sidebar-navigation">
      {navButtons.map((btn, i) => (
        <SidebarBtn
          key={i}
          svg={btn.icon}
          paths={btn.paths}
          svgDisabled={btn.iconDisabled}
          svgActive={btn.iconActive}
          txt={btn.txt}
          disabled={isOnboardingRoute}
        />
      ))}
    </div>
  );
};

export default SidebarNavigation;
