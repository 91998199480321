import React from "react";
import "./trackingWayDrawer.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import { ReactComponent as GzTopIcon } from "../../../icons/gz-top.svg";
import { ReactComponent as ScpIcon } from "../../../icons/scp.svg";

const TrackingWayDrawer = ({ cancel, activeOrder }) => {
  const points = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const airwayDate = activeOrder?.pallet?.airwayBill?.airway_date;

  let month = "";
  let day = "";

  if (airwayDate) {
    const dateObject = new Date(airwayDate);
    month = dateObject.toLocaleString("en-US", { month: "short" });
    day = dateObject.getDate();
  }

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="tracking-way-drawer">
      <div className="shadow" onClick={cancel}></div>
      <div className="drawer-wrap">
        <div className="header">
          <div className="left"></div>

          <div className="middle">
            {Returntext(contentManagementData, "trackingDrawerParcelH", lang)}
          </div>

          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />} // Use the SVG component here
            disabled={false}
            mode={"active"}
            onClick={cancel}
          />
        </div>

        <div className="body">
          <div className="loc-bar">
            <div className="eat">
              {activeOrder?.status !== "DELIVERED" ? (
                <div className="eat-txt">
                  {month && day
                    ? `${Returntext(
                        contentManagementData,
                        "trackingDrawerParcelAr",
                        lang
                      )} ${day}
 ${Returntext(contentManagementData, month?.toLowerCase(), lang)}`
                    : Returntext(
                        contentManagementData,
                        "trackingDrawerParcel Header",
                        lang
                      )}
                </div>
              ) : (
                <div className="eat-txt">
                  {Returntext(contentManagementData, "", lang)}
                </div>
              )}
            </div>

            <div className="clb">
              <div className="clb-wrap">
                <div className="left">
                  {/* <div className="top">13 Feb, 16:00</div> */}
                  <div className="main">DE</div>
                  <div className="bottom">Delaware</div>
                </div>

                <div className="middle">
                  <div className="stp">
                    <div className="crl"></div>
                  </div>
                  <div className="road">
                    <div className="st active"></div>
                    {points.map((pt) => (
                      <div
                        className={
                          pt <=
                            (points.length *
                              activeOrder?.orderHistory?.length) /
                              5 || activeOrder?.status === "DELIVERED"
                            ? "way active"
                            : "way"
                        }
                        key={pt}
                      >
                        {parseInt(
                          (points.length * activeOrder?.orderHistory?.length) /
                            5
                        ) === pt &&
                          activeOrder?.status !== "DELIVERED" && (
                            <GzTopIcon className="pin" />
                          )}
                      </div>
                    ))}
                    <div
                      className={
                        activeOrder?.status === "DELIVERED" ? "st active" : "st"
                      }
                    ></div>
                  </div>
                  <div
                    className={
                      activeOrder?.status === "DELIVERED" ? "edp active" : "edp"
                    }
                  >
                    {activeOrder?.status === "DELIVERED" && (
                      <GzTopIcon className="pin" />
                    )}
                  </div>
                </div>

                <div className="right">
                  {/* <div className="top">17 Feb, 18:00</div> */}
                  <div className="main">TBS</div>
                  <div className="bottom">Tbilisi</div>
                </div>
              </div>
            </div>
          </div>

          <div className="checkpoints">
            {activeOrder?.orderHistory.map((hist) => (
              <div className="cpt" key={hist?.id}>
                <div className="left active">
                  <div className="circle-p active">
                    <ScpIcon className="icon" />
                  </div>

                  <div className="line"></div>
                </div>
                <div className="right">
                  <div className="txts">
                    <div className="main">
                      {Returntext(
                        contentManagementData,
                        hist?.name?.toUpperCase()?.replaceAll(" ", "_"),
                        lang
                      )}
                    </div>
                  </div>
                  <div className="status">
                    <div className="txt">
                      {`${new Date(hist?.date).getDate()} ${Returntext(
                        contentManagementData,
                        new Date(hist?.date)
                          .toLocaleString("en-US", { month: "short" })
                          .toLowerCase(),
                        lang
                      )}`}
                    </div>
                    <div className="txt">
                      {new Date(hist?.date).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackingWayDrawer;
