import React, { useEffect, useState } from "react";
import "./navbarOnboard.scss";
import LanguageBtn from "../../components/atoms/LanguageBtn/LanguageBtn";
import { useLogoutMutation } from "../../hooks/auth";
import { ReactComponent as LogoutIcon } from "../../icons/log-out-nav.svg";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";
import BtnStandardIconText from "../BtnStandardIconText/BtnStandardIconText";
import { useNavigate } from "react-router-dom";

const NavbarOnboard = () => {
  const { mutate: logout } = useLogoutMutation();

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = "/sign-in";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const navigate = useNavigate();

  return (
    <div className={"navbar-onboard"}>
      <div className="gradient"></div>
      <img
        src="/images/icons/gz-lg.png"
        alt=""
        className="gz-icon"
        onClick={() => navigate("/")}
      />
      <div className="right">
        <LanguageBtn />
        <BtnStandardIconText
          mode={""}
          txt={Returntext(contentManagementData, "signOut", lang)}
          disabled={false}
          size={"sm"}
          icon={true}
          svg={<LogoutIcon />}
          iconPosition={"left"}
          mainColor={"grayBorder"}
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default NavbarOnboard;
