import React from "react";
import { useNavigate } from "react-router-dom";
import "./pageNotFound.scss";
import LightTextLanding from "../../components/atoms/LandingText/LightTextLanding";
import BoldTextLanding from "../../components/atoms/LandingText/BoldtextLanding";
import BtnStandardLanding from "../../ds/BtnStandardLanding/BtnStandardLanding";

function PageNotFound() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/landing/home");
  };

  return (
    <>
      <div className="notFound">
        <div className="notFoundChild">
          <img src="/images/icons/pageNotFound/notFound.svg" alt="" />
          <LightTextLanding
            text="გვერდი ვერ მოიძებნა"
            text_color="#000000"
            text_size="36px"
          />
          <BoldTextLanding
            text="მისამართი რომელზეც გადმოხვედით არასწორია ან აღარ არსებობს"
            text_color="#000000"
            text_size="16px"
            addedStyles={{ textAlign: "center" }}
          />
          <div>
            <BtnStandardLanding
              mode="active"
              txt={"მთავარზე დაბრუნება"}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"white"}
              styles={{
                backgroundColor: "white",
                border: "1px solid #D1CEC7",
                padding: "20px",
              }}
              onClick={handleNavigate}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
