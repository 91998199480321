import React from "react";
import "./toogle.scss";
const Toogle = ({ onClick, active, disabled, style }) => {
  return (
    <div
      className={`toogle ${active && "active"} ${disabled && "disabled"}`}
      onClick={!disabled ? onClick : () => {}}
      style={style}
    >
      <div className="t-circle"></div>
    </div>
  );
};

export default Toogle;
