import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import Cookies from "js-cookie";

const updateOrder = async (id, newData) => {
  try {
    const access_token = Cookies.get("access_token");
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/orders/nusxa_update/${id}?accessToken=${access_token}`,
      newData
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to update order");
  }
};

const useUpdateOrderByNusxa = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (variables) => updateOrder(variables.id, variables.newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("order");
      },
    }
  );

  const updateOrderByNusxa = async (variables) => {
    await mutation.mutateAsync(variables);
  };

  return {
    updateOrderByNusxa,
    isLoading: mutation.isLoading,
    error: mutation.error,
  };
};

export default useUpdateOrderByNusxa;
