import React from "react";
import "./circularCheckBox.scss";

const CircularCheckBox = ({ onClick, status }) => {
  return (
    <div
      className={`circular-check-box ${status && "active"}`}
      onClick={onClick}
    />
  );
};

export default CircularCheckBox;
