import React from "react";
import "./pInfoFooter.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";

const VatPInfoFooter = ({ SetVatPInfoDrawer, setVatDrawer }) => {
  return (
    <div className="p-info-footer">
      <BtnStandardIconText
        mode=""
        txt={"Confirm payment"}
        disabled={false}
        size={"standard"}
        icon={false}
        mainColor={"black"}
        onClick={() => {
          setVatDrawer(false);
          SetVatPInfoDrawer(false);
        }}
      />
    </div>
  );
};

export default VatPInfoFooter;
