import React from "react";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import TipsBox from "../TipsBox/TipsBox";
import "./tipsBody.scss";
import { ReactComponent as IdeaIcon } from "../../../icons/idea.svg";
import { ReactComponent as PcsIcon } from "../../../icons/pcs.svg";
import { ReactComponent as CspIcon } from "../../../icons/csp.svg";
import { ReactComponent as VatIcon } from "../../../icons/vat.svg";

const TipsBody = () => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <>
      <div className="info">
        <div className="label">
          <OrderTableLabel
            txt={
              <>
                <span>
                  {Returntext(contentManagementData, "customDrawerTips", lang)}
                </span>
                <IdeaIcon alt="Idea" /> {/* Use the SVG component here */}
              </>
            }
          />
        </div>
        <div className="heading">
          {Returntext(contentManagementData, "customDrawerST", lang)}
        </div>
      </div>

      <TipsBox
        txt={"1"}
        main={Returntext(contentManagementData, "customDrawerTip1M", lang)}
        sub={Returntext(contentManagementData, "customDrawerTip1B", lang)}
        icon={<PcsIcon />} // Use the SVG component here
      />

      <TipsBox
        txt={"2"}
        main={Returntext(contentManagementData, "customDrawerTip2M", lang)}
        sub={Returntext(contentManagementData, "customDrawerTip2B", lang)}
        icon={<CspIcon />} // Use the SVG component here
      />

      <TipsBox
        txt={"3"}
        main={Returntext(contentManagementData, "customDrawerTip3M", lang)}
        sub={Returntext(contentManagementData, "customDrawerTip3B", lang)}
        icon={<VatIcon />} // Use the SVG component here
      />
    </>
  );
};

export default TipsBody;
