import React, { useEffect, useState } from "react";
import "./addressPopUp.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import InputGz from "../../../ds/InputGz/InputGz";
import { Autocomplete } from "@react-google-maps/api";
import MapWithAutocomplete from "../GMap/MapWithAutocomplete";
import {
  useAddressPost,
  useUpdateAddressMutation,
} from "../../../hooks/address";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import axios from "axios";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../../icons/work.svg";
import { ReactComponent as OtherIcon } from "../../../icons/other.svg";
import { useSnackbar } from "notistack";

const AddressPopUp = ({
  setAddAddressPopUp,
  setEditAddressPopUp,
  activeItem,
  type,
}) => {
  const [title, setTitle] = useState(activeItem ? activeItem.title || "" : "");
  const [address, setAddress] = useState(
    activeItem ? activeItem.address || "" : ""
  );
  const [city, setCity] = useState(activeItem ? activeItem.city || "" : "");
  const [instructions, setInstructions] = useState(
    activeItem ? activeItem.additionalInstructions || "" : ""
  );
  const [currentLocation, setCurrentLocation] = useState({
    lat: activeItem ? parseFloat(activeItem.lat) || "" : 41.7056322,
    lng: activeItem ? parseFloat(activeItem.lang) || "" : 44.7870996,
  });
  const [tag, setTag] = useState(
    activeItem ? activeItem.tag || "home" : "home"
  );

  const userData = JSON.parse(localStorage.getItem("user"));

  const [autocomplete, setAutocomplete] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (autocomplete) {
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Place not found");
          return;
        }
        const { formatted_address } = place;
        setAddress(formatted_address);
        setCurrentLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      });
    }
  }, [autocomplete, address]);

  const { mutateAddress } = useAddressPost();

  const handleConfirmAddress = async () => {
    try {
      const addressData = {
        address,
        status: "ACTIVE",
        customer_id: userData.id,
        tag,
        title,
        city,
        additionalInstructions: instructions,
        lang: currentLocation ? currentLocation.lng.toString() : "",
        lat: currentLocation ? currentLocation.lat.toString() : "",
      };
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-adress", lang),
        {
          variant: "success",
        }
      );
      mutateAddress(addressData);
      setAddAddressPopUp(false);
    } catch (error) {
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-adress2", lang),
        {
          variant: "error",
        }
      );
    }
  };

  const { handleUpdateAddress } = useUpdateAddressMutation();

  const UpdateAddress = async () => {
    const updatedData = {
      address,
      tag,
      title,
      city,
      additionalInstructions: instructions,
      lang: currentLocation ? currentLocation.lng.toString() : "",
      lat: currentLocation ? currentLocation.lat.toString() : "",
    };

    try {
      handleUpdateAddress(activeItem.id, updatedData);
      setEditAddressPopUp(false);

      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-adrupdate", lang),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-adrupdate2", lang),
        {
          variant: "error",
        }
      );
    }
  };

  const mapStyles = {
    height: "344px",
    width: "100%",
    borderRadius: "16px",
  };

  useEffect(() => {
    // Fetch city using reverse geocoding
    const fetchCity = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );

        if (response.data.results.length > 0) {
          const addressComponents = response.data.results[0].address_components;
          for (let component of addressComponents) {
            if (component.types.includes("locality")) {
              setCity(component.long_name);
              break;
            }
          }
        }
      } catch (error) {
        console.error("Error fetching city:", error);
      }
    };

    fetchCity();
  }, [currentLocation]);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="address-pop-up">
      <div
        className="shadow"
        onClick={() => {
          activeItem ? setEditAddressPopUp(false) : setAddAddressPopUp(false);
        }}
      />
      <div className="adp-wrap">
        <div className="top">
          <div className="title">
            {Returntext(
              contentManagementData,
              "DeliverySettingsPopUpHeadline",
              lang
            )}
          </div>
          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />}
            disabled={false}
            mode={"active"}
            onClick={() => {
              activeItem
                ? setEditAddressPopUp(false)
                : setAddAddressPopUp(false);
            }}
          />
        </div>

        <div className="middle">
          <div className="left">
            <div className="form-wrap">
              <div className="tags">
                <BtnStandardIconText
                  mode={tag === "home" ? "active" : ""}
                  txt={Returntext(contentManagementData, "home", lang)}
                  disabled={false}
                  size={"sm"}
                  icon={true}
                  svg={<HomeIcon />}
                  iconPosition={"left"}
                  mainColor={"grayBorder"}
                  onClick={() => setTag("home")}
                />
                <BtnStandardIconText
                  mode={tag === "work" ? "active" : ""}
                  txt={Returntext(contentManagementData, "work", lang)}
                  disabled={false}
                  size={"sm"}
                  icon={true}
                  svg={<WorkIcon />}
                  iconPosition={"left"}
                  mainColor={"grayBorder"}
                  onClick={() => setTag("work")}
                />
                <BtnStandardIconText
                  mode={tag === "other" ? "active" : ""}
                  txt={Returntext(contentManagementData, "other", lang)}
                  disabled={false}
                  size={"sm"}
                  icon={true}
                  svg={<OtherIcon />}
                  iconPosition={"left"}
                  mainColor={"grayBorder"}
                  onClick={() => setTag("other")}
                />
              </div>

              <div className="inputs">
                <InputGz
                  type={"text"}
                  value={title}
                  setter={setTitle}
                  label={Returntext(contentManagementData, "Title", lang)}
                  nid={"title"}
                  disabled={false}
                  errorM={false}
                />
                <Autocomplete
                  onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                  options={{
                    placeholder: "",
                    componentRestrictions: { country: "ge" },
                  }}
                  className="autocomplete"
                >
                  <InputGz
                    type={"text"}
                    value={address}
                    setter={setAddress}
                    nid={Returntext(contentManagementData, "address", lang)}
                    disabled={false}
                    errorM={false}
                    hasLabel={true}
                  />
                </Autocomplete>
                <InputGz
                  type={"text"}
                  value={city}
                  setter={""}
                  label={Returntext(contentManagementData, "city", lang)}
                  nid={"city"}
                  disabled={true}
                  errorM={false}
                />
                <InputGz
                  type={"text"}
                  value={instructions}
                  setter={setInstructions}
                  label={Returntext(
                    contentManagementData,
                    "AdditionalInformation",
                    lang
                  )}
                  nid={"Additional instructions"}
                  disabled={false}
                  errorM={false}
                />
              </div>
            </div>
          </div>

          <div className="right">
            <MapWithAutocomplete
              setStreetName={setAddress}
              setCurrentLocation={setCurrentLocation}
              currentLocation={currentLocation}
              mapStyles={mapStyles}
            />
          </div>
        </div>

        <div className="bottom">
          <div className="left">
            {/* <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span className="c-txt">Make as default</span> */}
          </div>

          <div className="right">
            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "cancel", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"white"}
              onClick={() => {
                activeItem
                  ? setEditAddressPopUp(false)
                  : setAddAddressPopUp(false);
              }}
            />

            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "confirm", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"green"}
              onClick={() => {
                if (type === "update") {
                  UpdateAddress();
                } else {
                  handleConfirmAddress();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressPopUp;
