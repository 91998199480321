import "./orderDrawerBody.scss";
import React from "react";
import InputGz from "../InputGz/InputGz";
import BtnStandardIconText from "../BtnStandardIconText/BtnStandardIconText";
import { Returntext } from "../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../hooks/content";

import { ReactComponent as UsaIcon } from "../../icons/country/usa.svg";

const OrderDrawerBody = ({
  tracking,
  setTracking,
  title,
  setTitle,
  country,
  setCountry,
  titleError,
  trackingError,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="order-drawer-body">
      <div className="body-head">
        <div className="main">
          {Returntext(contentManagementData, "trackingDrawerS", lang)}
        </div>
        <div className="sub">
          {Returntext(contentManagementData, "trackingDrawerSh", lang)}
        </div>
      </div>
      <div className="countries">
        <BtnStandardIconText
          mode={country === "usa" ? "active" : ""}
          txt={Returntext(contentManagementData, "usa", lang)}
          disabled={false}
          size={"sm"}
          icon={true}
          svg={<UsaIcon />}
          iconPosition={"left"}
          mainColor={"grayBorder"}
          onClick={() => setCountry("usa")}
        />
      </div>

      <InputGz
        type={"text"}
        value={tracking}
        setter={setTracking}
        label={Returntext(contentManagementData, "trackingCode", lang)}
        nid={"tracking"}
        disabled={false}
        errorM={trackingError}
      />
      <div className="create-title">
        <InputGz
          type={"text"}
          value={title}
          setter={setTitle}
          label={Returntext(contentManagementData, "addName", lang)}
          nid={"asdasdc"}
          disabled={false}
        />

        <div className="s-msg">
          {Returntext(contentManagementData, "trackingDrawerOrganize", lang)}
        </div>
      </div>
    </div>
  );
};

export default OrderDrawerBody;
