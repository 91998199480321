import BoldTextLanding from "../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../components/atoms/LandingText/LightTextLanding";
import "./aboutUs.scss";
import DeliveryItem from "./helpers/DeliveryItem";
import USAPart from "./helpers/USAPart";
import KeyPoints from "../../../components/molecules/keyPoints/keyPoints";
import TryOut from "../../../components/atoms/TryOut/TryOut";
import { Returntext } from "../common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import {
  aboutUsCover1,
  aboutUsCover2,
  aboutUsCover3,
} from "../../../assets/landing";
function AboutUS() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <>
      <div className="aboutUSContainer">
        <div className="mainAboutUS">
          <div className="texts">
            <BoldTextLanding
              text={Returntext(contentManagementData, "about_h1", lang)}
              text_color="#000000"
              text_size="56px"
            />
            <LightTextLanding
              text_color="#000000"
              text={Returntext(contentManagementData, "about_h2", lang)}
              text_size="20px"
            />
          </div>
          <div className="images_container">
            <img src={aboutUsCover1} alt="" />
            <img src={aboutUsCover2} alt="" />
            <img src={aboutUsCover3} alt="" />
          </div>
        </div>
        <USAPart />
        <DeliveryItem />
        <KeyPoints />
        <div style={{ backgroundColor: "#F3F1EC", paddingTop: "20px" }}>
          {/* <MeetOurTeam /> */}
          <TryOut />
        </div>
      </div>
    </>
  );
}

export default AboutUS;
