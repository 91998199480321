import React from "react";
import "./chooseAddressBox.scss";
import ListItem from "../../../ds/ListItem/ListItem";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useCustomerAddresses } from "../../../hooks/address";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../../icons/work.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check-g.svg";
import { ReactComponent as AddIcon } from "../../../icons/add.svg";

const ChooseAddressBox = ({
  address,
  setAddress,
  setCadActive,
  setAddressDrawer,
}) => {
  const { customerAddresses } = useCustomerAddresses();

  const getAddressIcon = (tag) => {
    if (tag === "home") return <HomeIcon />;
    if (tag === "work") return <WorkIcon />;
    return <WorkIcon />;
  };

  return (
    <div className="choose-address-box">
      {customerAddresses !== undefined &&
        customerAddresses.length !== 0 &&
        customerAddresses.map((ad, index) => (
          <React.Fragment key={index}>
            <ListItem
              hasBorder={false}
              hasPadding={true}
              hasIcon={true}
              headline={ad.title}
              description={ad.address}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAddress(ad);
                setCadActive(false);
              }}
              icon={getAddressIcon(ad.tag)}
              buttons={ad.address === address.address ? <CheckIcon /> : <></>}
            />
            <div className="break"></div>
          </React.Fragment>
        ))}

      <BtnStandardIconText
        mode=""
        txt={"Add new address"}
        disabled={false}
        size={"standard"}
        icon={true}
        iconPosition={"left"}
        svg={<AddIcon />}
        mainColor={"white"}
        onClick={() => setAddressDrawer(true)}
      />
    </div>
  );
};

export default ChooseAddressBox;
