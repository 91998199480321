import React from "react";
import "./btnLightCircularIconLg.scss";
import { ReactComponent as WaveIcon } from "../../../icons/wero.svg";

const BtnLightCircularIconLg = ({ svg }) => {
  return (
    <div className="btn-light-circular-icon-lg">
      <WaveIcon className={"icon"} />
    </div>
  );
};

export default BtnLightCircularIconLg;
