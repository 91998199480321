import { Link } from "react-router-dom";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import BoldTextLanding from "../../atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../atoms/LandingText/LightTextLanding";
import HeaderLogo from "../../atoms/Logo/HeaderLogo";
import "./footer.scss";
import {
  facebook,
  instagram,
  twitter,
  phoneIcon,
  emailIcon,
  earthIcon,
  handIcon,
  truckIcon,
  penIcon,
} from "../../../assets/landing";
function LandingFooter() {
  var currentTime = new Date();
  var year = currentTime.getFullYear();

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const pages = [
    {
      label: Returntext(contentManagementData, "navbar_gz", lang),
      value: "/landing/home",
    },
    {
      label: Returntext(contentManagementData, "navbar_home", lang),
      value: "/landing/home",
    },
    {
      label: Returntext(contentManagementData, "navbar_prices", lang),
      value: "/landing/prices",
    },
    {
      label: Returntext(contentManagementData, "navbar_delivery", lang),
      value: "/landing/delivery",
    },
    {
      label: Returntext(contentManagementData, "navbar_aboutus", lang),
      value: "/landing/about",
    },
    // {
    //   label: Returntext(contentManagementData, "Terms_Conditions", lang),
    //   value: "/landing/about",
    // },
  ];

  const pagesIcons = [
    {
      label: Returntext(contentManagementData, "footer_why_us", lang),
      value: "",
    },
    {
      label: Returntext(contentManagementData, "footer_free_ship", lang),
      value: earthIcon,
    },
    {
      label: Returntext(contentManagementData, "footer_tracking", lang),
      value: handIcon,
    },
    {
      label: Returntext(contentManagementData, "footer_one_by_one", lang),
      value: truckIcon,
    },
    {
      label: Returntext(contentManagementData, "footer_sign", lang),
      value: penIcon,
    },
  ];
  // const footerTextsHeaders = [
  //   "ვებსაიტები",
  //   "ვებსაიტები",
  //   "ვებსაიტები",
  //   "ვებსაიტები",
  //   "ვებსაიტები",
  //   "ვებსაიტები",
  // ];
  // const footerTexts = {
  //   first: ["ვებსაიტები", "ამაზონი", "იბეი", "ტრენდიოლი", "ზარა", "ზარა ჰოუმი"],
  //   second: [
  //     "ვებსაიტები",
  //     "ამაზონი",
  //     "იბეი",
  //     "ტრენდიოლი",
  //     "ზარა",
  //     "ზარა ჰოუმი",
  //   ],
  //   third: ["ვებსაიტები", "ამაზონი", "იბეი", "ტრენდიოლი", "ზარა", "ზარა ჰოუმი"],
  //   fourth: [
  //     "ვებსაიტები",
  //     "ამაზონი",
  //     "იბეი",
  //     "ტრენდიოლი",
  //     "ზარა",
  //     "ზარა ჰოუმი",
  //   ],
  //   fifth: ["ვებსაიტები", "ამაზონი", "იბეი", "ტრენდიოლი", "ზარა", "ზარა ჰოუმი"],
  // };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for a smooth scrolling
    });
  };
  return (
    <>
      <div className="light_part_footer">
        <div className="light_part_footer_child ">
          <div>
            <div className="centerLogo">
              <HeaderLogo widthComp="60%" />
            </div>
            <LightTextLanding
              text_color="#000000"
              text={Returntext(contentManagementData, "footer_title", lang)}
              text_size="14px"
            />

            <div
              className="flex_start "
              style={{ gap: "10px", flexDirection: "row", marginTop: "10px" }}
            >
              <div style={{ gap: "10px", display: "flex" }}>
                <img className="lang-icon" alt="" src={phoneIcon} />
                <LightTextLanding
                  text_color="#000000"
                  text={Returntext(contentManagementData, "footer_mail", lang)}
                  text_size="14px"
                />
              </div>
              <div style={{ gap: "10px", display: "flex" }}>
                <img className="lang-icon" alt="" src={emailIcon} />

                <LightTextLanding
                  text_color="#000000"
                  text={Returntext(contentManagementData, "footer_phone", lang)}
                  text_size="14px"
                />
              </div>
            </div>

            <div
              className="flex_between_footer hide_on_mobile"
              style={{ gap: "12px", marginTop: "10px" }}
            >
              <Link
                to="https://www.linkedin.com/company/gzavnili-com/"
                target="_blanck"
              >
                <img className="lang-icon" alt="" src={twitter} />
              </Link>
              <Link
                to="https://www.facebook.com/servicegzavnili"
                target="_blanck"
              >
                <img className="lang-icon" alt="" src={facebook} />
              </Link>

              <Link
                to="https://www.instagram.com/gzavnili.com_/"
                target="_blanck"
              >
                <img className="lang-icon" alt="" src={instagram} />
              </Link>
            </div>
          </div>
          <hr />
          <div className="flex_between_footer_pages" style={{ gap: "10px" }}>
            {pages.map((page, index) => (
              <Link
                to={page.value}
                replace={true}
                key={index}
                state={{ textDecoration: "none !important" }}
                onClick={scrollToTop}
              >
                {index === 0 ? (
                  <div className="hide_on_mobile">
                    <BoldTextLanding
                      text_color="#000000"
                      text={page.label}
                      text_size="14px"
                    />
                  </div>
                ) : (
                  <LightTextLanding
                    text_color="#000000"
                    text={page.label}
                    text_size="14px"
                  />
                )}
              </Link>
            ))}
          </div>
          <hr />
          <div
            className="flex_between_footer_pages columnMedia"
            style={{ gap: "10px" }}
          >
            {pagesIcons.map((page, index) =>
              index === 0 ? (
                <div className="hide_on_mobile">
                  <BoldTextLanding
                    text_color="#000000"
                    text={page.label}
                    text_size="14px"
                  />
                </div>
              ) : (
                <div style={{ gap: "20px", display: "flex" }}>
                  <LightTextLanding
                    text_color="#000000"
                    text={page.label}
                    text_size="14px"
                  />
                  <img className="lang-icon" alt="" src={page.value} />
                </div>
              )
            )}
          </div>
          <hr />
          <div
            className="flex_between_footer show_on_mobile"
            style={{ gap: "12px", marginTop: "10px" }}
          >
            <Link
              to="https://www.linkedin.com/company/gzavnili-com"
              target="_blanck"
            >
              <img className="lang-icon" alt="" src={twitter} />
            </Link>
            <Link
              to="https://www.facebook.com/servicegzavnili"
              target="_blanck"
            >
              <img className="lang-icon" alt="" src={facebook} />
            </Link>

            <Link
              to="https://www.instagram.com/gzavnili.com"
              target="_blanck"
            >
              <img className="lang-icon" alt="" src={instagram} />
            </Link>
          </div>
        </div>
      </div>
      <div className="copyRight">
        <div
          style={{
            borderTop: "0.5px solid #343F46",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <BoldTextLanding
            text_color="#FFFDFA"
            text={
              `Copyright © ${year} Gzavnili.ge, ` +
              Returntext(contentManagementData, "footer_rights", lang)
            }
            text_size="14px"
          />
        </div>
      </div>
    </>
  );
}

export default LandingFooter;

/* <div
          className="dart_part_footer flex_between_footer"
          style={{ flexWrap: "wrap", gap: "20px" }}
        > */

/* {footerTextsHeaders.map((header, index) => (
            <div key={index} className="flex_col">
              <BoldTextLanding
                text={header}
                text_color="#FFFFFF"
                text_size="16px"
              />
              <div style={{ marginTop: "16px" }}>
                {footerTexts[`first`].map((text, textIndex) => (
                  <LightTextLanding
                    key={textIndex}
                    text_color="#FFFDFA"
                    text={text}
                    text_size="14px"
                  />
                ))}
              </div>
            </div>
          ))} */

/* </div> */
