import React from "react";
import "./shipmentInformation.scss";
import AccountDetailsBoxTitle from "../../atoms/AccountDetailsBoxTitle/AccountDetailsBoxTitle";
import ListItem from "../../../ds/ListItem/ListItem";
import CopyToClipBoard from "../../atoms/CopyToClipBoard/CopyToClipBoard";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useShippingAddresses } from "../../../hooks/shippingAddresses";

const ShipmentInformation = ({}) => {
  // const { data: userData, isLoading, isError } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));
  const { shippingAddress } = useShippingAddresses();

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="shipment-information">
      <AccountDetailsBoxTitle
        main={Returntext(contentManagementData, "ShipmentInformation", lang)}
        sub={Returntext(contentManagementData, "ShipmentInformationSub", lang)}
      />

      {userData.customerType === "PERSONAL" ? (
        <ListItem
          hasBorder={false}
          hasPadding={false}
          hasIcon={false}
          headline={Returntext(contentManagementData, "name", lang)}
          description={`${userData?.first_name} ${userData?.last_name}`}
          buttons={
            <CopyToClipBoard
              textToCopy={`${userData?.first_name} ${userData?.last_name}`}
            />
          }
        />
      ) : (
        <ListItem
          hasBorder={false}
          hasPadding={false}
          hasIcon={false}
          headline={Returntext(contentManagementData, "name", lang)}
          description={`${userData?.company_info}`}
          buttons={<CopyToClipBoard textToCopy={`${userData?.company_info}`} />}
        />
      )}

      <div className="break" />

      <ListItem
        hasBorder={false}
        hasPadding={false}
        hasIcon={false}
        headline={Returntext(contentManagementData, "Address", lang)}
        description={`${
          shippingAddress?.street
        } UNIT ${userData.room_number.toUpperCase()}`}
        buttons={
          <CopyToClipBoard
            textToCopy={`${
              shippingAddress?.street
            } UNIT ${userData.room_number.toUpperCase()}`}
          />
        }
      />
      <div className="break" />

      <ListItem
        hasBorder={false}
        hasPadding={false}
        hasIcon={false}
        headline={Returntext(contentManagementData, "City", lang)}
        description={shippingAddress?.city}
        buttons={<CopyToClipBoard textToCopy={shippingAddress?.city} />}
      />
      <div className="break" />

      <ListItem
        hasBorder={false}
        hasPadding={false}
        hasIcon={false}
        headline={Returntext(contentManagementData, "Zip", lang)}
        description={shippingAddress?.zipCode}
        buttons={<CopyToClipBoard textToCopy={shippingAddress?.zipCode} />}
      />
      <div className="break" />

      <ListItem
        hasBorder={false}
        hasPadding={false}
        hasIcon={false}
        headline={Returntext(contentManagementData, "State", lang)}
        description={shippingAddress?.state}
        buttons={<CopyToClipBoard textToCopy={shippingAddress?.state} />}
      />
      <div className="break" />

      <ListItem
        hasBorder={false}
        hasPadding={false}
        hasIcon={false}
        headline={Returntext(contentManagementData, "Country", lang)}
        description={shippingAddress?.country}
        buttons={<CopyToClipBoard textToCopy={shippingAddress?.country} />}
      />
      <div className="break" />

      <ListItem
        hasBorder={false}
        hasPadding={false}
        hasIcon={false}
        headline={Returntext(contentManagementData, "Tel", lang)}
        description={shippingAddress?.tel}
        buttons={<CopyToClipBoard textToCopy={shippingAddress?.tel} />}
      />
    </div>
  );
};

export default ShipmentInformation;
