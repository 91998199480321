import React, { useRef, useState } from "react";
import "./autocomplete.scss";
import { ReactComponent as UpIcon } from "../../icons/up.svg";
import { ReactComponent as DownIcon } from "../../icons/down.svg";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";

const AutocompleteInput = ({
  type,
  value,
  setter,
  label,
  nid,
  disabled,
  errorM,
  style,
  noBorder,
  sActive,
  setSActive,
  data,
}) => {
  const [lbClick, setLbClick] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = data.filter((website) =>
    website.toLowerCase().startsWith(searchTerm.toLowerCase())
  );

  const cleanUp = () => {
    setSActive(false);
    setSearchTerm("");
  };

  const inputRef = useRef(null);

  const handleClick = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div
      className={`autocomplete-inp ${sActive && "active"} ${
        disabled && "disabled"
      }`}
      onClick={handleClick}
    >
      <div
        className={
          lbClick || value
            ? `autocomplete-gz-wrap focused ${noBorder && "no-border"} ${
                disabled ? "disabled" : ""
              } ${errorM ? "error" : ""}`
            : `autocomplete-gz-wrap ${noBorder && "no-border"} ${
                disabled ? "disabled" : ""
              } ${errorM ? "error" : ""}`
        }
        onFocus={() => setLbClick(true)}
        onBlur={() => setLbClick(false)}
        onClick={!disabled ? () => setSActive(!sActive) : () => {}}
      >
        <div
          id={nid}
          name={nid}
          className="input-gz"
          type={type}
          value={value}
          disabled={disabled}
          style={style}
        >
          {value}
          {sActive ? (
            <UpIcon className="icon" />
          ) : (
            <DownIcon className="icon" />
          )}
        </div>
        <label htmlFor={nid} className="label">
          {label}
        </label>
      </div>

      <div className={`select ${sActive && "active"}`}>
        <input
          className="search"
          placeholder="search ..."
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          ref={inputRef}
        />

        {searchTerm === "" && (
          <>
            <div className="item-group1">
              {Returntext(contentManagementData, "suggested", lang)}
              <div className="items">
                {filteredData.slice(0, 3).map((dt, i) => (
                  <div
                    className="item"
                    key={i}
                    onClick={() => {
                      setter(dt);
                      cleanUp();
                    }}
                  >
                    <div className="wrap">
                      <div className="middle">
                        <div className="code">{dt}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="item-group1">
              <div className="title">
                {Returntext(contentManagementData, "select_list", lang)}
              </div>
              <div className="items">
                {filteredData.slice(3).map((dt, i) => (
                  <div
                    className="item"
                    key={i}
                    onClick={() => {
                      setter(dt);
                      cleanUp();
                    }}
                  >
                    <div className="wrap">
                      <div className="middle">
                        <div className="code">{dt}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {searchTerm !== "" && (
          <div className="item-group1">
            <div className="title">Search Result</div>
            <div className="items">
              {filteredData.map((dt, i) => (
                <div
                  className="item"
                  key={i}
                  onClick={() => {
                    setter(dt);
                    cleanUp();
                  }}
                >
                  <div className="wrap">
                    <div className="middle">
                      <div className="code">{dt}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutocompleteInput;
