import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import NavbarMobileAuth from "../components/organisms/Navbar/NavbarMobileAuth";
import Navbar from "../components/organisms/Navbar/Navbar";

const AuthLayout = ({ web, mob }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/landing/home");
  };

  return (
    <div className="auth-layout">
      <BrowserView>
        <Navbar />
        {web}
      </BrowserView>
      <MobileView>
        <NavbarMobileAuth cancelClick={handleClose} />
        {mob}
      </MobileView>
    </div>
  );
};

export default AuthLayout;
