import React from "react";
import "./noItems.scss";

const NoItems = ({ icon: IconComponent, main, sub, positionAbsolute }) => {
  return (
    <div className={`no-items ${positionAbsolute && "fixed"}`}>
      <IconComponent className="icon" />
      <div className="txts">
        <div className="main">{main}</div>
        <div className="sub">{sub}</div>
      </div>
    </div>
  );
};

export default NoItems;
