import React from "react";
import "./authFormHeader.scss";
import SignInFormHeaderMainTxt from "../../atoms/SignInFormHeaderMainTxt/SignInFormHeaderMainTxt";
import SignInFormHeaderSubTxt from "../../atoms/SignInFormHeaderSubTxt/SignInFormHeaderSubTxt";

const AuthFormHeader = ({ mainTxt, subTxt, linkTxt, link }) => {
  return (
    <div className="auth-in-form-header">
      <SignInFormHeaderMainTxt txt={mainTxt} />
      <SignInFormHeaderSubTxt txt={subTxt} linkTxt={linkTxt} link={link} />
    </div>
  );
};

export default AuthFormHeader;
