import React from "react";
import "./signInForm.scss";
import SignInFormHeader from "../../molecules/AuthFormHeader/AuthFormHeader";
import SignInFormBody from "../../molecules/SignInFormBody/SignInFormBody";
import SignInFormFooter from "../../molecules/SignInFormFooter/SignInFormFooter";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const SignInForm = ({
  email,
  setEmail,
  password,
  setPassword,
  phone,
  setPhone,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="sign-in-form">
      <div className="top">
        <SignInFormHeader
          subTxt={Returntext(contentManagementData, "headerSub", lang)}
          mainTxt={Returntext(contentManagementData, "headerMain", lang)}
          link="/sign-up"
          linkTxt={Returntext(contentManagementData, "signUp", lang)}
        />
        <SignInFormBody
          phone={phone}
          setPhone={setPhone}
          password={password}
          setPassword={setPassword}
          email={email}
          setEmail={setEmail}
        />
      </div>
      <SignInFormFooter />
    </div>
  );
};

export default SignInForm;
