import DeliverySettingsBody from "../../components/organisms/DeliverySettingsBody/DeliverySettingsBody";
import "./deliverySettings.scss";
import React, { useState } from "react";
import AccountSettingsTitle from "../../components/atoms/AccountSettingsTitle/AccountSettingsTitle";
import { useAddressDelete } from "../../hooks/address";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../Landings/common/returnText";

const DeliverySettings = ({
  setAddAddressPopUp,
  setEditAddressPopUp,
  setActiveItem,
  setDeleteItem,
  activeItem,
  deleteItem,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="delivery-address">
      <div className="main-content">
        <AccountSettingsTitle
          main={Returntext(
            contentManagementData,
            "DeliverySettingsHeadline",
            lang
          )}
          sub={Returntext(
            contentManagementData,
            "DeliverySettingsSubheadline",
            lang
          )}
        />
        <DeliverySettingsBody
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          deleteItem={deleteItem}
          setDeleteItem={setDeleteItem}
          setAddAddressPopUp={setAddAddressPopUp}
          setEditAddressPopUp={setEditAddressPopUp}
        />
      </div>
    </div>
  );
};

export default DeliverySettings;
