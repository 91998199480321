import React, { useState, useEffect, useRef } from "react";
import "./walletPopOver.scss";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as PopOverIcon } from "../../../icons/popOver.svg";
import { ReactComponent as DefaultCardIcon } from "../../../icons/d-card.svg";
import { ReactComponent as DeleteIcon } from "../../../icons/delete-r.svg";

const WalletPopOver = ({ setActiveItem, setDeleteItem, item, setDefault }) => {
  const [active, setActive] = useState(false);
  const popOverRef = useRef(null);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const handleClickOutside = (event) => {
    if (popOverRef.current && !popOverRef.current.contains(event.target)) {
      setActive(false);
    }
  };

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [active]);

  return (
    <div className="pop-over" ref={popOverRef}>
      <PopOverIcon className="icon" onClick={() => setActive(!active)} />

      {active && (
        <div className="actions">
          {!item.isDefault && (
            <div
              className="p-btn"
              onClick={() => {
                setDefault();
                setActive(false);
              }}
            >
              <DefaultCardIcon />
              <span>
                {Returntext(contentManagementData, "set_default", lang)}
              </span>
            </div>
          )}
          {!item.isDefault && (
            <div
              className="p-btn red"
              onClick={() => {
                setActiveItem(item);
                setDeleteItem(true);
                setActive(false);
              }}
            >
              <DeleteIcon />
              <span>{Returntext(contentManagementData, "delete", lang)}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WalletPopOver;
