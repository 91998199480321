import React, { useState } from "react";
import "./customBodyS2.scss";
import StepHeader from "../StepHeader/StepHeader";
import CustomPaymentBox from "../CustomPaymentBox/CustomPaymentBox";
import Nusxa from "../Nusxa.jsx/Nusxa";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useDeleteFileAndUpdateOrder } from "../../../hooks/files";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const CustomBodyS2 = ({
  step,
  main,
  sub,
  setTipsDrawerActive,
  activeOrder,
  files,
  setFiles,
  setActiveOrder,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const [deleteFile, setDeleteFile] = useState(false);
  const [activeFile, setActiveFile] = useState([]);

  const { deleteFileAndUpdateOrder } = useDeleteFileAndUpdateOrder();

  const handleDelete = (activeFile) => {
    deleteFileAndUpdateOrder(activeFile.id);
  };

  const DeleteFileConfirmation = (
    <div className="delete-item">
      <div className="delete-item-box">
        <div className="top">
          <div className="title">
            {Returntext(contentManagementData, "delete_Parcel_1", lang)}
          </div>
          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />}
            disabled={false}
            mode={"active"}
            onClick={() => {
              setDeleteFile(false);
              setActiveFile([]);
            }}
          />
        </div>

        <div className="middle">
          <div className="txt">
            {Returntext(contentManagementData, "delete_Parcel_2", lang)}
          </div>
        </div>

        <div className="bottom">
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "cancel", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"white"}
            onClick={() => {
              setDeleteFile(false);
              setActiveFile([]);
            }}
          />
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "delete", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"red"}
            onClick={() => {
              handleDelete(activeFile);
              setDeleteFile(false);
              setActiveFile([]);
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="custom-body-s2">
      <StepHeader
        step={step}
        main={main}
        sub={sub}
        onClick={() => setTipsDrawerActive(true)}
      />

      <CustomPaymentBox
        files={files}
        setFiles={setFiles}
        activeOrder={activeOrder}
        setActiveOrder={setActiveOrder}
        setDeleteFile={setDeleteFile}
        setActiveFile={setActiveFile}
      />
      <Nusxa activeOrder={activeOrder} />

      {deleteFile && DeleteFileConfirmation}
    </div>
  );
};

export default CustomBodyS2;
