import React from "react";
import "./stepHeader.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import CustomStepsBar from "../../atoms/CustomStepsBar/CustomStepsBar";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import { ReactComponent as IdeaIcon } from "../../../icons/idea.svg";

const StepHeader = ({ step, main, sub, onClick }) => {
  return (
    <div className="step-header">
      <div className="top">
        <BtnCircular
          size={"sm"}
          mainColor={"green"}
          svg={<CloseIcon />} // Use the SVG component here
          disabled={false}
          mode={"active"}
          type={"txt"}
          txt={step}
          style={{ backgroundColor: "#49A65D" }}
        />
        <div className="label" onClick={onClick}>
          <OrderTableLabel
            txt={
              <>
                <span>All Tips</span>
                <IdeaIcon alt="Idea" /> {/* Use the SVG component here */}
              </>
            }
          />
        </div>
      </div>

      <div className="txts">
        <div className="main">{main}</div>
        <div className="sub">{sub}</div>
      </div>

      <CustomStepsBar stage={step - 1} />
    </div>
  );
};

export default StepHeader;
