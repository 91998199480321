import React from "react";
import OtpEmail from "../OtpEmail/OtpEmail";
import AuthFormHeader from "../AuthFormHeader/AuthFormHeader";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const SignUpOtp = ({
  setScreenNumber,
  email,
  setEmail,
  phone,
  sMethod,
  cSelectActive,
  setCSelectActive,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <>
      <AuthFormHeader
        mainTxt={`${Returntext(contentManagementData, "confirm_email", lang)} ${
          email !== ""
            ? Returntext(contentManagementData, "email", lang)
            : Returntext(contentManagementData, "phone", lang)
        }`}
        subTxt={`${Returntext(
          contentManagementData,
          "SignUpEnterCode",
          lang
        )} ${email !== "" ? email : phone}`}
      />
      <OtpEmail
        continueClick={() => setScreenNumber(2)}
        email={email}
        sMethod={sMethod}
        fPhone={phone}
        setEmail={setEmail}
        cSelectActive={cSelectActive}
        setCSelectActive={setCSelectActive}
      />
    </>
  );
};

export default SignUpOtp;
