import React, { useState } from "react";
import "./passwordReset.scss";
import Navbar from "../../components/organisms/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import { BrowserView, MobileView } from "react-device-detect";
import NavbarMobileAuth from "../../components/organisms/Navbar/NavbarMobileAuth";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [screenNumber, setScreenNumber] = useState(0);
  const [countryData, setCountryData] = useState("Georgia +995");
  const [sMethod, setSMethod] = useState("email");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [parentCode, setParentCode] = useState("");
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/sign-in");
  };

  return (
    <div className="reset-p">
      <BrowserView>
        <Navbar />
      </BrowserView>
      <MobileView>
        <NavbarMobileAuth cancelClick={handleClose} />
      </MobileView>
      <div className="form-wrap">
        {screenNumber === 0 && (
          <StepOne
            screenNumber={screenNumber}
            setScreenNumber={setScreenNumber}
            sMethod={sMethod}
            setSMethod={setSMethod}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            continueClick={() => setScreenNumber(1)}
            countryData={countryData}
            setCountryData={setCountryData}
          />
        )}
        {screenNumber === 1 && (
          <StepThree
            email={email}
            phone={phone}
            password={password}
            setPassword={setPassword}
            passwordCheck={passwordCheck}
            setPasswordCheck={setPasswordCheck}
            parentCode={parentCode}
            setScreenNumber={setScreenNumber}
            screenNumber={screenNumber}
            setParentCode={setParentCode}
          />
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
