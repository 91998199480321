import React from "react";
import "./serviceBox.scss";
import Toogle from "../../atoms/Toogle/Toogle";

const ServiceBox = ({
  main,
  sub,
  icon: IconComponent,
  value,
  setter,
  disabled,
  updateCustomerData,
  style,
}) => {
  return (
    <div className="service-box">
      <div className="sb-wrap">
        <div className="left">
          {IconComponent && (
            <div className="top">
              <IconComponent className="icon" />
            </div>
          )}
          <div className="bottom">
            <div className="main">{main}</div>
            <div className="sub">{sub}</div>
          </div>
        </div>
        <div className="right">
          <Toogle
            active={value}
            onClick={() => {
              setter(!value);
              updateCustomerData(!value);
            }}
            disabled={disabled}
            style={style}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceBox;
