import React from "react";
import "./sidebarBody.scss";
import SidebarIcons from "../SidebarIcons/SidebarIcons";
import SidebarNavigation from "../SidebarNavigation/SidebarNavigation";

const SidebarBody = ({ headIcons, navButtons }) => {
  return (
    <div className="sidebar-body">
      <SidebarIcons headIcons={headIcons} />
      <SidebarNavigation navButtons={navButtons} />
    </div>
  );
};

export default SidebarBody;
