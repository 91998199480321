import React from "react";
import "./actionTabBtn.scss";
import ActionTabCircularBtn from "../ActionTabCircularBtn/ActionTabCircularBtn";
import ActionTabCircularBtnMobile from "../ActionTabCircularBtnMobile/ActionTabCircularBtnMobile";

const ActionTabBtn = ({
  txt,
  svg: SvgIcon,
  svgActive: SvgActiveIcon,
  count,
  active,
  onClick,
  inactive,
}) => {
  return (
    <div
      className={`action-tab-btn ${active ? "active" : ""}`}
      onClick={onClick}
    >
      <ActionTabCircularBtn
        active={active ? "active" : ""}
        svg={SvgIcon}
        svgActive={SvgActiveIcon}
        count={count}
      />

      <ActionTabCircularBtnMobile
        active={active ? "active" : ""}
        svg={SvgIcon}
        svgActive={SvgActiveIcon}
        count={count}
        inactive={inactive}
      />
      <div className="bottom">{txt}</div>
    </div>
  );
};

export default ActionTabBtn;
