import React from "react";
import SignUpFormBody from "../SignUpFormBody/SignUpFormBody";
import SignInFormFooter from "../SignInFormFooter/SignInFormFooter";
import AuthFormHeader from "../AuthFormHeader/AuthFormHeader";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const SignUpEnterEmail = ({
  setScreenNumber,
  email,
  setEmail,
  phone,
  setPhone,
  sMethod,
  SetSMethod,
  activeMode,
  setActiveMode,
  countryData,
  setCountryData,
  setCSelectActive,
  cSelectActive,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <>
      <div className="top">
        <AuthFormHeader
          subTxt={Returntext(contentManagementData, "SignUpTitleSub", lang)}
          mainTxt={Returntext(contentManagementData, "SignUpTitleMain", lang)}
          link="/sign-in"
          linkTxt={Returntext(contentManagementData, "SignInLink", lang)}
        />
        <SignUpFormBody
          continueClick={() => setScreenNumber(1)}
          email={email}
          setEmail={setEmail}
          sMethod={sMethod}
          SetSMethod={SetSMethod}
          phone={phone}
          setPhone={setPhone}
          activeMode={activeMode}
          setActiveMode={setActiveMode}
          countryData={countryData}
          setCountryData={setCountryData}
          cSelectActive={cSelectActive}
          setCSelectActive={setCSelectActive}
        />
      </div>

      <SignInFormFooter />
    </>
  );
};

export default SignUpEnterEmail;
