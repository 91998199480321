import { useEffect, useRef } from "react";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import KeyNumberItem from "../../atoms/LandingKeyNumber/KeyNumberItem";
import BoldTextLanding from "../../atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../atoms/LandingText/LightTextLanding";
import LandingContainer from "../../atoms/landingContainer/landingContainer";
import { flag } from "../../../assets/landing";

function KeyPoints() {
  const { contentManagementData } = useContent();
  const { lang } = useLanguages();

  // Create refs for each container
  const boxOneRef = useRef(null);
  const boxTwoRef = useRef(null);
  const boxThreeRef = useRef(null);
  const boxFourRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            const delayClass = `animation-delay-${index}`;
            entry.target.classList.add("is-visible", delayClass);
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    // Observe the refs
    if (boxOneRef.current) observer.observe(boxOneRef.current);
    if (boxTwoRef.current) observer.observe(boxTwoRef.current);
    if (boxThreeRef.current) observer.observe(boxThreeRef.current);
    if (boxFourRef.current) observer.observe(boxFourRef.current);

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="keyPoints container_inside flex_col">
      <div className="flex_between_normal" style={{ gap: "32px" }}>
        <div
          style={{ width: "60%", backgroundColor: "#F3F1EC" }}
          className="little_key_boxes"
          ref={boxOneRef}
        >
          <LandingContainer>
            <KeyNumberItem
              text={Returntext(
                contentManagementData,
                "key_points_first_title",
                lang
              )}
            />
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "key_points_first_text1",
                lang
              )}
              text_color="#000000"
              text_size="24px"
            />
            <LightTextLanding
              text_color="#525252"
              text={Returntext(
                contentManagementData,
                "key_points_first_text2",
                lang
              )}
              text_size="16px"
            />
          </LandingContainer>
        </div>
        <div
          style={{ width: "40%", backgroundColor: "#6AD67D" }}
          className="little_key_boxes"
          ref={boxTwoRef}
        >
          <LandingContainer>
            <KeyNumberItem image={flag} />
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "key_points_second_text1",
                lang
              )}
              text_color="#000000"
              text_size="24px"
            />
            <LightTextLanding
              text_color="#000000"
              text={Returntext(
                contentManagementData,
                "key_points_second_text2",
                lang
              )}
              text_size="16px"
            />
          </LandingContainer>
        </div>
      </div>
      <div
        className="flex_between"
        style={{ gap: "32px", alignItems: "stretch" }}
      >
        <div
          style={{ flexGrow: 1, backgroundColor: "#121D25" }}
          className="little_key_boxes"
          ref={boxThreeRef}
        >
          <LandingContainer>
            <KeyNumberItem
              text={Returntext(
                contentManagementData,
                "key_points_third_title",
                lang
              )}
            />
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "key_points_third_text1",
                lang
              )}
              text_color="#FFFDFA"
              text_size="24px"
            />
            <LightTextLanding
              text_color="#FFFDFA"
              text={Returntext(
                contentManagementData,
                "key_points_third_text2",
                lang
              )}
              text_size="14px"
            />
          </LandingContainer>
        </div>
        <div
          style={{ flexGrow: 1, backgroundColor: "#F3F1EC" }}
          className="little_key_boxes"
          ref={boxFourRef}
        >
          <LandingContainer>
            <KeyNumberItem
              text={Returntext(
                contentManagementData,
                "key_points_fourth_title",
                lang
              )}
            />
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "key_points_fourth_text1",
                lang
              )}
              text_color="#000000"
              text_size="24px"
            />
            <LightTextLanding
              text_color="#000000"
              text={Returntext(
                contentManagementData,
                "key_points_fourth_text2",
                lang
              )}
              text_size="14px"
            />
          </LandingContainer>
        </div>
      </div>
    </div>
  );
}
export default KeyPoints;
