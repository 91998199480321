import React from "react";
import "./signInFormFooter.scss";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useNavigate } from "react-router-dom";

const SignInFormFooter = () => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const navigate = useNavigate();
  return (
    <div className="sign-in-form-footer">
      <div className="txt">
        {Returntext(contentManagementData, "legal", lang)}{" "}
        <span onClick={() => navigate("/landing/terms")} className="link">
          {Returntext(contentManagementData, "TermsOfUse", lang)}{" "}
        </span>
        {/* <a href="/" className="link">
          {Returntext(contentManagementData, "PrivacyPolicy", lang)}{" "}
        </a> */}
      </div>
    </div>
  );
};

export default SignInFormFooter;
