import axios from "axios";
import Cookies from "js-cookie";
import { useQuery } from "react-query";
import refreshAccessToken from "./refresh";

const getUser = async () => {
  const access_token = Cookies.get("access_token");
  const refresh_token = Cookies.get("refresh_token");

  if (!access_token || !refresh_token) {
    return;
  }

  try {
    const params = {
      accessToken: access_token,
    };
    const userDataResponse = await axios.get(
      `${process.env.REACT_APP_API_URL}/customers/search/userId?accessToken=${access_token}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const userData = userDataResponse.data;

    if (userData) {
      localStorage.setItem("user", JSON.stringify(userData));
    } else {
      localStorage.setItem("user", null);
    }

    return userData;
  } catch (err) {
    if (
      err.response &&
      (err.response.status === 401 || err.response.status === 403)
    ) {
      refreshAccessToken();
      return await getUser();
    } else {
      console.log("Error:", err);
      localStorage.setItem("user", null);
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      return null;
    }
  }
};

const useUserData = () => {
  const { data, isLoading, error, refetch } = useQuery("userData", getUser, {
    enabled: !!Cookies.get("access_token"),
  });

  return { data, isLoading, error, refetch };
};

export { useUserData };
