import React from "react";
import "./tipsDrawer.scss";
import TipsContent from "../CustomContents/TipsContent";

const TipsDrawer = ({ cancel, setTipsDrawerActive }) => {
  return (
    <div className="tips-drawer">
      <div className="shadow" onClick={cancel}></div>
      <TipsContent
        setTipsDrawerActive={setTipsDrawerActive}
        cancelClick={cancel}
      />
    </div>
  );
};

export default TipsDrawer;
