import React from "react";
import "./checkBox.scss";
import { ReactComponent as CheckedIcon } from "../../../icons/checked.svg";
import { ReactComponent as CheckedDisabledIcon } from "../../../icons/checkedD.svg";

const CheckBox = ({ state, disable, onClick }) => {
  return (
    <div
      className={`check-box ${disable && "disabled-s"} ${
        state === "active" && "active"
      }`}
      onClick={(e) => {
        if (!disable) {
          e.stopPropagation();
          onClick();
        }
      }}
    >
      {state === "active" && disable && <CheckedDisabledIcon />}
      {!disable && state === "active" && <CheckedIcon />}
    </div>
  );
};

export default CheckBox;
