import "./textLanding.scss";
function BoldTextLanding({
  text,
  text_color,
  text_size,
  line_height,
  addedStyles,
}) {
  return (
    <h2
      className="boldText"
      style={{
        color: text_color,
        fontSize: text_size,
        lineHeight: line_height,
        ...addedStyles,
      }}
    >
      {text}
    </h2>
  );
}
export default BoldTextLanding;
