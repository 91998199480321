import React, { useEffect, useState } from "react";
import "./navbar.scss";
import Gap8 from "../../atoms/Gap8/Gap8";
import { useLocation, useNavigate } from "react-router-dom";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as LangIcon } from "../../../icons/lang.svg";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const signUpRedirect = () => {
    if (location === "/sign-in") {
      navigate("/sign-up");
    } else {
      navigate("/sign-in");
    }
  };
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const initialLanguage = localStorage.getItem("language") || "ka";
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
  const { refetchLang } = useLanguages();
  const changeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ka" : "en";
    setCurrentLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };
  useEffect(() => {
    refetchLang();
    // eslint-disable-next-line
  }, [currentLanguage]);

  return (
    <div className="navbar">
      <img
        src="/images/icons/gz-lg.png"
        alt=""
        className="gz-icon"
        onClick={() => navigate("/")}
      />
      <Gap8
        childs={
          <>
            <BtnCircular
              size={"md"}
              mainColor={"grayBorder"}
              svg={<LangIcon />}
              disabled={false}
              mode={""}
              onClick={changeLanguage}
            />
            <BtnStandardIconText
              mode=""
              txt={
                location === "/sign-in"
                  ? Returntext(contentManagementData, "signUp", lang)
                  : Returntext(contentManagementData, "signIn", lang)
              }
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"grayBorder"}
              onClick={signUpRedirect}
            />
          </>
        }
      />
    </div>
  );
};

export default Navbar;
