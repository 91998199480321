import { useState } from "react";
import "./acordeon.scss";
import BoldTextLanding from "../LandingText/BoldtextLanding";
import LightTextLanding from "../LandingText/LightTextLanding";
import { useLanguages } from "../../../hooks/content";
import { minus, plus } from "../../../assets/landing";

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={toggleAccordion}>
        <BoldTextLanding text={title} text_color="#000000" text_size="18px" />
        <img
          className="lang-icon"
          loading="lazy"
          alt=""
          src={isOpen ? minus : plus}
        />
      </div>
      {isOpen && (
        <div className="accordion-content">
          <LightTextLanding
            text_color="#000000"
            text={content}
            text_size="16px"
          />
        </div>
      )}
    </div>
  );
};

const Accordion = ({ items }) => {
  const { lang } = useLanguages();
  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={lang === "ka" ? item?.acordeonTitleKa : item?.acordeonTitleEN}
          content={
            lang === "ka" ? item?.acordeonContentKa : item?.acordeonContentEN
          }
        />
      ))}
    </div>
  );
};

export default Accordion;
