import React from "react";
import "./infoBoxItem.scss";

const InfoBoxItem = ({ main, sub }) => {
  return (
    <div className="info-box-item">
      <div className="left-wrap">
        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
              fill="#6AD67D"
            />
            <path
              d="M16.5 9L10.6562 16L8 12.8182"
              stroke="#FFFDFA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="right-wrap">
        <div className="top">{main}</div>
        <div className="bottom">{sub}</div>
      </div>
    </div>
  );
};

export default InfoBoxItem;
