import React, { useEffect, useState } from "react";
import Navbar from "../../components/organisms/Navbar/Navbar";
import "./signUp.scss";
import SignUpForm from "../../components/organisms/SignUpForm/SignUpForm";
import InfoBox from "../../components/molecules/InfoBox/InfoBox";
import BtnCircular from "../../ds/BtnCircular/BtnCircular";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../icons/back-arrow.svg";

const SignUp = () => {
  const [screenNumber, setScreenNumber] = useState(0);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sMethod, SetSMethod] = useState("email");
  const [activeMode, setActiveMode] = useState("personal");
  const [countryData, setCountryData] = useState("Georgia +995");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setEmail("");
    setPhone("");
  }, [sMethod]);
  return (
    <div className="sign-up">
      <Navbar />
      <div className="form-wrap">
        <div className="left">
          <div className="left-wrap">
            <BtnCircular
              size={"sm"}
              mainColor={"grayBorder"}
              svg={<BackIcon />}
              disabled={false}
              mode={""}
              onClick={() => {
                if (screenNumber === 0) {
                  navigate("/sign-in");
                } else {
                  setScreenNumber(screenNumber - 1);
                }
              }}
              style={{ marginTop: "28px" }}
            />
            <SignUpForm
              screenNumber={screenNumber}
              setScreenNumber={setScreenNumber}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              sMethod={sMethod}
              SetSMethod={SetSMethod}
              activeMode={activeMode}
              setActiveMode={setActiveMode}
              countryData={countryData}
              setCountryData={setCountryData}
              password={password}
              setPassword={setPassword}
              passwordCheck={passwordCheck}
              setPasswordCheck={setPasswordCheck}
            />
          </div>
        </div>
        <div className="right">
          <InfoBox />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
