import React, { useEffect, useState } from "react";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import "./deliveryDrawer.scss";
import PayedNusxa from "../PayedNusxa/PayedNusxa";
import ListItem from "../../../ds/ListItem/ListItem";
import TimeSlotBox from "../../organisms/TimeSlotBox/TimeSlotBox";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import axios from "axios";
import { useOrders } from "../../../hooks/orders";
import DeliveryOptions from "../DeliveryOptions/DeliveryOptions";
import Cookies from "js-cookie";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import PaymentLoader from "../../../loaders/PaymentLoader";
import { useSnackbar } from "notistack";
import { useTimeSlot } from "../../../hooks/ts";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import { ReactComponent as SuccessIcon } from "../../../icons/s-d.svg";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../../icons/work.svg";
import { ReactComponent as TimeSlotIcon } from "../../../icons/ts-sc.svg";
import Lottie from "lottie-react";
import LoaderAnimation from "../../../assets/animations/Loaders/1 - Green/data.json";
import useMobileVhFix from "../../../hooks/height";

const DeliveryDrawer = ({ cancelClick, activeOrder }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { orders, refetchOrders } = useOrders(
    user.id,
    "RECEIVED_IN_TBILISI_WAREHOUSE"
  );

  const [dt, setDt] = useState("");
  const [isDistrict, setIsDistrict] = useState(false);
  const [atDoor, setAtDoor] = useState(false);
  const [ringBell, setRingBell] = useState(false);
  const { timeSlots } = useTimeSlot();

  const { enqueueSnackbar } = useSnackbar();

  const [ts, setTs] = useState("");
  const access_token = Cookies.get("access_token");

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const updatePaymentStatus = async () => {
    try {
      setLoading(true);

      const orderDate = timeSlots?.today?.includes(ts) ? "today" : "tomorrow";

      await axios.put(
        `${process.env.REACT_APP_API_URL}/orders/time-slot-update/${activeOrder.id}?accessToken=${access_token}`,
        {
          timeSlot: ts,
          day: orderDate,
          do_not_ring_bell: ringBell,
          leave_by_door: atDoor,
        }
      );

      refetchOrders();
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-time", lang),
        {
          variant: "success",
        }
      );
    } catch (error) {
      refetchOrders();
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-time2", lang),
        {
          variant: "success",
        }
      );
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const airwayDate = activeOrder?.order_date;

  let month = "";
  let day = "";

  if (airwayDate) {
    const dateObject = new Date(airwayDate);
    month = dateObject.toLocaleString("default", { month: "short" });
    day = dateObject.getDate();
  }

  useEffect(() => {
    const checkDistrict = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/district/find?lat=${activeOrder?.orderDetail?.lat}&lng=${activeOrder?.orderDetail?.lang}&accessToken=${access_token}`
        );

        if (res?.data === false) {
          setIsDistrict(false);
        } else {
          setIsDistrict(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const checkTime = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/district/date?accessToken=${access_token}`
        );

        setDt(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    checkTime();
    checkDistrict();
  }, [activeOrder]);

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  useMobileVhFix();

  return (
    <div className="delivery-drawer">
      {loader && <PaymentLoader bg={true} />}
      <div className="shadow" onClick={cancelClick}></div>
      <div className="drawer-wrap">
        <div className="header">
          <div className="left"></div>

          <div className="middle">
            {Returntext(
              contentManagementData,
              "Delivery_settled_drawer_header 2",
              lang
            )}
          </div>

          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />}
            disabled={false}
            mode={"active"}
            onClick={cancelClick}
          />
        </div>

        <div className="body-dl">
          {!activeOrder?.timeSlot && (
            <div className="success-box">
              <div className="mdt">
                <SuccessIcon className="st-icon" />
                <div className="mdt-main-txt">
                  {Returntext(contentManagementData, "DMH", lang)}
                </div>
              </div>
            </div>
          )}

          {activeOrder.timeSlot === null && (
            <div className="ts-details">
              <div className="txts">
                <div className="main">
                  {Returntext(
                    contentManagementData,
                    "Delivery_settled_drawer_header",
                    lang
                  )}
                </div>
              </div>

              {isDistrict && <TimeSlotBox ts={ts} setTs={setTs} />}
              {isDistrict && (
                <DeliveryOptions
                  atDoor={atDoor}
                  setAtDoor={setAtDoor}
                  ringBell={ringBell}
                  setRingBell={setRingBell}
                />
              )}

              <ListItem
                hasBorder={true}
                hasPadding={true}
                hasIcon={true}
                headline={
                  activeOrder?.orderDetail?.title !== ""
                    ? activeOrder?.orderDetail?.title
                    : activeOrder?.orderDetail?.tag
                }
                description={`${activeOrder?.orderDetail?.address}, ${activeOrder?.orderDetail?.city}`}
                icon={
                  activeOrder?.orderDetail?.tag === "home" ? (
                    <HomeIcon />
                  ) : (
                    <WorkIcon />
                  )
                }
              />
            </div>
          )}
          {activeOrder.timeSlot !== null && (
            <div className="ts-details">
              <div className="txts">
                <div className="main">
                  {Returntext(contentManagementData, "DRO", lang)}
                </div>
              </div>
              <ListItem
                hasBorder={true}
                hasPadding={true}
                hasIcon={true}
                headline={
                  activeOrder?.orderDetail?.title !== ""
                    ? activeOrder?.orderDetail?.title
                    : activeOrder?.orderDetail?.tag
                }
                description={`${activeOrder?.orderDetail?.address}, ${activeOrder?.orderDetail?.city}`}
                icon={
                  activeOrder?.orderDetail?.tag === "home" ? (
                    <HomeIcon />
                  ) : (
                    <WorkIcon />
                  )
                }
              />

              <ListItem
                hasBorder={true}
                hasPadding={true}
                hasIcon={true}
                headline={Returntext(contentManagementData, "date_time", lang)}
                description={`${
                  month &&
                  day &&
                  `${day} ${Returntext(
                    contentManagementData,
                    month?.toLowerCase(),
                    lang
                  )}`
                }, ${activeOrder.timeSlot}`}
                icon={<TimeSlotIcon />}
              />
            </div>
          )}

          <PayedNusxa
            main={Returntext(contentManagementData, "PNT", lang)}
            activeOrder={orders.find((order) => order.id === activeOrder.id)}
            type={"delivery"}
          />
        </div>

        {activeOrder.timeSlot === null && isDistrict && (
          <div className="footer">
            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "cancel", lang)}
              disabled={false}
              size={"md"}
              icon={false}
              mainColor={"white"}
              onClick={cancelClick}
            />

            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "delivery_btn", lang)}
              disabled={!ts ? true : false}
              size={"md"}
              icon={false}
              mainColor={"green"}
              onClick={updatePaymentStatus}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryDrawer;
