import { useEffect, useRef, useState } from "react";
import BoldTextLanding from "../../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../../components/atoms/LandingText/LightTextLanding";
import LandingContainer from "../../../../components/atoms/landingContainer/landingContainer";
import BtnStandardLanding from "../../../../ds/BtnStandardLanding/BtnStandardLanding";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";
import deliveryIMG from "../../../../assets/animations/Phone Image/data.json";
import "../home.scss";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
function DeliverySettings() {
  const { contentManagementData } = useContent();
  const { lang } = useLanguages();
  const carRef = useRef(null);
  const [loopCar, setLoopCar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !loopCar) {
            setLoopCar(true);
            setTimeout(() => {
              setLoopCar(false);
            }, 10);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (carRef.current) observer.observe(carRef.current);

    return () => observer.disconnect();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ padding: "64px 0px" }}>
      <div className="container_inside settings flex_evenly" ref={carRef}>
        <Lottie
          animationData={deliveryIMG}
          loop={loopCar}
          style={{ width: "40%" }}
          className="full_width"
        />
        <div style={{ width: "50%" }} className="full_width">
          <LandingContainer>
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "home_delivery_settings_main",
                lang
              )}
              text_color="#000000"
              text_size="40px"
              line_height="52px"
            />
            <LightTextLanding
              text_color="#000000"
              text={Returntext(
                contentManagementData,
                "home_delivery_settings_secondary",
                lang
              )}
              text_size="16px"
            />
            <div>
              <BtnStandardLanding
                mode="active"
                txt={Returntext(
                  contentManagementData,
                  "home_delivery_settings_btn",
                  lang
                )}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"grayBorder"}
                onClick={() => navigate("/landing/delivery")}
                styles={{ backgroundColor: "white", padding: "20px" }}
              />
            </div>
          </LandingContainer>
        </div>
      </div>
    </div>
  );
}
export default DeliverySettings;
