import React from "react";
import "./actionTabCircularBtn.scss";

const ActionTabCircularBtn = ({
  active,
  count,
  svg: SvgIcon,
  svgActive: SvgActiveIcon,
}) => {
  return (
    <div className={`action-tab-circular-btn ${active ? "active" : ""}`}>
      {active ? (
        <SvgActiveIcon className="icon" />
      ) : (
        <SvgIcon className="icon" />
      )}
      <div className="count-circle">{count}</div>
    </div>
  );
};

export default ActionTabCircularBtn;
