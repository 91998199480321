import React from "react";
import "./customBodyS3.scss";
import StepHeader from "../StepHeader/StepHeader";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import Nusxa from "../Nusxa.jsx/Nusxa";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import { ReactComponent as SignHeroIcon } from "../../../icons/sign-hero.svg";
import { ReactComponent as SignIcon } from "../../../icons/sign.svg";

const CustomBodyS3 = ({
  step,
  main,
  sub,
  setTipsDrawerActive,
  getLink,
  activeOrder,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="custom-body-s3">
      <StepHeader
        step={3}
        main={main}
        sub={sub}
        onClick={() => setTipsDrawerActive(true)}
      />

      <div className="sign-doc">
        <SignHeroIcon className="sign-hero" />

        <div className="sign-box">
          {step === "5" && (
            <>
              <div className="main">
                {Returntext(contentManagementData, "customDrawerStep3H", lang)}
              </div>
              <div className="sub">
                {Returntext(contentManagementData, "customDrawerStep3B", lang)}
              </div>
            </>
          )}
          {step === "8" && (
            <div className="main2">
              {Returntext(
                contentManagementData,
                "ErrorMessages-signeddocument2",
                lang
              )}
            </div>
          )}

          {step === "5" && (
            <BtnStandardIconText
              mode=""
              txt={Returntext(
                contentManagementData,
                "customDrawerStep3Btn1",
                lang
              )}
              disabled={false}
              size={"lg"}
              icon={true}
              iconPosition={"left"}
              svg={<SignIcon />}
              mainColor={"green"}
              onClick={() => getLink()}
            />
          )}

          {step === "8" && (
            <BtnStandardIconText
              mode=""
              txt={Returntext(
                contentManagementData,
                "customDrawerStep3Btn2",
                lang
              )}
              disabled={false}
              size={"lg"}
              icon={true}
              iconPosition={"left"}
              svg={<SignIcon />}
              mainColor={"green"}
              onClick={() => getLink()}
            />
          )}
        </div>
      </div>
      <Nusxa activeOrder={activeOrder} />
    </div>
  );
};

export default CustomBodyS3;
