import "./textLanding.scss";
function LightTextLanding({
  text,
  text_color,
  text_size,
  line_height,
  addedStyles,
}) {
  return (
    <h4
      className="lightText"
      style={{
        color: text_color,
        fontSize: text_size,
        lineHeight: line_height,
        ...addedStyles,
      }}
    >
      {text}
    </h4>
  );
}
export default LightTextLanding;
