import BoldTextLanding from "../../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../../components/atoms/LandingText/LightTextLanding";
import BtnStandardLanding from "../../../../ds/BtnStandardLanding/BtnStandardLanding";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";
import pricesAnimation from "../../../../assets/animations/price.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import {
  gzavnili_btn,
  not_checked,
  checkedIMG,
} from "../../../../assets/landing";

function WhyUs() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const navigate = useNavigate();

  return (
    <div className="whyus ">
      <div className="container_inside headerWhyUs">
        <BoldTextLanding
          text_color="#FFFFFF"
          text={Returntext(contentManagementData, "prices_whyUs_title", lang)}
          text_size="56px"
        />
        <LightTextLanding
          text={Returntext(contentManagementData, "prices_whyUs_text", lang)}
          text_color="#FFFFFF"
          text_size="18px"
        />
      </div>
      <div className="price_table container_inside">
        <div className="price_table_cont1"></div>
        <div className="price_table_cont2">
          <img src={gzavnili_btn} alt="" width={"70%"} loading="lazy" />
        </div>
        <div className="price_table_cont3">
          <BoldTextLanding
            text={Returntext(
              contentManagementData,
              "prices_other_platforms",
              lang
            )}
            text_color="#FFFFFF"
            text_size="24px"
          />
        </div>
      </div>

      <div className="whyus_body_bg">
        <div
          className="hide_on_mobile"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            width: "100%",
            height: "100%",
          }}
        >
          <Lottie animationData={pricesAnimation} width={"100%"} />
        </div>
        <div
          className=" container_inside price_table_body"
          style={{ zIndex: 100 }}
        >
          <div className="price_main_container">
            <PriceTableBodyChild
              content={<Case1 text={"prices_table_col1_text1"} />}
              content1={
                <Case2
                  text1={"prices_table_col1_text2_main"}
                  text2={"prices_table_col1_text2_sec"}
                />
              }
              content2={<Case1 text={"prices_table_col1_text3"} />}
            />
            <PriceTableBodyChild
              content={<Case1 text={"prices_table_col2_text1"} />}
              content1={
                <Case2
                  text1={"prices_table_col2_text2_main"}
                  text2={"prices_table_col2_text2_sec"}
                />
              }
              content2={<Case1 text={"prices_table_col2_text3"} />}
            />
            <PriceTableBodyChild
              content={<Case1 text={"prices_table_col3_text1"} />}
              content1={
                <Case2
                  text1={"prices_table_col3_text2_main"}
                  text2={"prices_table_col3_text2_sec"}
                />
              }
              content2={<Case1 text={"prices_table_col3_text3"} />}
            />
            <PriceTableBodyChild
              content={<Case1 text={"prices_table_col4_text1"} />}
              content1={
                <Case2
                  text1={"prices_table_col4_text2_main"}
                  text2={"prices_table_col4_text2_sec"}
                />
              }
              content2={<Case1 text={"prices_table_col4_text3"} />}
            />
            <PriceTableBodyChild
              content={<Case1 text={"prices_table_col5_text1"} />}
              content1={
                <Case1
                  text={"prices_table_col5_text2_main"}
                  customStyles={{ margin: "auto" }}
                />
              }
              content2={<Case1 text={"prices_table_col5_text3"} />}
            />
            <PriceTableBodyChild
              content={<Case1 text={"prices_table_col6_text1"} />}
              content1={<Case3 />}
              content2={<Case3 checked={false} />}
            />
            <PriceTableBodyChild
              borderNone
              content={<Case1 text={"prices_table_col7_text1"} />}
              content1={<Case3 />}
              content2={<Case3 checked={false} />}
            />
          </div>
        </div>
      </div>
      <BtnStandardLanding
        mode="active"
        txt={Returntext(
          contentManagementData,
          "about_delivery_tryBox_btn",
          lang
        )}
        disabled={false}
        size={"standard"}
        icon={false}
        mainColor={"green"}
        onClick={() => navigate("/sign-up")}
        styles={{ margin: "auto" }}
      />
    </div>
  );
}
export default WhyUs;

const PriceTableBodyChild = ({
  content,
  content1,
  content2,
  borderNone = false,
}) => {
  return (
    <div className="price_table_body_child">
      <div
        className="price_table_cont1"
        style={{ borderBottom: borderNone && "none" }}
      >
        {content}
      </div>
      <div
        className="price_table_cont2"
        style={{ borderBottom: borderNone && "none" }}
      >
        {content1}
      </div>
      <div
        className="price_table_cont3"
        style={{ borderBottom: borderNone && "none" }}
      >
        {content2}
      </div>
    </div>
  );
};

const Case1 = ({ text, customStyles }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div style={{ ...customStyles }}>
      <LightTextLanding
        text={Returntext(contentManagementData, text, lang)}
        text_color="#525252"
        text_size="18px"
      />
    </div>
  );
};

const Case2 = ({ text1, text2 }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="dark_cont">
      <LightTextLanding
        text={Returntext(contentManagementData, text1, lang)}
        text_color="#525252"
        text_size="18px"
      />
      <div className="green_box">
        <BoldTextLanding
          text={Returntext(contentManagementData, text2, lang)}
          text_color="#000000"
          text_size="12px"
        />
      </div>
    </div>
  );
};

const Case3 = ({ checked = true }) => {
  return (
    <div className="checked">
      {checked ? (
        <img src={checkedIMG} alt="" loading="lazy" />
      ) : (
        <img src={not_checked} alt="" loading="lazy" />
      )}
    </div>
  );
};
