import { usaImage } from "../../../../assets/landing";
import BoldTextLanding from "../../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../../components/atoms/LandingText/LightTextLanding";
import LandingContainer from "../../../../components/atoms/landingContainer/landingContainer";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";
function USAPart() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="container_inside flex_between usaPart">
      <img alt="" src={usaImage} loading="lazy"/>
      <LandingContainer>
        <BoldTextLanding
          text={Returntext(
            contentManagementData,
            "about_image_text_title",
            lang
          )}
          text_color="#000000"
          text_size="48px"
          line_height="60px"
        />
        <LightTextLanding
          text_color="#000000"
          text={Returntext(
            contentManagementData,
            "about_image_text_text1",
            lang
          )}
          text_size="18px"
        />
        <LightTextLanding
          text_color="#000000"
          text={Returntext(
            contentManagementData,
            "about_image_text_text2",
            lang
          )}
          text_size="18px"
        />
      </LandingContainer>
    </div>
  );
}
export default USAPart;
