import React, { useEffect, useState } from "react";
import "./setPassword.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import InputGz from "../../../ds/InputGz/InputGz";
import { useAddPasswordMutation } from "../../../hooks/auth";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const SetPassword = ({
  password,
  setPassword,
  passwordCheck,
  setPasswordCheck,
  email,
  phone,
  activeMode,
}) => {
  const access_token = Cookies.get("access_token");
  const { mutation: addPassword, createCustomer } = useAddPasswordMutation();
  const { isSuccess } = addPassword;
  const [passwordError, setPasswordError] = useState(null);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const { enqueueSnackbar } = useSnackbar();

  const handlePassword = async () => {
    // Password length validation
    if (password.length < 8) {
      setPasswordError(true);
      enqueueSnackbar(Returntext(contentManagementData, "PasswordRule", lang), {
        variant: "error",
      });
      return;
    }
    // Password match validation
    if (password !== passwordCheck) {
      setPasswordError(true);
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-signin5", lang),
        {
          variant: "error",
        }
      );
      return;
    }

    try {
      await addPassword.mutate({
        password: password,
        access_token: access_token,
        email: email,
        phone: phone,
      });
      // Password mutation has been triggered
    } catch (error) {
      setPasswordError(true);
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-password4", lang),
        {
          variant: "error",
        }
      );
    }
  };

  // Call createCustomer function if needed
  useEffect(() => {
    // Assuming you want to call createCustomer after successful password mutation
    if (isSuccess) {
      createCustomer({ email, phone, access_token, customerType: activeMode });
    }
  }, [isSuccess, createCustomer, email, phone, access_token]);

  return (
    <div className="set-password">
      <div className="input-wrap">
        <InputGz
          type={"password"}
          value={password}
          setter={setPassword}
          label={Returntext(contentManagementData, "password", lang)}
          nid={"Password"}
          disabled={false}
          errorM={passwordError}
        />
        <InputGz
          type={"password"}
          value={passwordCheck}
          setter={setPasswordCheck}
          label={Returntext(contentManagementData, "RePassword", lang)}
          nid={"rp"}
          disabled={false}
          errorM={passwordError}
        />
      </div>
      <div className="bottom-wrap">
        <BtnStandardIconText
          mode=""
          txt={Returntext(contentManagementData, "createAccount", lang)}
          disabled={false}
          size={"standard"}
          icon={false}
          mainColor={"green"}
          onClick={() => {
            setPasswordError(false);
            handlePassword();
          }}
        />
      </div>
    </div>
  );
};

export default SetPassword;
