const Returntext = (data, identifier, lang) => {
  const filteredData = data.filter((item) => item.identifier === identifier);

  if (filteredData.length > 0) {
    const text =
      filteredData[0][`name${lang.charAt(0).toUpperCase() + lang.slice(1)}`];
    return (
      text ||
      `Text not found for identifier '${identifier}' and language '${lang}'`
    );
  } else {
    return "";
  }
};

export { Returntext };
