import React from "react";
import "./accountSettingsTitle.scss";

function AccountSettingsTitle({ main, sub }) {
  return (
    <div className="account-settings-title">
      <div className="main">{main}</div>
      <div className="sub">{sub}</div>
    </div>
  );
}

export default AccountSettingsTitle;
