import React from "react";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";

const DateComponent = ({ airwayDate }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const dateObject = new Date(airwayDate);
  const month = dateObject.toLocaleString("en-US", { month: "short" });
  const day = dateObject.getDate();

  return (
    <div className="t-date">
      {month && day
        ? `${day} ${Returntext(
            contentManagementData,
            month?.toLowerCase(),
            lang
          )}`
        : Returntext(
            contentManagementData,
            "trackingDrawerParcelHeader",
            lang
          )}{" "}
    </div>
  );
};

export default DateComponent;
