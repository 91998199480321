import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";
import refreshAccessToken from "./refresh";

export const useDeleteFileAndUpdateOrder = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(async (fileId) => {
    let access_token = Cookies.get("access_token");

    try {
      // Delete the file
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/declaration_files/${fileId}?accessToken=${access_token}`
      );

      // Refetch orders after deletion and update
      queryClient.invalidateQueries("orders");
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        await refreshAccessToken();
        access_token = Cookies.get("access_token");

        // Retry the deletion with the new access token
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/declaration_files/${fileId}?accessToken=${access_token}`
        );

        // Refetch orders after retry
        queryClient.invalidateQueries("orders");
      } else {
        console.error("Error updating order:", error.message);
        throw error;
      }
    }
  });

  return {
    deleteFileAndUpdateOrder: mutation.mutate,
    isDeleting: mutation.isLoading,
    deleteError: mutation.error,
    isDeleteSuccess: mutation.isSuccess,
  };
};
