import React, { useEffect, useState } from "react";
import "./payedNusxa.scss";
import OrderTableCell from "../../../ds/OrderTableCell/OrderTableCell";
import axios from "axios";
import Cookies from "js-cookie";

const PayedNusxa = ({ main, sub, activeOrder }) => {
  const [orderGroup, setOrderGroup] = useState([]);
  const access_token = Cookies.get("access_token");

  useEffect(() => {
    const getOrderData = async () => {
      try {
        if (activeOrder?.payments[0]?.id !== undefined) {
          const endpoint = `/transactions/${activeOrder?.payments[0]?.id}`;

          const response = await axios.get(
            process.env.REACT_APP_API_URL +
              endpoint +
              `?accessToken=${access_token}`
          );

          setOrderGroup(response?.data?.orders);
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };
    getOrderData();
  }, [activeOrder]);

  return (
    <div className="payed-nusxa">
      <div className="txts">
        <div className="main">{main}</div>
        <div className="sub">{sub}</div>
      </div>
      <div className="items">
        {orderGroup.map((item) => (
          <OrderTableCell breakM={true} key={item?.id} order={item} />
        ))}
      </div>
    </div>
  );
};

export default PayedNusxa;
