import DeliveryBox from "../../../../components/atoms/DeliveryBox/DeliveryBox";
import BoldTextLanding from "../../../../components/atoms/LandingText/BoldtextLanding";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";
import animationData3 from "../../../../assets/animations/icons/3/data.json";
import { carIMG } from "../../../../assets/landing/index.js";

function DeliveryItem() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="container_inside deliveryItem">
      <BoldTextLanding
        text={Returntext(
          contentManagementData,
          "about_delivery_text_title",
          lang
        )}
        text_color="#000000"
        text_size="48px"
        line_height="60px"
      />
      <div
        style={{
          backgroundImage: `url(${carIMG})`,
        }}
        className="bg_image"
      >
        <DeliveryBox
          customStyles={{ backgroundColor: "white" }}
          logoName={animationData3}
          text1={Returntext(
            contentManagementData,
            "about_delivery_text_card_title",
            lang
          )}
          text2={Returntext(
            contentManagementData,
            "about_delivery_text_card_text",
            lang
          )}
        />
      </div>
    </div>
  );
}
export default DeliveryItem;
