import React from "react";
import BoldTextLanding from "../../../components/atoms/LandingText/BoldtextLanding";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../common/returnText";
import "./termsAndConditions.scss";

function TermsAndConditions() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div>
      <div className="terms_conditions">
        <BoldTextLanding
          text={Returntext(
            contentManagementData,
            "title_terms_and_conditions",
            lang
          )}
          text_color="#000000"
          text_size="48px"
        />
      </div>
      <div className="cont">
        {" "}
        <div className="wrap">
          <p>
            წინამდებარე დოკუმენტი არის GZAVNILI-ს მომსახურებით სარგებლობის
            წესები და პირობები, რომელიც ფორმდება მომხმარებელსა და შპს „GZAVNILI
            GE“-ს (ს.ნ. 404430932; რეგისტრირებული მისამართი - ქ. თბილისი,
            საბურთალოს რაიონი, ცინცაძის ქ. N43 ბ, 4ა) შორის და წარმოადგენს
            ორმხრივად მავალდებულებელ ხელშეკრულება.
          </p>
          <p>
            შპს „GZAVNILI GE“ წინამდებარე დოკუმენტში, კონფიდენციალურობის
            პოლიტიკაში და ყველა სხვა სახელმძღვანელოსა და ინსტრუქციაში შემდგომში
            მოხსენიებული იქნება როგორც „GZAVNILI”.
          </p>
          <p>
            წინამდებარე ხელშეკრულება, მასში განხორციელებულ ცვლილებებთან და/ან
            დამატებებთან და GZAVNILI-ს სხვა ნებისმიერ რეგულაციასთან ერთად
            წარმოადგენს ერთიან დოკუმენტს და განიხილება როგორც მომხმარებელთან
            გაფორმებული ერთიანი ხელშეკრულება.
          </p>
          <p>
            ვებგვერდზე რეგისტრაციით მომხმარებელი ეთანხმება და აღიარებს
            წინამდებარე წესებსა და პირობებს, ზიანის ანაზღაურებისა და
            კონფიდენციალურობის პოლიტიკის დოკუმენტს, ასევე აცხადებს თანხმობას,
            გავრცელდეს მასზე მათი სამართლებრივი ძალა.
          </p>
          <p>
            GZAVNILI-ს მიერ მომხმარებლისათვის გასაწევი მომსახურების პირობები
            საჯაროა და იგი გათვალისწინებულია როგორც წინამდებარე დოკუმენტში,
            ასევე GZAVNILI-ს ვებგვერდზე.
          </p>
          <p>
            იმ შემთხვევაში, თუ მომხმარებელი არ ეთანხმება GZAVNILI-ს ვებვერდზე
            განთავსებული სამართლებრივი ხასიათის დოკუმენტების შინაარსს,
            შესაძლებლობა აქვს არ ისარგებლოს ვებგვერდით.
          </p>
          <p>
            ვებგვერდზე რეგისტრაციის შემდგომ მომხმარებელს მიეცემა GZAVNILI-ის
            გადაზიდვის მომსახურებით სარგებლობის შესაძლებლობა.
          </p>
          <p>
            მომხმარებლის მიერ განხორციელებული თითოეული შეკვეთა წარმოადგენს
            ცალკეულ მომსახურების ხელშეკრულებას, რომლის მხარეები არიან ვებგვერდის
            ავტორიზებული მომხმარებელი და შპს „GZAVNILI GE“.
          </p>
          <p>
            შეკითხვის, პრეტენზიის, თხოვნის არსებობის შემთხვევაში, მომხმარებელს
            შეუძლია GZAVNILI-ს დაუკავშირდეს ელექტრონული ფოსტის მეშვეობით.
            GZAVNILI-ს გუნდი მზად არის მომხმარებლის მიერ დასმულ ნებისმიერ
            შეკითხვას გასცეს მაქსიმალურად ამომწურავი პასუხი.
          </p>
          <h2>1. ტერმინთა განმარტება</h2>
          <p>
            წინამდებარე წესებსა და პირობებში გამოყენებულ ტერმინებს გააჩნიათ
            შემდეგი მნიშვნელობა:
          </p>
          <ul>
            <li>
              <strong>
                GZAVNILI (შემდგომში „ჩვენ“, „ჩვენი“, „ვებ-გვერდი“
                „GZAVNILI.COM”)
              </strong>{" "}
              - საქართველოს კანონმდებლობის შესაბამისად დაფუძნებული შპს „GZAVNILI
              GE“, საიდენტიფიკაციო კოდი 404430932, რეგისტრირებული: ქ. თბილისი,
              საბურთალოს რაიონი, ცინცაძის ქ. N43, 4ა.
            </li>
            <li>
              <strong>მომხმარებელი</strong> - GZAVNILI-ს ვებგვერდზე
              რეგისტრირებული იურიდიული ან ფიზიკური პირი, რომელსაც მინიჭებული
              აქვს პერსონალური ოთახის ნომერი (უნიკალური კოდი) და ამანათის
              ჩამოსვლისას ფიქსირდება როგორც საბაჟო დოკუმენტაციაში, ასევე
              GZAVNILI-ს მონაცემთა ბაზაში.
            </li>
            <li>
              <strong>ამანათი</strong> - ნივთი/პროდუქტი/საქონელი/ტვირთი, რომლის
              ტრანსპორტირებას GZAVNILI ახორციელებს მომხმარებლის შეკვეთის
              საფუძველზე.
            </li>
            <li>
              <strong>მომხმარებლის თანხმობა</strong> - მომხმარებლის მიერ
              წინამდებარე დოკუმენტის გაცნობა და მასზე თანხმობის გაცემა, რაც
              გამოიხატება ვებგვერდზე რეგისტრაციით, GZAVNILI-ს ანიჭებს
              მომხმარებლის პერსონალური ხასიათის მონაცემების დამუშავების
              უფლებამოსილებას.
            </li>
            <li>
              <strong>მესამე პირი</strong> - ნებისმიერი ფიზიკური ან იურიდიული
              პირი, საჯარო დაწესებულება.
            </li>
            <li>
              <strong>პირდაპირი მარკეტინგი</strong> - ტელეფონის, ფოსტის,
              ელექტრონული ფოსტის ან სხვა ელექტრონული საშუალებით მონაცემთა
              სუბიექტისთვის ინფორმაციის პირდაპირი და უშუალო მიწოდება ფიზიკური
              პირის და/ან იურიდიული პირის, საქონლის, იდეის, მომსახურების,
              სამუშაოს და/ან წამოწყების, აგრეთვე საიმიჯო და სოციალური
              თემატიკისადმი ინტერესის ფორმირების, შენარჩუნების, რეალიზაციის
              და/ან მხარდაჭერის მიზნით.
            </li>
            <li>
              <strong>რეგისტრაცია</strong> - პირადი ანგარიშის შექმნა,
              პერსონალური ოთახის ნომრის (უნიკალური კოდი) მინიჭება.
            </li>
            <li>
              <strong>ანგარიში/პირადი ოთახი</strong> - მომხმარებლის მიერ
              GZAVNILI-ზე რეგისტრირებული პირადი გვერდი, რომელსაც მინიჭებული აქვს
              ოთახის ნომერი.
            </li>
            <li>
              <strong>ოთახის ნომერი</strong> - მომხმარებლისათვის რეგისტრაციის
              შედეგად ავტომატურად მინიჭებული უნიკალური კოდი.
            </li>
            <li>
              <strong>შპს „მერკური დელივერი“</strong> - საკურიერო კომპანია,
              რომელიც უზრუნველყოფს GZAVNILI-ს მომხმარებლისათვის ამანათ(ებ)ის
              მიწოდებას.
            </li>
            <li>
              <strong>შპს „მერკური დელივერის“ ქვეკონტრაქტორი კომპანია</strong> -
              საკურიერო კომპანი(ებ)ი, რომელიც შპს „მერკური დელივერთან“
              გაფორმებული ხელშეკრულების საფუძველზე განახორციელებს
              მომხმარებლისათვის ამანათის მიწოდებას.
            </li>
          </ul>
          <h2>2. მომსახურების საგანი</h2>
          <p>
            შპს „GZAVNILI GE” წინამდებარე ხელშეკრულებით გათვალისწინებული
            პირობებისა და მოქმედი კანონმდებლობის საფუძველზე საკუთარი სახელითა და
            მომხმარებლის მიერ გაწეული ხარჯით ახორციელებს სატრანსპორტო
            ექსპედიციას.
          </p>
          <p>
            GZAVNILI მომხმარებლის ამანათ(ებ)ის გადაზიდვის ორგანიზებას
            ახორციელებს მისი პარტნიორი ორგანიზაციის საწყობიდან, რომლის სამუშაო
            დრო განისაზღვრება 09:00 საათიდან 18:00 საათამდე, ორშაბათიდან
            პარასკევის ჩათვლით (შაბათ-კვირას არ მუშაობს საწყობი) და მდებარეობს
            შემდეგ მისამართზე:
          </p>
          <address>
            Delaware Address:
            <br />
            Address Line 1<br />
            3007 Rosemont Avenue
            <br />
            Address Line 2<br />
            City Wilmington, State DE, Country USA, Zip Code 19802
            <br />
            Cell Phone - +1 (302) 510 6956; Phone - +1 (302) 510 6956
          </address>
          <h2>3. რეგისტრაცია</h2>
          <p>
            GZAVNILI-ს ვებგვერდზე www.gzavnili.com რეგისტრაცია ნებაყოფლობითია და
            უსასყიდლო. Gzavnili.com-ის მიერ მომსახურების გასაწევად საჭიროა
            ვებგვერდზე რეგისტრაცია, რისთვისაც აუცილებელია სარეგისტრაციო ფორმის
            შევსება შემდეგი მონაცემების მითითებით:
          </p>
          <ul>
            <li>სახელი, გვარი</li>
            <li>პირადი ნომერი</li>
            <li>მისამართი</li>
            <li>ელექტრონული ფოსტა</li>
            <li>ტელეფონის ნომერი</li>
            <li>სისტემაში შესასვლელი პაროლი</li>
          </ul>
          <p>
            მომხმარებელმა ვებგვერდზე რეგისტრაცია უნდა გაიაროს შეკვეთის
            განხორციელებამდე. შეკვეთის განხორციელებამდე რეგისტრაცია/ავტორიზაცია
            ხდება მას მერე რაც მომხმარებელი ვებგვერდის მთავარი მენიუდან აირჩევს
            „ანგარიშზე შესვლას“. ანგარიშზე შესვლა/ავტორიზაცია შესაძლებელია
            ვებგვერდის მეშვეობით. GZAVNILI მომხმარებლის მიერ დოკუმენტაციის
            ხელმოწერისათვის სარგებლობს Signify-ს სერვისით (ელექტრონული
            ხელმოწერა) და მომხმარებელი GZAVNILI-ს ვებგვერდზე რეგისტრაციით
            აცხადებს თანხმობას აღნიშნულთან დაკავშირებით.
          </p>
          <h2>4. მომხმარებელი ადასტურებს და იძლევა გარანტიას, რომ:</h2>
          <ul>
            <li>მის მიერ მითითებული ინფორმაცია არის სრული და სწორი;</li>
            <li>იგი არის 18 წელს მიღწეული, სრულწლოვანი ქმედუნარიანი პირი;</li>
            <li>
              არ გამოიყენებს სხვა პირის მაიდენტიფიცირებელ ინფორმაციას GZAVNILI-ს
              ვებგვერდზე რეგისტრაციის მიზნით;
            </li>
            <li>
              არ გამოიყენებს სახელს, რომელიც შესაძლოა დამამცირებელი და
              შეურაცხმყოფელი აღმოჩნდეს ნებისმიერი სხვა პირისათვის;
            </li>
            <li>
              არ გამოიყენებს ვებგვერდს ყალბი ინფორმაციის გავრცელების მიზნით;
            </li>
            <li>
              არ გამოიყენებს ვებგვერდს სხვა კომპანიის, კომპანიის თანამშრომლის ან
              ნებისმიერი სხვა პირის განსახიერების მიზნით;
            </li>
            <li>
              არ დაარღვევს სხვა პირის უფლებებს, კანონსაწინააღმდეგო და ზიანის
              მიმყენებელი ქმედებით;
            </li>
            <li>
              არ გამოიყენებს მოწყობილობას, პროგრამას, რომლის მიზანი არის
              ინფორმაციის მოპოვება ვებგვერდიდან და/ან ნორმალური
              ფუნქციონირებისათვის ხელის შეშლა;
            </li>
            <li>
              დაიცავს საავტორო და მომიჯნავე უფლებებს, არ განახორციელებს
              GZAVNILI-ზე არსებული ინფორმაციის: ლოგო, პიქტოგრამა, სურათი,
              გრაფიკა, გამოსახულება, პატენტი, სასაქონლო ნიშნები, დიზაინის და
              სხვა ნებისმიერი სახის ინტელექტუალური საკუთრების უნებართვო
              გამოყენებას, დამუშავებასა და გამოქვეყნებას, GZAVNILI-ს წინასწარი
              წერილობითი თანხმობის გარეშე;
            </li>
            <li>არ გამოიყენებს სხვა მომხმარებლის ანგარიშს;</li>
            <li>
              არ შეეცდება მოიპოვოს წვდომა სხვა მომხმარებლის ავტორიზაციის
              ინფორმაციაზე;
            </li>
            <li>
              არ განახორციელებს ისეთ ქმედებას, რომელიც საფრთხეს შეუქმნის
              GZAVNILI-ს გამართულ ფუნქციონირებას;
            </li>
            <li>
              არ განახორციელებს ქმედებას, რომელიც იკრძალება მოქმედი
              კანონმდებლობით, წინამდებარე ხელშეკრულებითა და GZAVNILI-ს
              კონფიდენციალურობის დაცვის პოლიტიკით.
            </li>
          </ul>
          <h2>
            5. ტრანსპორტირების ღირებულება, ამანათის ზომისა და წონის გამოთვლის
            წესი.
          </h2>
          <ul>
            <li>
              GZAVNILI-ს მომსახურებით სარგებლობისას ტრანსპორტირების ღირებულება
              გამოიანგარიშება შემდეგი ორი კრიტერიუმით: 1) ამანათების მოცულობით
              და 2) მისი რეალური (სასწორის) წონის მიხედვით. GZAVNILI იტოვებს
              უფლებას, რეალურ (სასწორის) წონასთან მიმართებით მოცულობითი წონის
              მეტობის შემთხვევაში, მომსახურების საფასური გამოიანგარიშოს
              მოცულობით და რეალურ წონებს შორის მაქსიმალურით, რომელიც
              გათვალიწინებულია საერთაშორისო წესის შესაბამისად.
            </li>
            <li>
              GZAVNILI-ს მომსახურებით სარგებლობისას ამანათის მოცულობითი წონის
              გამოანგარიშება ხორციელდება შემდეგი ფორმულით: სიგრძე * სიგანე *
              სიმაღლე / 6000 - სანტიმეტრებში;
            </li>
            <li>
              მომხმარებლის მიერ GZAVNILI-ს ვებგვერდის მეშვეობით 1 კგ. ამანათის
              ამერიკიდან ტრანსპორტირების ღირებულება განისაზღვრება 8 (რვა) აშშ
              დოლარის ოდენობით, გადახდის დღისთვის არსებული საქართველოს
              კომერციული ბანკის მიერ დადგენილი დოლარის შესყიდვის კურსის
              შესაბამისად.
            </li>
            <li>
              ტრანსპორტირების საფასურის გამოანგარიშებისას წონის შემთხვევაში
              დამრგვალება ხორციელდება 100 (ასი) გრამის სიზუსტით. პარამეტრების
              გამოანგარიშებისას ყუთის ზომის ცდომილება დასაშვებია თითოეული
              გვერდისათვის 5 მმ-ის ოდენობით.
            </li>
            <li>
              ამანათის მოცულობითი ან რეალური (სასწორის) წონის ოდენობის 10 (ათი)
              ან მეტი კგ.-ით გადაჭარბების შემთხვევაში GZAVNILI იტოვებს უფლებას
              მოსთხოვოს მომხმარებელს თანხის წინასწარ ავანსის სახით გადახდა,
              ამასთან უფლებამოსილია არ განახორციელოს ამანათის ტრანსპორტირება
              მომხმარებლის მიერ საავანსო ღირებულების გადახდამდე (ასეთის
              არსებობის შემთხვევაში).
            </li>
            <li>
              ამანათის, რომლის წონა აღემატება 100 (ასი) კგ-ს, ტრანსპორტირების
              ღირებულების გადახდა შესაძლებელია GZAVNILI-ს მიერ მოთხოვნილ იქნას
              წინასწარ ავანსის სახით.
            </li>
            <li>
              GZAVNILI-სათვის საავანსო თანხის გადაუხდელობის საფუძლით ამანათის
              შეჩერების შემთხვევაში, მისი გამოგზავნა განხორციელდება პირველივე
              შესაძლებლობისთანავე, მომხმარებლის მიერ საავანსო თანხის სრულად
              დაფარვის შემდგომ.
            </li>
            <li>
              ლართან მიმართებით უცხოური ვალუტის კურსის გამოანგარიშება
              განხორციელდება მომსახურების ღირებულების გადახდის დღეს არსებული
              საქართველოს კომერციული ბანკის მიერ დადგენილი უცხოური ვალუტის
              შესყიდვის კურსით. GZAVNILI-ს მიერ გაწეული მომსახურების საფასურის
              გადახდა ხორციელდება www.gzavnili.com ვებგვერდზე მითითებული
              გადახდის მეთოდის გამოყენებით. საფასურის სხვა მეთოდით გადახდის
              შემთხვევაში შესაძლებელია ადგილი ჰქონდეს ხარვეზს, კერძოდ, არ
              განხორციელდეს საფასურის გადახდის დროული დადასტურება, რაც შემდგომში
              გამოიწვევს მომხმარებლისათვის ამანათის მიწოდების შეზღუდვას.
            </li>
            <li>
              მომხმარებელს ანგარიშსწორების ვალდებულება წარმოეშვება ამანათის
              საქართველოში ჩამოსვლისთანავე, ანგარიშსწორება უნდა განხორციელდეს
              ამანათის ჩამოსვლის თაობაზე შეტყობინების მიღებისას, მაგრამ
              არაუგვიანეს პირად კაბინეტში გადასახდელი თანხის ასახვიდან 14
              (თოთხმეტი) დღისა. წინააღმდეგ შემთხვევაში, GZAVNILI უფლებამოსილია
              დააკისროს მომხმარებელს ამანათის შენახვის ღირებულების გადახდის
              ვალდებულება, 20 თეთრის ოდენობით ყოველი ვადაგადაცილებული დღისათვის
              საფასურის სრულად დაფარვამდე.
            </li>
            <li>
              მომხმარებელს შეეზღუდება ამანათის მიღების შესაძლებლობა,
              მომსახურების საფასურის და სხვა ნებისმიერი ხარჯის სრულად
              ანაზღაურებამდე.
            </li>
          </ul>
          <h2>6. ამანათის ზომის შემცირება</h2>
          <ul>
            <li>
              მომხმარებელი წინამდებარე დოკუმენტის გაცნობით აცხადებს თანხმობას,
              რომ GZAVNILI-ს ანიჭებს სრულ უფლებამოსილებას, განახორციელოს
              ამანათის ზომის და წონის შემცირების მიზნით მისი გადაფუთვა. იმ
              შემთხვევაში, თუ მომხმარებელი არ არის თანახმა ამანათი გადაიფუთოს
              GZAVNILI-ს მიერ, მას შესაძლებლობა აქვს პირად ოთახში გამორთოს
              გადაფუთვის რეჟიმი. წინააღმდეგ შემთხვევაში, ავტომატურად ჩაითვლება,
              რომ იგი თანხმობას აცხადებს ამანათის გადაფუთვაზე.
            </li>
            <li>
              გადაფუთვის სერვისით სარგებლობისათვის მომხმარებელი არ იხდის
              დამატებით გადასახადს.
            </li>
            <li>
              გადაფუთვის სერვისი თავისი შინაარსით გულისხმობს, ამანათის ზომის
              მაქსიმალურად შემცირებას, რაც თავის თავში მოიაზრებს შეფუთვისას
              დარჩენილი სიცარიელის მაქსიმალურად შევსებას. წინამდებარე პუნქტით
              გათვალისწინებული მიზნის მისაღწევად, მომხმარებელი GZAVNILI-ს
              ანიჭებს უფლებას მიმართოს არაერთ ხერხს, მათ შორის და არა მხოლოდ,
              ყუთის გადაჭრას, ჩაჭრას, გადაკეცვას და/ან სხვაგვარად სიცარიელის
              შევსებას.
            </li>
            <li>
              გადაფუთვის სერვისით სარგებლობა გამოიყენება, თუ ამანათი შედგება
              ტანისამოსისგან და იგი განთავსებულია მუყაოს ყუთში, GZAVNILI-ს
              ენიჭება უფლებამოსილება ამოიღოს ტანისამოსი მუყაოს ყუთიდან და
              განათავსოს პოლიეთილენის შეფუთვაში.
            </li>
            <li>
              იმ შემთხვევაში, თუ ამანათი შედგება ტანისასმოსის და სხვა კატეგორიის
              ნივთ(ებ)ისაგან, GZAVNILI უფლებამოსილია არ განახორციელოს გადაფუთვა,
              თუმცა ამასთანავე მიმართავს ყველა ღონეს ამანათის ზომის შემცირების
              მიზნით, მისი განცალკევებისა და არსებული შეფუთვიდან ამოღების
              გარეშე.
            </li>
            <li>
              GZAVNILI უფლებამოსილია არ განახორციელოს ამანათის გადაფუთვა ერთ
              შეფუთვაში ერთზე მეტი ამანათის გაერთიანების ხარჯზე.
            </li>
            <li>
              გადაფუთვის სერვისით სარგებლობას არ ექვემდებარება ფეხსაცმელი,
              რომელიც მოთავსებულია ე.წ. ქარხნულ შეფუთვაში, ასევე ამანათის
              უსაფრთხოების მიზნების გათვალისწინებით, მსხვრევადი, სასაჩუქრე
              შეფუთვაში განთავსებული და ელექტრო ტექნიკის კატეგორიას მიკუთვნებული
              ნივთი.
            </li>
            <li>
              გადაფუთვის სერვისით სარგებლობის შემთხვევაში, გადაფუთული ამანათის
              დაზიანებისას GZAVNILI სრულად თავისუფლდება ნებისმიერი სახის, მათ
              შორის, მატერიალური პასუხისმგებლობისაგან.
            </li>
            <li>
              მოხმარებელს ეკისრება ვალდებულება GZAVNILI-ს მიაწოდოს მნიშვნელოვანი
              გარემოების შესახებ ინფორმაცია, რომელიც შესაძლოა კავშირში იყოს
              ამანათის უსაფრთხო ტრანსპორტირებასთან, მათ შორის, გადაფუთვის
              თვალსაზრისით.
            </li>
          </ul>
          <h2>7. ამანათის დეკლარირება</h2>
          <ul>
            <li>
              ამანათის დეკლარირებას GZAVNILI-ს ვებგვერდის მეშვეობით ახორციელებს
              მომხმარებელი ამანათის საწყობში მიღებიდან 3 (სამი) დღის
              განმავლობაში. აღნიშნული ვადის უშედეგოდ გასვლის შემთხვევაში
              GZAVNILI იტოვებს უფლებამოსილებას არ განახორციელოს ამანათის
              გადაზიდვა, ხოლო მომხამრებელი კარგავს ამანათის გადაზიდვის ვადის
              გადაცილების საფუძვლით ნებისმიერი სახის მოთხოვნის უფლებას.
            </li>
            <li>
              მომხმარებელს ეკისრება ერთპიროვნული, სრული პასუხისმგებლობა ამანათის
              წინამდებარე მუხლითა და მოქმედი კანონმდებლობით გათვალისწინებულ
              ვადაში არასწორი დეკლარირებისათვის, სახელმწიფოს მიერ მომხმარებლის
              დაჯარიმების შემთხვევაში ჯარიმის გადახდის ვალდებულება ეკისრება
              მომხმარებელს.
            </li>
            <li>
              ინფორმაცია, რომელსაც მომხმარებელი უთითებს ამანათის დეკლარირების
              პროცესში:
              <ul>
                <li>გამომგზავნი პირის დასახელება;</li>
                <li>Tracking code;</li>
                <li>შეფუთვაში განთავსებული ნივთის სახეობა;</li>
                <li>შეფუთვაში არსებული ნივთის ღირებულება.</li>
              </ul>
            </li>
            <li>
              მომხმარებლის მიერ დეკლარირების განუხორციელებლობის შემთხვევაში
              ამანათი გადაეცემა სახელმწიფოს მოქმედი კანონმდებლობის შესაბამისად.
            </li>
          </ul>
          <h2>8. განბაჟება</h2>
          <ul>
            <li>
              ამანათის განბაჟების საჭიროების შემთხვევაში, განბაჟებისათვის საჭირო
              ყველა პროცედურას ახორციელებს GZAVNILI მომხმარებლის მიერ მიწოდებული
              ინფორმაციისა და საქართველოს კანონმდებლობით გათვალისწინებული წესით.
            </li>
            <li>
              საჭიროების შემთხვევაში GZAVNILI იტოვებს უფლებამოსილებას,
              მომხმარებლის მიმართვისას საბაჟო საკითხებთან დაკავშირებით გაუწიოს
              მას საკონსულტაციო ხასიათის დახმარება.
            </li>
            <li>
              განსაბაჟებელ ამანათებთან დაკავშირებული დოკუმენტების შედგენის
              საფასური განისაზღვრება 10 (ათი) ლარით, თითო დეკლარაციაზე.
            </li>
            <li>
              ნებისმიერი ადმინისტრაციული ორგანოს მიერ (მათ შორის საბაჟო
              სამსახური ან ფინანსთა სამინისტრო) GZAVNILI -ს დაჯარიმების
              შემთხვევაში, რომელიც გამოწვეულია მომხმარებლის მიერ, წინამდებარე
              წესებისა და პირობების დარღვევით, GZAVNILI უფლებამოსილია სრულად
              დააკისროს ჯარიმის გადახდის ვალდებულება მომხმარებელს.
            </li>
            <li>
              ამანათი, რომლის იდენტიფიცირებაც ვერ ხერხდება, კერძოდ, ამანათის
              საბაჟოზე შემოსვლიდან არაუგვიანეს 3 (სამი) დღისა არ დაზუსტდება
              ამანათის მაიდენტიფიცირებელი მონაცემები, რომელიც განსაზღვრულია
              წინამდებარე დოკუმენტის 7.2 პუნქტით, ექვემდებარება სახელმწიფოსთვის
              გადაცემას.
            </li>
            <li>
              მომხმარებლის მიერ კანონით დადგენილი დეკლარირების ვადის დარღვევის
              შემთხვევაში, ამანათი გადაეცემა სახელმწიფოს.
            </li>
          </ul>
          <h2>9. მომხმარებლის უფლებები, ვალდებულებები და პასუხისმგებლობა</h2>
          <ul>
            <li>
              GZAVNILI-ს მომსახურებით სარგებლობისათვის მომხმარებელი ვალდებულია
              შეავსოს რეგისტრაციისათვის დადგენილი ფორმა, რის შემდგომაც
              შესაძლებლობა მიეცემა ისარგებლოს „პირადი ოთახით“, ამასთან მიენიჭება
              ოთახის ნომერი.
            </li>
            <li>
              მომხმარებელს ეკისრება პასუხისმგებლობა მის მიერ GZAVNILI-სათვის
              მიწოდებული ინფორმაციის სისრულეზე, სისწორესა და სიზუსტეზე, რომელიც
              ეხება როგორც მის პირად მაიდენტიფიცირებელ, ასევე საკონტაქტო და
              ამანათის შესახებ ინფორმაციას. წინააღმდეგ შემთხვევაში GZAVNILI
              სრულად თავისუფლდება ყველანაირი სახის პასუხისმგებლობისაგან.
            </li>
            <li>
              მომხმარებელი ვალდებულია განახორციელოს ამანათის დეკლარირება
              (მიუთითოს ინფორმაცია ამანათის შესახებ). დეკლარირების შესაძლებლობა
              მომხმარებელს მიეცემა ამანათის გამომგზავნის მიერ თრექინგ კოდის
              მინიჭების შემდეგ, რათა გამარტივდეს ამანათის მის პირად ოთახში
              დროული ასახვა.
            </li>
            <li>
              მომხმარებელს ეკისრება ვალდებულება ამანათის/ამანათის მიღების
              მომენტში შეამოწმოს მისი შიგთავსი და დაზიანების/დანაკლისის
              აღმოჩენის შემთხვევაში პრეტენზიის თაობაზე ინფორმაცია დაუყოვნებლით
              მიაწოდოს კურიერს და არ ჩაიბაროს ამანათი, წინააღმდეგ შემთხვევაში
              მომხმარებელი კარგავს ზიანის ანაზღაურების მოთხოვნის უფლებას,
              შესაბამისად GZAVNILI სრულად იხსნის პასუხისმგებლობას, რაც
              გულისხმობს, რომ მომხმარებელმა ამანათი მიიღო დაუზიანებელ
              მდგომარეობაში, სრული მოცულობით.
            </li>
            <li>
              მომხმარებელი ვალდებულია ამანათის დეკლარირებისას, ასახოს მისი
              რეალური/ზუსტი ღირებულება, ვინაიდან ამანათის დაკარგვის, დაზიანების
              და/ან განადგურების შემთხვევაში ზიანის ოდენობა განისაზღვრება
              დეკლარაციაში მითითებული ნივთ(ებ)ის ღირებულების ოდენობით,
              გამონაკლისს წარმოადგენს, თუ დეკლარაციაში მითითებული ნივთ(ებ)ის
              ღირებულება აღემატება ინვოისში მითითებულ ოდენობას, ასეთ შემთხვევაში
              GZAVNILI-ს პასუხისმგებლობა შემოიფარგლება მხოლოდ ინვოისში
              მითითებული ღირებულების ოდენობით.
            </li>
            <li>
              მომხმარებელი ვალდებულია GZAVNILI-ს მიაწოდოს ამომწურავი ინფორმაცია
              ამანათის განსაკუთრებული მახასიათებლებისა და თვისებების შესახებ
              ასეთის არსებობის შემთხვევაში.
            </li>
            <li>
              მომხმარებელი ვალდებულია GZAVNILI გააფრთხილოს საფრთხის შემცველი
              ამანათის შესახებ და ასეთის აუცილებლობის შემთხვევაში მიაწოდოს მას
              ინფორმაცია აუცილებელი უსაფრთხოების ზომების თაობაზე. წინააღმდეგ
              შემთხვევაში GZAVNILI უფლებამოსილია ნებისმიერ დროსა და ადგილზე
              გადმოტვირთოს, გაანადგუროს ან გააუნვებელყოს საფრთხის შემცველი
              ამანათი, რა დროსაც ზიანის ანაზღაურების ვალდებულებას სრულად იხსნის
              GZAVNILI.
            </li>
            <li>
              მომხმარებელი ვალდებულია გადასცეს GZAVNILI-ს ნებისმიერი დოკუმენტი,
              რომელიც შესაძლოა საჭირო გახდეს ამანათის ტრანსპორტირების
              განხორციელებისათვის.
            </li>
            <li>
              მომხმარებელს ეკისრება ვალდებულება გადაიხადოს ნებისმიერი სახის
              ჯარიმა/პირგასამტეხლო/საურავი, რომელიც GZAVNILI-ს შესაძლოა
              დაეკისროს მომხმარებლის განზრახი ან გაუფრთხილებელი ქმედების
              საფუძველზე.
            </li>
            <li>
              მომხმარებელს ეკისრება ვალდებულება იმოქმედოს მხარეთა ურთიერთობებში
              კეთილსინდისიერების პრინციპების სრული დაცვით.
            </li>
            <li>
              მომხმარებელი ვალდებულია აანაზღაუროს GZAVNILI-ს მიერ დამატებით
              გაწეული ნებისმიერი ხარჯი, ასეთის არსებობის შემთხვევაში.
            </li>
            <li>
              მომხმარებელი უფლებამოსილია მიიღოს წინამდებარე წესებითა და
              პირობებით გათვალისწინებული მომსახურება დროულად, გაუმართლებელი
              შეფერხების გარეშე.
            </li>
            <li>
              მომხმარებელი უფლებამოსილია მიმართოს GZAVNILI-ს შესაბამისი
              დასაბუთებული პრეტენზიით, ასეთის არსებობის შემთხვევაში.
            </li>
            <li>
              მომხმარებელი უფლებამოსილია მოითხოვოს GZAVNILI-ს ბრალეულობის
              შედეგად ამანათის დაზიანებით, დაკარგვით და/ან განადგურებით დამდგარი
              ზიანის ანაზღაურება.
            </li>
            <li>
              მომხმარებელი უფლებამოსილია ამანათის მიტანის ვადის 30 (ოცდაათი)
              დღით გადაცილების შემთხვევაში, მოსთხოვოს GZAVNILI-ს წინამდებარე
              დოკუმენტითა და მოქმედი კანონმდებლობით გათვალისწინებული
              ანაზღაურება.
            </li>
          </ul>
          <h2>10. GZAVNILI-ს უფლებები, ვალდებულებები და პასუხისმგებლობა</h2>
          <ul>
            <li>
              GZAVNILI პასუხისმგებელია ამანათის ტრანსპორტირებაზე, მას შემდეგ რაც
              განხორციელდება ამანათის GZAVNILI-ს საწყობში მიტანა მისი
              ადრესატისათვის გადაცემის მომენტამდე, რაც დასტურდება შესაბამისი
              უფლებამოსილი პირის ხელმოწერით. GZAVNILI პასუხს არ აგებს ამანათზე,
              რომლის მიღებაც არ დასტურდება საწყობის თანამშრომლის მიერ
              ხელმოწერილი შესაბამისი დოკუმენტით.
            </li>
            <li>
              GZAVNILI კისრულობს ვალდებულებას, აუნაზღაუროს მომხმარებელს
              GZAVNILI-ს ბრალეული ქმედების შედეგად ამანათის დაკარგვით და/ან
              დაზიანებით გამოწვეული ზიანი. ზიანის ოდენობა განისაზღვრება
              დეკლარაციაში მითითებული ნივთ(ებ)ის ღირებულების ოდენობით,
              გამონაკლისს წარმოადგენს, თუ დეკლარაციაში მითითებული ნივთ(ებ)ის
              ღირებულება აღემატება ინვოისში მითითებულ ოდენობას, ასეთ შემთხვევაში
              GZAVNILI-ს პასუხისმგებლობა შემოიფარგლება მხოლოდ ინვოისში
              მითითებული ღირებულების ოდენობით.
            </li>
            <li>
              GZAVNILI უფლებამოსილია უარი განაცხადოს ამანათის ტრანსპორტირებაზე,
              თუ მომხმარებელი არ აწვდის ამანათის შესახებ სრულ, ზუსტ და ამომწურავ
              ინფორმაციას.
            </li>
            <li>
              GZAVNILI უფლებამოსილია გააფორმოს ხელშეკრულებები მესამე
              პირებთან/ქვეკონტრაქტორებთან, რაც არ საჭიროებს მომხმარებლის
              დამატებით თანხმობას. მომხარებელი აღნიშნულის თაობაზე თანხმობას
              აცხადებს GZAVNILI-ს ვებგვერდზე რეგისტრაციით.
            </li>
          </ul>
          <h2>11. GZAVNILI უფლებამოსილია</h2>
          <ul>
            <li>
              დადოს ხელშეკრულებები მესამე პირებთან/ქვეკონტრაქტორებთან, რაც არ
              საჭიროებს მომხმარებლის დამატებით თანხმობას. მომხარებელი აღნიშნულის
              თაობაზე თანხმობას აცხადებს წინამდებარე დოკუმენტის
              გაცნობით/ხელმოწერით.
            </li>
            <li>
              მომხმარებელს მოსთხოვოს 100 (ასი) კგ-ზე მეტი წონის ამანათის
              გადაზიდვის შემთხვევაში მომსახურების საფასურის წინასწარ ავანსის
              სახით სრულად და ნაწილობრივ გადახდა.
            </li>
            <li>
              მომხმარებლის მიერ ავანსის თანხის წინასწარ გადაუხდელობის
              შემთხვევაში, GZAVNILI უფლებამოსილია არ განახორციელოს/შეაჩეროს
              ამანათის გადაზიდვა, რომლის წონა აღემატება 100 (ასი) კგ.-ს, ავანსის
              თანხის სრულად დაფარვამდე. ამანათის გადაზიდვას GZAVNILI
              განახორციელებს ავანსის თანხის მიღებიდან გონივრულ ვადაში, პირველივე
              შესაძლებლობისთანავე.
            </li>
            <li>
              GZAVNILI უფლებამოსილია საკუთარი შეხედულებისამებრ განკარგოს
              ამანათი, რომლის მომხმარებლისათვის ჩაბარება ვერ ხორციელდება
              GZAVNILI-ს მიერ გონივრული ვადების დაცვით განხორციელებული სამი
              მცდელობის მიუხედავად და რომელიც გამოწვეულია მომხმარებლის მიზეზით.
              გამონაკლისს წარმოადგენს კანონმდებლობით განსაზღვრული შემთხვევები,
              როდესაც GZAVNILI-ს ეკისრება ამანათის სახელმწიფოსათვის გადაცემის
              ვალდებულება.
            </li>
            <li>
              GZAVNILI თავისუფლდება პასუხისმგებლობისგან ამანათთან დაკავშირებით,
              რომელსაც არ აღენიშნება ვიზუალური დაზიანება და/ან განსხვავდება
              ინვოისში მითითებულისაგან.
            </li>
            <li>
              ამანათის შეფუთვის დაზიანება მომხმარებლის მხრიდან აღმოჩენილ უნდა
              იქნას GZAVNILI-ს მიერ მისთვის ამანათის მიწოდებისას. წინააღმდეგ
              შემთხვევაში მიიჩნევა, რომ ამანათი მისი შიგთავსით მომხმარებლისათვის
              მიწოდებულია დაზიანებისა და დანაკლისის გარეშე, შესაბამისად GZAVNILI
              სრულად თავისუფლდება ნებისმიერი სახის პასუხისმგებლობისაგან.
            </li>
            <li>
              GZAVNILI პასუხს არ აგებს ამანათის დაგვიანებით მიწოდებაზე, რომელიც
              გამოწვეულია ავიაკომპანიის რეისის განრიგის ცვლილების/გადადების
              და/ან GZAVNILI-სგან დამოუკიდებელი ნებისმიერი მიზეზით.
            </li>
            <li>
              GZAVNILI პასუხს არ აგებს მომხმარებლის მხრიდან განზრახ ან
              გაუფრთხილებლობით GZAVNILI-სთვის არასრული, არასწორი და/ან
              დაუზუსტებელი ინფორმაციის მიწოდების შედეგად მისთვის მიყენებული
              ზიანის ანაზღაურებაზე.
            </li>
          </ul>
          <h2>12. პირდაპირი მარკეტინგი</h2>
          <ul>
            <li>
              პირდაპირი მარკეტინგის მიზნებისათვის მომხმარებელი გასცემს თანხმობას
              აქტიური მოქმედებით. ამასთან, მას აქვს შესაძლებლობა ნებისმიერ დროს,
              ყოველგვარი განმარტების/დასაბუთების გარეშე გამოიხმოს მის მიერ
              გაცემული თანხმობა. თავის მხრივ, GZAVNILI შეწყვეტს მომხმარებლისთვის
              პირდაპირი მარკეტინგის განხორციელებას გამოხმობის თაობაზე მოთხოვნის
              მიღებიდან გონივრულ ვადაში, მაგრამ არაუგვიანეს 7 (შვიდი) სამუშაო
              დღისა.
            </li>
            <li>
              GZAVNILI ვალდებულია მომხმარებლის მიერ თანხმობის გაცემისა და
              თანხმობის გამოხმობის დრო და ფაქტი აღრიცხოს და შეინახოს პირდაპირი
              მარკეტინგის განხორციელების ვადით და პირდაპირი მარკეტინგის
              განხორციელების შეწყვეტიდან 1 (ერთი) წლის განმავლობაში.
            </li>
            <li>
              მომხმარებლის მიერ პირდაპირი მარკეტინგის შეწყვეტის თაობაზე მოთხოვნა
              არ ავალდებულებს GZAVNILI-ს შეწყვიტოს ინფორმაციის მიწოდება
              განახლებებისა და ცვლილებების შესახებ.
            </li>
          </ul>
          <h2>13. აკრძალული ნივთის ტრანსპორტირება</h2>
          <ul>
            <li>
              მომხმარებელი ვალდებულია არ განახორციელოს იმგვარი ამანათის
              გაგზავნა, რომელიც აკრძალულია საქართველოს მოქმედი და/ან იმ ქვეყნის
              კანონმდებლობით რომლიდანაც ხორციელდება ამანათის გამოგზავნა.
              აკრძალულ ნივთებთან დაკავშირებით დამატებითი ინფორმაცია გთხოვთ
              იხილოთ ქვევით:
              <ul>
                <li>
                  აშშ-ს საექსპორტო კანონმდებლობის გათვალისწინებით შეზღუდულია
                  ნებისმიერი სახის ცეცხლსასროლი იარაღის, მისი ნაწილის და/ან
                  აქსესუარის, ოპტიკური ლაზერული სამიზნეების, ოპტიკური ბინოკლისა
                  და საბრძოლო დანიშნულების მასალის ტრანსპორტირება. (აღნიშნული
                  სახის პროდუქცია ნადგურდება საწყობში მიღებისთანავე ან გადაეცემა
                  სპეციალურ სამსახურს). ამანათები არ გადაიგზავნება სხვა
                  მისამართზე და არ მოხდება მათი გამყიდველთან გაბრუნება
                  ტრანსპორტირებისთვის.
                </li>
                <li>
                  აკრძალული ნივთების სიაში შედის:
                  <ul>
                    <li>ცეცხლსასროლი იარაღი</li>
                    <li>
                      პნევმატური იარაღი (გარდა 4.5მმ კალიბრისა ბალონების გარეშე)
                    </li>
                    <li>
                      ძვირფასი ქვები, ოქრო, ვერცხლი, ან სხვა ღირებული მასალისგან
                      დამზადებული საუველირო ნაწარმი
                    </li>
                    <li>არბალეტი/მშვილდ-ისარი</li>
                    <li>ოპტიკური ლაზერული სამიზნე</li>
                    <li>ოპტიკური სამიზნე საბრძოლო დანიშნულების</li>
                    <li>ფეთქებადი მასალა</li>
                    <li>შხამ-ქიმიკატები</li>
                    <li>სპრეი ან რაიმე სახის დატუმბული ბალონი</li>
                    <li>
                      ნარკოტიკული ნივთიერებები (ასევე ე.წ. ბიო არომატიზატორები)
                    </li>
                    <li>ფსიქოტროპული საშუალებები</li>
                    <li>ფული</li>
                    <li>ჟურნალები და კატალოგები</li>
                    <li>ნებისმიერი სახის არომატიზატორი</li>
                    <li>პორნოგრაფიული სახის პროდუქცია</li>
                    <li>რძის, ხორცის, თევის პროდუქტები</li>
                    <li>ცხოველები და ფრინველები ან მათი ფიტულები</li>
                    <li>
                      ნებისმიერი სახის მცენარის თესლი, ან მცენარე, ნერგი და ხე
                    </li>
                    <li>
                      აალებადი და ფეთქებადსაშიში ნივთიერებების ან სითხის
                      შემცველი პროდუქცია
                    </li>
                    <li>სპირტი</li>
                    <li>ავტომობილის/მოტოს ზეთი და დატუმბული ბალონები/სპრეი</li>
                    <li>ავტომობილის უსაფრთხოების ბალიშები</li>
                    <li>ავტომობილის/მოტოს ან სხვა მექანიზმის ამორტიზატორები</li>
                    <li>ავტომობილის/მოტოს ან სხვა მექანიზმის აკუმულატორი</li>
                    <li>საღებავები</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              GZAVNILI ატყობინებს მომხმარებელს, რომ აკრძალული ნივთ(ებ)ის
              კატეგორიას მიკუთვნებული ამანათის გაფრთხილების მიუხედავად გაგზავნის
              შემთხვევაში, GZAVNILI იხსნის პასუხისმგებლობას მომხმარებლისთვის
              მსგავსი ტიპის ამანათის გადაზიდვა/გადაცემაზე.
            </li>
            <li>
              GZAVNILI სრულად იხსნის ზიანის ანაზღაურების პასუხისმგებლობას,
              საფრთხის შემცველი ამანათის, (რომლის შესახებაც არ იყო ინფორმირებული
              მომხმარებლის მიერ) ნებისმიერ დროსა და ნებისმიერ ადგილზე
              გადმოტვირთვის და განადგურებისათვის.
            </li>
            <li>
              წინამდებარე მუხლით გათვალისწინებული წესების დარღვევა, შესაძლოა
              GZAVNILI-ს მიერ მიჩნეულ იქნას მხარეთა შორის არსებული ხელშეკრულების
              შეწყვეტის უპირობო საფუძვლად.
            </li>
            <li>
              წინამდებარე მუხლით გათვალისწინებული წესების დარღვევის შემთხვევაში,
              GZAVNILI უფლებამოსილია მოსთხოვოს მომხმარებელს ზიანის ანაზღაურება
              იმ მოცულობით, რამდენადაც განისაზღვრება GZAVNILI-სათვის მიყენებული
              ზიანი.
            </li>
            <li>
              აკრძალული ამანათის ტრანსპორტირების მუხლში მითითებული აკრძალული
              ნივთ(ებ)ის ჩამონათვალი არ არის ამომწურავი, არასრულია და
              ექვემდებარება მუდმივ განახლებას, შესაბამისად მომხმარებელი
              ვალდებულია არ დაეყრდნოს მხოლოდ წინამდებარე დოკუმენტსა და
              GZAVNILI-ს მიერ მითითებულ ლინკზე არსებულ ჩამონათვალს. ხსენებულ
              ჩამონათვალში ნივთ(ებ)ის დასახელების არარსებობა არ შეიძლება გახდეს
              მომხმარებლის პასუხისმგებლობისგან გათავისუფლების საფუძველი.
            </li>
            <li>
              ინფორმაცია იმის თაობაზე, რომელიმე ნივთი განეკუთვნება თუ არა
              წინამდებარე მუხლით გათვალისწინებულ და სიაში მითითებულ აკრძალულ
              ნივთ(ებ)ს, მომხმარებელს შეუძლია მიიღოს როგორც GZAVNILI-სთვის,
              ასევე სსიპ შემოსავლების სამსახურისთვის მიმართვის გზით.
            </li>
          </ul>
          <h2>14. საკურიერო მომსახურება</h2>
          <ul>
            <li>
              მომხმარებელი აცხადებს თანხმობას, რომ შეკვეთის მიწოდებას
              განახორციელებს შპს „მერკური დელივერი“ (საიდენტიფიკაციო კოდი:
              405430500, რეგისტრირებული: საქართველო, თბილისი, საბურთალოს რაიონი,
              ს. ცინცაძის ქუჩა N43ბ, 4ა).
            </li>
            <li>
              GZAVNILI საკურიერო მომსახურების გაწევის მიზნით, მომხმარებლის
              შესახებ მისთვის ცნობილ, პერსონალური ხასიათის კონფიდენციალურ
              ინფორმაციას, კერძოდ, მომხმარებლის სახელს, გვარს, მისამართსა და
              ტელეფონის ნომერს მიაწოდებს შპს „მერკური დელივერს“.
            </li>
            <li>
              GZAVNILI მომხმარებლის ამანათს გადასცემს საკურიერო კომპანია შპს
              „მერკური დელივერს“, რომელიც ახორციელებს ამანათის ტრანსპორტირებას
              მითითებულ მისამართზე. GZAVNILI-ს პასუხისმგებლობა სრულდება ამანათის
              საკურიერო კომპანიისათვის გადაცემის დროს. ამანათის დაკარგვისა და
              დაზიანების რისკი სრულად გადადის საკურიერო მომსახურების გამწევ
              კომპანიაზე.
            </li>
          </ul>
          <h2>15. სამუშაო განრიგი და ამანათის მიწოდება</h2>
          <ul>
            <li>
              ამანათის მიღება მომხმარებელს შეუძლია როგორც სამუშაო, ასევე
              არასამუშაო დღეებში.
            </li>
            <li>
              ამანათის თბილისის საწყობში მიღების შემდგომ (განსაბაჟებელ
              ამანათებზე განბაჟების პროცესის დასრულების შემდგომ), მომხმარებელს
              საშუალება აქვს, რომ ამანათს მისაღებად აარჩიოს 5 (ხუთი) დროის
              მონაკვეთიდან ერთ-ერთი: 09:00– 12:00; 12:00-15:00; 15:00 – 19:00;
              19:00 – 22:00; 22:00 – 01:00;
            </li>
            <li>
              თბილისის მასშტაბით მომხმარებლისათვის მიწოდების მომსახურების
              ღირებულება განისაზღვრება 8 (რვა) ლარით, მიუხედავად ამანათის
              რაოდენობისა და წონისა.
            </li>
            <li>
              საქართველოს რეგიონში მომხმარებლისათვის მიწოდების მომსახურების
              ღირებულება განისაზღვრება 10 (ათი) ლარით, მიუხედავად ამანათის
              წონისა და რაოდენობისა.
            </li>
            <li>
              ამანათის საქართველოში ჩამოტანის საორიენტაციო ვადა განისაზღვრება
              ამერიკის საწყობში ამანათის მიტანიდან 7-10 სამუშაო დღით. რის
              შემდეგაც, GZAVNILI-ს საწყობში ამანათის მიღების თაობაზე
              მომხმარებელს ეცნობობა შეტყობინების სახით, შესაბამისად მას მიეცემა
              შესაძლებლობა ამანათის მიწოდებისათვის შეთავაზებული დროის შუალედებს
              შორის შეარჩიოს მისთვის მისაღები ნებისმიერი დროის მონაკვეთი.
            </li>
            <li>
              ამანათის ჩაბარების ვადების გამოთვლისას მხედველობაში არ მიიღება
              როგორც საბაჟო ორგანოს მიერ ამანათის შეჩერების, ასევე შპს „მერკური
              დელივერი“-ს მიერ საქართველოს ტერიტორიაზე საკურიერო მომსახურების
              გაწევის ვადა, რომელსაც მომხმარებელი ირჩევს საკუთარი სურვილისამებრ
              ინდივიდუალურად.
            </li>
          </ul>
          <h2>16. პირადი ოთახით სარგებლობის შეზღუდვა და მისი გაუქმება</h2>
          <ul>
            <li>
              თუ GZAVNILI მიიჩნევს, რომ დარღვეულია წინამდებარე დოკუმენტით
              სარგებლობის წესები და პირობები და/ან იგი რეგისტრირებულია ყალბი,
              არასწორი ინფორმაციის მითითებით, იგი უფლებამოსილია ცალმხრივად
              შეუზღუდოს მომხმარებელს წვდომა და პირადი ოთახით სარგებლობის
              შესაძლებლობა ელექტრონულ ფოსტაზე გაგზავნილი შეტყობინების
              საფუძველზე. ამასთან, მომხმარებელს აქვს შესაძლებლობა მსგავსი
              შინაარსის შეტყობინების მიღების შემთხვევაშ დაუკავშირდეს GZAVNILI-ს.
            </li>
            <li>
              მომხმარებელს აქვს შესაძლებლობა გააუქმოს მისი კუთვნილი პირადი ოთახი
              GZAVNILI-სათვის ელექტრონულ ფოსტაზე შეტყობინების გაგზავნის გზით,
              გაუქმების მომენტისათვის GZAVNILI-ს წინაშე არსებული ნებისმიერი
              სახის დავალიანების (ასეთის არსებობის შემთხვევაში) სრულად დაფარვის
              შემდეგ.
            </li>
          </ul>
          <h2>17. საავტორო უფლებები</h2>
          <ul>
            <li>
              ვებგვერდზე განთავსებული ნებისმიერი ტექსტი, გრაფიკული და ანიმაციური
              გამოსახულება წარმოადგენს GZAVNILI-ს ექსკლუზიურ საკუთრებას.
            </li>
            <li>
              GZAVNILI-ს სასაქონლო ნიშანი რეგისტრირებულია საქპატენტში და
              წარმოადგენს შპს „GZAVNILI GE“-ს საკუთრებას. შესაბამისად,
              GZAVNILI-ს წერილობითი ფორმით გამოხატული წინასწარი თანხმობის
              გარეშე, მისი ნებისმიერი სახით გამოყენება მკაცრად აკრძალულია.
              მომხმარებელს არ აქვს უფლება, გამოიყენოს GZAVNILI-ს სასაქონლო
              ნიშანი, ვებგვერდზე განთავსებული გრაფიკული გამოსახულებები ნებისმიერ
              პროდუქტთან ან მომსახურებასთან დაკავშირებით, რამაც შეიძლება
              გამოიწვიოს აღრევა. მომხმარებელს არ აქვს უფლება, გამოიყენოს ისინი
              იმგვარად, რომ დაამციროს ან შეურაცხყოფა მიაყენოს GZAVNILI-ს საქმიან
              რეპუტაციასა და იმიჯს. ნებისმიერი ამგვარი დარღვევის შემთხვევაში,
              GZAVNILI უფლებამოსილია მიმართოს მისი დარღვეული უფლებების დაცვის
              სამართლებრივ საშუალებებს. მომხმარებელს დაეკისრება GZAVNILI-სათვის
              მიყენებული როგორც მატერიალური, ასევე არამატერიალური ზიანის
              ანაზრაურების პასუხისმგებლობა, რომელიც შესაძლოა მოიცავდეს
              GZAVNILI-ს მიერ ზიანის აღმოფხვრისთვის გაწეულ, მათ შორის,
              საადვოკატო მომსახურებისა და სასამართლოს ხარჯებს.
            </li>
            <li>
              ვებგვერდის გამოყენების შედეგად მომხმარებელზე არ გადადის რაიმე
              სახის საავტორო და მომიჯნავე უფლება.
            </li>
          </ul>
          <h2>18. ცვლილებები და დამატებები</h2>
          <ul>
            <li>
              წინამდებარე დოკუმენტში ცვლილებას და/ან დამატებას GZAVNILI
              ახორციელებს ცალმხრივად, მომხმარებელთან შეთანხმების გარეშე.
              აღნიშნულის თაობაზე მომხმარებელს ეცნობება პირად ოთახში მისთვის
              თვალსაჩინოდ გამოტანილი დროებითი ფანჯრის მეშვეობით, რომელსაც
              მომხმარებელი დაეთანხმება თანხმობის ღილაკის მონიშვნით და გაეცნობა
              წინამდებარე დოკუმენტში განხორციელებულ ცვლილებას და/ან დამატებას.
              იმ შემთხვევაში, თუ მომხმარებელი არ ეთანხმება მას, ვალდებულია არ
              გააგრძელოს GZAVNILI-ს მომსახურებითა და ვებგვერდით სარგებლობა.
              წინააღმდეგ შემთხვევაში, GZAVNILI მიიჩნევს, რომ მომხმარებელი
              დაეთანხმა მის მიერ ცალმხრივად განხორციელებულ ცვლილებას და/ან
              დამატებას.
            </li>
          </ul>
          <h2>19. მოქმედების ვადა და შეწყვეტა</h2>
          <ul>
            <li>
              წინამდებარე წესები და პირობები ძალაში შედის მომხმარებლის მიერ
              რეგისტრაციის შემდეგ და მოქმედებს მომხმარებლის ანგარიშის
              გაუქმებამდე.
            </li>
            <li>
              წინამდებარე დოკუმენტის მოქმედება შესაძლებელია შეწყდეს საქართველოს
              კანონმდებლობით და/ან წინამდებარე წესებითა და პირობებით
              გათვალისწინებულ შემთხვევებში, აგრეთვე მომხმარებლის ინიციატივით
              ნებისმიერ დროს, შეწყვეტის მომენტისათვის GZAVNILI-ს წინაშე არსებული
              ნებისმიერი სახის დავალიანების (ასეთის არსებობის შემთხვევაში)
              სრულად დაფარვის შემდეგ.
            </li>
          </ul>
          <h2>20. მესამე მხარე</h2>
          <ul>
            <li>
              GZAVNILI-ი თავისუფლდება ნებისმიერი პასუხისმგებლობას სხვა
              ვებგვერდებთან დაკავშირებით, რომლებიც შესაძლოა გარე ბმულის სახით
              განთავსებული იყოს GZAVNILI-ის ვებგვერდზე. ასეთი ვებგვერდის
              შინაარსი, წესები, პირობები და კონფიდენციალურობის პოლიტიკა
              შესაძლებელია განსხვავდებოდეს GZAVNILI-ს პოლიტიკისგან.
            </li>
          </ul>
          <h2>21. კანონმდებლობა და დავის გადაწყვეტა</h2>
          <ul>
            <li>
              წინამდებარე წესები და პირობები რეგულირდება და განიმარტება მოქმედი
              კანონმდებლობის შესაბამისად.
            </li>
            <li>
              ნებისმიერი უთანხმოების შემთხვევაში მხარეები აცხადებენ მზაობას, რომ
              ისინი შეეცდებიან წარმოშობილი უთანხმოება გადაწყვიტონ
              ურთიერთმოლაპარაკების გზით. მისი აღმოფხვრის შეუძლებლობის
              შემთხვევაში, მხარეები უფლებამოსილნი არიან აღნიშნული საკითხის
              განხილვისა და გადაწყვეტილების მიღების მიზნით მიმართონ თბილისის
              საქალაქო სასამართლოს.
            </li>
            <li>
              წინამდებარე წესებისა და პირობების რომელიმე ნაწილის ბათილად ცნობა
              არ გამოიწვევს სხვა ნაწილის ბათილად ცნობას.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
