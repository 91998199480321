import React from "react";
import "./sidebarIcons.scss";
import { useNavigate, useLocation } from "react-router-dom";

const SidebarIcons = ({ headIcons }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = () => {
    if (location.pathname === "/onboarding") {
      navigate("/landing/home");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="sidebar-icons">
      <div className="left-icon" onClick={handleNavigation}>
        {headIcons.gzLogo}
      </div>
    </div>
  );
};

export default SidebarIcons;
