import React from "react";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import CustomBodyS2 from "../../molecules/CustomBodyS2/CustomBodyS2";
import CustomFooterS2 from "../../molecules/CustomFooterS2/CustomFooterS2";
import useUpdateOrderByNusxa from "../../../hooks/custom";
import { useUpdateOrder } from "../../../hooks/orders";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const CustomContentS2 = ({
  cancelClick,
  setTipsDrawerActive,
  setStep,
  activeOrder,
  setFiles,
  files,
  setActiveOrder,
}) => {
  const { updateOrderByNusxa } = useUpdateOrderByNusxa();
  const { refetchOrdersOnSuccess } = useUpdateOrder();

  const handleUpdateCustomStatus = async () => {
    try {
      await updateOrderByNusxa({
        id: activeOrder?.id,
        newData: { custom_status: 3 },
      });
      await refetchOrdersOnSuccess();
      setStep(3);
    } catch (error) {
      console.log(error);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="drawer-wrap">
      <div className="header">
        <div className="left"></div>

        <div className="middle">
          {Returntext(contentManagementData, "customDrawerT", lang)}
        </div>

        <BtnCircular
          size={"sm"}
          mainColor={"white"}
          svg={<CloseIcon />} // Use the SVG component here
          disabled={false}
          mode={"active"}
          onClick={cancelClick}
        />
      </div>

      <div className="body">
        <CustomBodyS2
          main={Returntext(
            contentManagementData,
            "customDrawerStep1_2MH",
            lang
          )}
          step={2}
          setTipsDrawerActive={setTipsDrawerActive}
          activeOrder={activeOrder}
          files={files}
          setFiles={setFiles}
          setActiveOrder={setActiveOrder}
        />
      </div>

      <div className="footer">
        <CustomFooterS2
          handleUpdateCustomStatus={handleUpdateCustomStatus}
          cancelClick={cancelClick}
          step={2} // Assuming `step` is 2 here, adjust if needed
        />
      </div>
    </div>
  );
};

export default CustomContentS2;
