import React from "react";
import "./accountDetailsBox.scss";
import AccountDetailsBoxTitle from "../../atoms/AccountDetailsBoxTitle/AccountDetailsBoxTitle";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import ListItem from "../../../ds/ListItem/ListItem";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as PhoneIcon } from "../../../icons/phone.svg";
import { ReactComponent as MailIcon } from "../../../icons/mail.svg";
import { ReactComponent as PasswordIcon } from "../../../icons/password.svg";
import { setAPopUpActive } from "../../../redux/actions";
import { useDispatch } from "react-redux";

const AccountDetailsBox = ({ setPopUpActive }) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const dispatch = useDispatch();

  return (
    <div className="account-details-box">
      <div className="details">
        <AccountDetailsBoxTitle
          main={Returntext(contentManagementData, "settingsHeadline2", lang)}
          sub={Returntext(contentManagementData, "settingsSubheadline23", lang)}
        />

        <ListItem
          hasBorder={false}
          hasPadding={false}
          hasIcon={true}
          icon={<PhoneIcon />}
          headline={Returntext(contentManagementData, "phone", lang)}
          description={
            userData?.phone !== ""
              ? userData?.phone
              : "No phone number was found"
          }
          buttons={
            userData?.registered &&
            userData?.phone === userData?.registered && (
              <OrderTableLabel
                txt={Returntext(contentManagementData, "default", lang)}
              />
            )
            // ) : (
            //   <span
            //     className="edit"
            //     onClick={() => dispatch(setAPopUpActive(true))}
            //   >
            //     {Returntext(contentManagementData, "update", lang)}
            //   </span>
            // )
          }
        />

        <div className="break" />

        <ListItem
          hasBorder={false}
          hasPadding={false}
          hasIcon={true}
          icon={<MailIcon />}
          headline={Returntext(contentManagementData, "email", lang)}
          description={userData?.email}
          buttons={
            userData?.registered &&
            userData?.email ===
              userData?.registered && (
                <OrderTableLabel
                  txt={Returntext(contentManagementData, "default", lang)}
                />
              )
            //  : (
            //   <span
            //     className="edit"
            //     onClick={() => dispatch(setAPopUpActive(true))}
            //   >
            //     {Returntext(contentManagementData, "update", lang)}
            //   </span>
            // )
          }
        />

        <AccountDetailsBoxTitle
          main={Returntext(contentManagementData, "settingsBOX3", lang)}
        />

        <ListItem
          hasBorder={false}
          hasPadding={true}
          hasHover={true}
          hasIcon={true}
          icon={<PasswordIcon />}
          headline={Returntext(contentManagementData, "password", lang)}
          description={"*********"}
          mode={"active"}
          buttons={
            <BtnStandardIconText
              mode={""}
              txt={Returntext(contentManagementData, "change", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              iconPosition={"left"}
              mainColor={"white"}
              onClick={() => setPopUpActive(true)}
            />
          }
        />

        {/* {userData.customerType === "PERSONAL" ? (
          <>
            {" "}
            <AccountDetailsBoxTitle
              main={Returntext(contentManagementData, "settingsBOX4", lang)}
            />
            <ListItem
              hasBorder={false}
              hasPadding={false}
              hasIcon={false}
              headline={Returntext(contentManagementData, "FullName", lang)}
              description={""}
              buttons={
                <span style={txtStyle}>
                  {userData.first_name} {userData.last_name}
                </span>
              }
            />
            {!userData?.isNotResident && (
              <ListItem
                hasBorder={false}
                hasPadding={false}
                hasIcon={false}
                headline={Returntext(contentManagementData, "FullNameGe", lang)}
                description={""}
                buttons={
                  <span style={txtStyle}>
                    {userData.first_name_ka} {userData.last_name_ka}
                  </span>
                }
              />
            )}
            {!userData?.isNotResident && <div className="break" />}
            <div className="break" />
            <ListItem
              hasBorder={false}
              hasPadding={false}
              hasIcon={false}
              headline={Returntext(contentManagementData, "ID", lang)}
              description={""}
              buttons={<span style={txtStyle}>{userData.id_number}</span>}
            />
          </>
        ) : (
          <>
            <AccountDetailsBoxTitle main={"Company details"} sub={""} />
            <ListItem
              hasBorder={false}
              hasPadding={false}
              hasIcon={false}
              headline={"Company ID"}
              description={""}
              buttons={<span style={txtStyle}>{userData.company_id}</span>}
            />
            <div className="break" />
            <ListItem
              hasBorder={false}
              hasPadding={false}
              hasIcon={false}
              headline={"Company Info"}
              description={""}
              buttons={<span style={txtStyle}>{userData.company_info}</span>}
            />
          </>
        )} */}
      </div>
    </div>
  );
};

export default AccountDetailsBox;
