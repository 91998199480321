import Accordion from "../../../../components/atoms/Acordeon/Acordeon";
import BoldTextLanding from "../../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../../components/atoms/LandingText/LightTextLanding";
import SubscribeBox from "../../../../components/atoms/SubscribeBox/SubscribeBox";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";
function AskedQuestions({ showGreenPart = true }) {
  const { lang } = useLanguages();
  const { contentManagementData, acordeonData } = useContent();
  return (
    <div className="asked_questions">
      <div className="container_inside">
        <div className="flex_between asked_questions_child">
          <div className="flex_col_between_start" style={{ gap: "10px" }}>
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "asked_questions_title",
                lang
              )}
              text_color="#000000"
              text_size="44px"
            />
            <LightTextLanding
              text_color="#000000"
              text={Returntext(
                contentManagementData,
                "asked_questions_text",
                lang
              )}
              text_size="16px"
            />
          </div>
          <Accordion items={acordeonData} />
        </div>
      </div>
      {/* {showGreenPart ? <SubscribeBox /> : null} */}
    </div>
  );
}

export default AskedQuestions;
