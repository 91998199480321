import React from "react";
import "./pInfoFooter.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import useUpdateOrderByNusxa from "../../../hooks/custom";
import { useUpdateOrder } from "../../../hooks/orders";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const PInfoFooter = ({ cancel, setPInfoDrawer, setStep, activeOrder }) => {
  const { updateOrderByNusxa } = useUpdateOrderByNusxa();
  const { refetchOrdersOnSuccess } = useUpdateOrder();

  const onUpdateCustom = async () => {
    try {
      await updateOrderByNusxa({
        id: activeOrder?.id,
        newData: { custom_status: 2, DeclarationPaymentAuto: false },
      });
      await refetchOrdersOnSuccess();
      setStep(2);
      setPInfoDrawer(false);
    } catch (error) {
      console.log(error);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="p-info-footer">
      <BtnStandardIconText
        mode=""
        txt={Returntext(contentManagementData, "cancel", lang)}
        disabled={false}
        size={"standard"}
        icon={false}
        mainColor={"white"}
        onClick={cancel}
      />
      <BtnStandardIconText
        mode=""
        txt={Returntext(contentManagementData, "customDrawerStep1_2Btn", lang)}
        disabled={false}
        size={"standard"}
        icon={false}
        mainColor={"black"}
        onClick={() => {
          onUpdateCustom();
        }}
      />
    </div>
  );
};

export default PInfoFooter;
