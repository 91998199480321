import React, { useEffect, useState } from "react";
import "./nusxa.scss";
import OrderTableCell from "../../../ds/OrderTableCell/OrderTableCell";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import axios from "axios";
import Cookies from "js-cookie";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const Nusxa = ({ activeOrder }) => {
  const [orders, setOrders] = useState([]);
  const access_token = Cookies.get("access_token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/orders/getByNusxa/${activeOrder.id}?accessToken=${access_token}`
        );
        setOrders(response.data.orders);
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };

    fetchData();
  }, []);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="nusxa">
      <div className="txts">
        {Returntext(contentManagementData, "customDrawerStep1CP1", lang)}{" "}
        <span>{orders !== undefined ? orders.length : ""}</span>{" "}
        {Returntext(contentManagementData, "customDrawerStep1CP2", lang)}
      </div>

      {orders?.map((order) => (
        <OrderTableCell
          key={order.id}
          order={order}
          breakM={true}
          buttons={<CheckBox state={"active"} disable={true} />}
        />
      ))}
    </div>
  );
};

export default Nusxa;
