import React, { useState } from "react";
import "./onboardFormFooter.scss";

import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const OnboardFormFooter = ({
  stage,
  setStage,
  sdaVerified,
  handleDetails,
  isChecked,
  setIsChecked,
}) => {
  const handleCheckboxChange = () => {
    if (isChecked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="onboard-form-footer">
      {stage === 0 && (
        <BtnStandardIconText
          mode=""
          txt={Returntext(contentManagementData, "continue", lang)}
          disabled={sdaVerified ? false : true}
          size={"standard"}
          icon={false}
          mainColor={"green"}
          onClick={() => {
            handleDetails();
          }}
        />
      )}
      {stage === 0 && (
        <div className="check">
          <CheckBox
            state={isChecked ? "active" : "c"}
            onClick={handleCheckboxChange}
            disable={false}
          />
          <span className="c-txt">
            {Returntext(contentManagementData, "promo", lang)}
          </span>
        </div>
      )}
    </div>
  );
};

export default OnboardFormFooter;
