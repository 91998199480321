import React, { useState } from "react";
import "./orderPriceDetails.scss";
import ListItem from "../../../ds/ListItem/ListItem";
import PriceItem from "../../atoms/PriceItem/PriceItem";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as UpAIcon } from "../../../icons/up-a.svg";
import { ReactComponent as DownAIcon } from "../../../icons/down-a.svg";
import CheckBox from "../../atoms/CheckBox/CheckBox";

const OrderPriceDetails = ({
  order,
  actives,
  all,
  handleToggleAllOrder,
  handleToggleOrder,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const [clicked, setClicked] = useState(false);

  return (
    <div className={`order-price-details ${clicked && "active"}`}>
      <ListItem
        hasBorder={clicked ? false : true}
        hasPadding={clicked ? false : true}
        hasIcon={true}
        hasHover={clicked ? false : true}
        icon={
          <CheckBox
            state={actives?.includes(order.id) ? "active" : "inactive"}
            disable={
              actives?.findIndex((item) => item === order.id) === 0
                ? true
                : false
            }
            onClick={() => {
              {
                all ? handleToggleAllOrder(order) : handleToggleOrder(order);
              }
            }}
          />
        }
        headline={order.name}
        description={`${order?.tracking_number} (${order.weight}  ${Returntext(
          contentManagementData,
          "Kg",
          lang
        )})`}
        buttons={
          <div className="opd-wrap">
            <span className="opd-price">{order?.prices?.total}₾</span>
            {clicked ? <UpAIcon /> : <DownAIcon />}
          </div>
        }
        onClick={() => setClicked(!clicked)}
      />

      {clicked && (
        <>
          <div className="line"></div>
          <PriceItem
            left={`${Returntext(
              contentManagementData,
              "transportation",
              lang
            )}: ${order.weight} ${Returntext(
              contentManagementData,
              "Kg",
              lang
            )}`}
            right={`${order?.prices?.total}₾`}
          />
          <PriceItem
            left={Returntext(contentManagementData, "Repackaging", lang)}
            right={"0.00₾"}
          />
          <PriceItem
            left={Returntext(contentManagementData, "Insurance", lang)}
            right={"0.00₾"}
          />
        </>
      )}
    </div>
  );
};

export default OrderPriceDetails;
