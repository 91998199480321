import React from "react";
import "./personalDetailsBox.scss";
import AccountDetailsBoxTitle from "../../atoms/AccountDetailsBoxTitle/AccountDetailsBoxTitle";
import ListItem from "../../../ds/ListItem/ListItem";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const txtStyle = {
  color: "#000",
  textAlign: "right",
  fontFamily: "Helvetica Neue LT GEO",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};

const PersonalDetailsBox = () => {
  const userData = JSON.parse(localStorage.getItem("user"));

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="personal-details-box">
      <div className="personal">
        <AccountDetailsBoxTitle
          main={Returntext(contentManagementData, "settingsBOX4", lang)}
        />

        <ListItem
          hasBorder={false}
          hasPadding={false}
          hasIcon={false}
          headline={Returntext(contentManagementData, "FullName", lang)}
          description={""}
          buttons={
            <span style={txtStyle}>
              {userData.first_name} {userData.last_name}
            </span>
          }
        />

        <div className="break" />

        {!userData?.isNotResident && (
          <ListItem
            hasBorder={false}
            hasPadding={false}
            hasIcon={false}
            headline={Returntext(contentManagementData, "FullNameGe", lang)}
            description={""}
            buttons={
              <span style={txtStyle}>
                {userData.first_name_ka} {userData.last_name_ka}
              </span>
            }
          />
        )}

        {!userData?.isNotResident && <div className="break" />}

        <ListItem
          hasBorder={false}
          hasPadding={false}
          hasIcon={false}
          headline={Returntext(contentManagementData, "ID", lang)}
          description={""}
          buttons={<span style={txtStyle}>{userData.id_number}</span>}
        />
      </div>
    </div>
  );
};

export default PersonalDetailsBox;
