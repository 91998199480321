import React from "react";
import BtnCircular from "../BtnCircular/BtnCircular";
import { ReactComponent as WeroIcon } from "../../icons/wero.svg";
import { ReactComponent as DeleteWhiteIcon } from "../../icons/deleteWhite.svg";

const DeclaredDrawerBody = ({ activeOrder, setDeleteItem, setActiveDec }) => {
  return (
    <div className="declared-drawer-body">
      {activeOrder.declaration !== undefined &&
        activeOrder.declaration.map((dec, i) => (
          <div className="product-list" key={i}>
            <div className="product">
              <div className="product-info">
                <div className="left">
                  <div className="image">
                    <WeroIcon className="icon" />
                  </div>
                  <div className="tag">
                    <div className="main">${dec.price}</div>
                    <div className="sub">{dec.category}</div>
                  </div>
                </div>
                <div className="right">
                  {activeOrder?.buttons?.declaration_enable && (
                    <BtnCircular
                      size={"md"}
                      mainColor={"red"}
                      svg={<DeleteWhiteIcon />} // Use the SVG component here
                      disabled={false}
                      mode={""}
                      onClick={() => {
                        setDeleteItem(true);
                        setActiveDec(dec);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DeclaredDrawerBody;
