import React from "react";
import "./emptyOrders.scss";
// import { ReactComponent as AddIcon } from "../../../icons/add.svg";

const EmptyOrders = ({ icon, main, sub, onClick }) => {
  return (
    <div className="empty-orders">
      {icon}
      <div className="actions">
        <div className="txts">
          <div className="main">{main}</div>
          <div className="sub">{sub}</div>
        </div>
        {/* <BtnStandardIconText
          mode=""
          txt={"Add parcel"}
          disabled={false}
          size={"standard"}
          icon={true}
          svg={<AddIcon />}
          iconPosition={"left"}
          mainColor={"green"}
          onClick={onClick}
        /> */}
      </div>
    </div>
  );
};

export default EmptyOrders;
