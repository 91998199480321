import React from "react";
import "./paymentLoader.scss";
import LoaderAnimation from "../assets/animations/Loaders/1 - Green/data.json";
import Lottie from "lottie-react";

const PaymentLoader = ({ bg }) => {
  return (
    <div className={`payment-loader ${bg && "bg"}`}>
      <div className="drawer-loader">
        <Lottie animationData={LoaderAnimation} className="animation" />
      </div>
    </div>
  );
};

export default PaymentLoader;
