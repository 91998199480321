import { useQuery } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";

const fetchShippingAddresses = async () => {
  try {
    const access_token = Cookies.get("access_token");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/addresses?accessToken=${access_token}`
    );
    return response.data.find((dict) => dict.country === "USA");
  } catch (error) {
    console.error("Error fetching addresses:", error);
    throw error;
  }
};

export const useShippingAddresses = () => {
  const { isLoading, error, data, refetch } = useQuery(
    ["shippingAddresses"],
    fetchShippingAddresses,
    {
      onError: (error) => {
        console.error("Error fetching addresses:", error);
      },
    }
  );

  const shippingAddress = data ? data : null;

  return {
    shippingAddress,
    addressesLoading: isLoading,
    addressesError: error,
    refetchAddresses: refetch,
  };
};
