import React, { useState } from "react";
import BtnCircular from "../../ds/BtnCircular/BtnCircular";
import { useNavigate } from "react-router-dom";
import SignInFormHeaderSubTxt from "../../components/atoms/SignInFormHeaderSubTxt/SignInFormHeaderSubTxt";
import SignInFormHeaderMainTxt from "../../components/atoms/SignInFormHeaderMainTxt/SignInFormHeaderMainTxt";
import { useSnackbar } from "notistack";
import { useLoginMutation } from "../../hooks/auth";
import InputGz from "../../ds/InputGz/InputGz";

import BtnStandardIconText from "../../ds/BtnStandardIconText/BtnStandardIconText";

import axios from "axios";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../Landings/common/returnText";
import { ReactComponent as BackIcon } from "../../icons/back-arrow.svg";

const StepThree = ({
  setScreenNumber,
  screenNumber,
  email,
  phone,
  setPassword,
  password,
  setPasswordCheck,
  passwordCheck,
  parentCode,
  setParentCode,
}) => {
  const navigate = useNavigate();

  const [passwordError, setPasswordError] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const loginMutation = useLoginMutation();

  const handlePassword = async () => {
    // Password length validation
    if (password.length < 8) {
      setPasswordError(true);
      enqueueSnackbar(Returntext(contentManagementData, "PasswordRule", lang), {
        variant: "error",
      });
      return;
    }
    // Password match validation
    if (password !== passwordCheck) {
      setPasswordError(true);
      enqueueSnackbar(
        Returntext(contentManagementData, "PasswordMatch", lang),
        {
          variant: "error",
        }
      );
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_URL}/v1/auth/password/reset`,
        {
          newPassword: password,
          code: parentCode,
          phoneOrEmail: email !== "" ? email : `+995${phone}`,
        },
        {
          headers: {
            "x-public-key": process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      // const unique_name = sMethod === "email" ? email : `+995${phone}`;
      loginMutation
        .mutateAsync({
          username: email !== "" ? email : `+995${phone}`,
          password,
        })
        .then((data) => {
          if (data.status !== 201) {
            enqueueSnackbar("Error Sign In!", {
              variant: "error",
            });
          }
        });
    } catch (error) {
      enqueueSnackbar("Otp Code is Incorrect!", {
        variant: "error",
      });
    }
  };

  const handleOTP = (inputValue) => {
    const newValue = inputValue.replace(/\D/g, "").slice(0, 4);
    setParentCode(newValue);
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="reset-wrap">
      <div className="top">
        <BtnCircular
          size={"sm"}
          mainColor={"grayBorder"}
          svg={<BackIcon />}
          disabled={false}
          mode={""}
          onClick={() => {
            if (screenNumber === 0) {
              navigate("/sign-in");
            } else {
              setScreenNumber(screenNumber - 1);
            }
          }}
        />
      </div>
      <div className="middle">
        <div className="tt">
          {" "}
          <SignInFormHeaderMainTxt
            txt={Returntext(
              contentManagementData,
              "PasswordRecoveryTitleStep2Main",
              lang
            )}
          />
          <SignInFormHeaderSubTxt
            txt={Returntext(
              contentManagementData,
              "PasswordRecoveryTitleStep2Sub",
              lang
            )}
          />
        </div>
        <div className="set-password">
          <div className="input-wrap">
            <InputGz
              type={"password"}
              value={password}
              setter={setPassword}
              label={Returntext(contentManagementData, "password", lang)}
              nid={"Password"}
              disabled={false}
              errorM={passwordError}
            />
            <InputGz
              type={"password"}
              value={passwordCheck}
              setter={setPasswordCheck}
              label={Returntext(contentManagementData, "RePasswod", lang)}
              nid={"rp"}
              disabled={false}
              errorM={passwordError}
            />
            <InputGz
              type={"text"}
              value={parentCode}
              setter={handleOTP}
              label={Returntext(contentManagementData, "OtpCode", lang)}
              nid={"rp"}
              disabled={false}
              errorM={passwordError}
            />
          </div>
          <div className="bottom-wrap">
            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "confirm", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"green"}
              onClick={() => {
                setPasswordError(false);
                handlePassword();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
