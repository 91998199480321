import React from "react";
import "./accountDetailsBoxTitle.scss";

const AccountDetailsBoxTitle = ({ main, sub }) => {
  return (
    <div className="account-detail-box-title">
      <div className="main">{main}</div>
      <div className="sub">{sub}</div>
    </div>
  );
};

export default AccountDetailsBoxTitle;
