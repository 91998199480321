function LandingContainer({ children, customStyles }) {
  return (
    <div>
      <div
        className="delivery_main_title"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          ...customStyles,
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default LandingContainer;
