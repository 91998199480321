import React, { useEffect, useState } from "react";
import "./addressDrawer.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import AddressDrawerBody from "../../molecules/AddressDrawerBody/AddressDrawerBody";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import useAddressPost2 from "../../../hooks/address";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import { useSnackbar } from "notistack";
import useMobileVhFix from "../../../hooks/height";

const AddressDrawer = ({ cancelClick, setUserAddress, style }) => {
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [instructions, setInstructions] = useState("");
  const [currentLocation, setCurrentLocation] = useState({
    lat: 41.7056322,
    lng: 44.7870996,
  });

  const [tag, setTag] = useState("home");

  // const { data: userData } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));

  const { enqueueSnackbar } = useSnackbar();

  const [autocomplete, setAutocomplete] = useState(null);

  const { mutateAddress } = useAddressPost2(); // Initialize the hook

  const handleConfirmAddress = async () => {
    try {
      const addressData = {
        address,
        status: "ACTIVE",
        customer_id: userData.id,
        tag,
        title,
        city,
        additionalInstructions: instructions,
        lang: currentLocation ? currentLocation.lng.toString() : "",
        lat: currentLocation ? currentLocation.lat.toString() : "",
      };
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-adress", lang),
        {
          variant: "success",
        }
      );
      mutateAddress(addressData);
      cancelClick();
    } catch (error) {
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-adress2", lang),
        {
          variant: "error",
        }
      );
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  useMobileVhFix();

  return (
    <div className="address-drawer" style={style}>
      <div className="shadow" onClick={cancelClick}></div>
      <div className="drawer-wrap">
        <div className="header">
          <div className="left"></div>

          <div className="middle">
            {Returntext(
              contentManagementData,
              "DeliverySettingsPopUpHeadline",
              lang
            )}
          </div>

          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />} // Use the SVG component here
            disabled={false}
            mode={"active"}
            onClick={cancelClick}
          />
        </div>

        <div className="body-dd">
          <AddressDrawerBody
            tag={tag}
            setTag={setTag}
            title={title}
            setTitle={setTitle}
            setAutocomplete={setAutocomplete}
            address={address}
            setAddress={setAddress}
            city={city}
            setCity={setCity}
            instructions={instructions}
            setInstructions={setInstructions}
            setCurrentLocation={setCurrentLocation}
            currentLocation={currentLocation}
            autocomplete={autocomplete}
          />
        </div>

        <div className="footer">
          <div className="btns-wrap">
            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "cancel", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"white"}
              onClick={cancelClick}
            />

            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "confirm", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"green"}
              onClick={() => handleConfirmAddress()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressDrawer;
