import React from "react";
import InputGz from "../../../ds/InputGz/InputGz";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const ResetPasswordPopUpBody = ({
  password,
  setPassword,
  newPassword,
  setNewPassword,
  rePassword,
  setRePassword,
  passwordError,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <>
      <InputGz
        type={"password"}
        value={password}
        setter={setPassword}
        label={Returntext(contentManagementData, "password", lang)}
        nid={"Current password"}
        disabled={false}
        errorM={passwordError}
      />
      <InputGz
        type={"password"}
        value={newPassword}
        setter={setNewPassword}
        label={Returntext(contentManagementData, "newPassword", lang)}
        nid={"New Password"}
        disabled={false}
        errorM={passwordError}
      />
      <div className="rule">
        {Returntext(contentManagementData, "rulePassword", lang)}
      </div>

      <InputGz
        type={"password"}
        value={rePassword}
        setter={setRePassword}
        label={Returntext(contentManagementData, "RePassword", lang)}
        nid={"Re-enter your password"}
        disabled={false}
        errorM={passwordError}
      />
    </>
  );
};

export default ResetPasswordPopUpBody;
