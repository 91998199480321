import React from "react";
import "./btnCircular.scss";
const BtnCircular = ({
  size,
  mainColor,
  disabled,
  svg,
  svgDisabled,
  mode,
  onClick,
  style,
  type,
  txt,
}) => {
  return (
    <div
      className={
        !disabled
          ? `btn-circular ${size} ${mainColor} ${mode}`
          : `btn-circular ${size} ${mainColor} ${mode} disabled`
      }
      onClick={onClick}
      style={style}
    >
      {type !== "txt" && !disabled ? svg : svgDisabled}

      {type === "txt" && <span className="txt">{txt}</span>}
    </div>
  );
};

export default BtnCircular;
