import React from "react";
import "./tipsFooter.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const TipsFooter = ({ onClick }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <>
      <BtnStandardIconText
        mode=""
        txt={Returntext(contentManagementData, "customDrawerTipsBtn", lang)}
        disabled={false}
        size={"md"}
        icon={false}
        mainColor={"black"}
        style={{ width: "100%" }}
        onClick={onClick}
      />
    </>
  );
};

export default TipsFooter;
