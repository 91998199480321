import React from "react";
import "./customStepsBar.scss";

const CustomStepsBar = ({ stage }) => {
  return (
    <div className="custom-steps-bar">
      <div className={stage === 0 ? "form-step-nav active" : "form-step-nav"} />
      <div className={stage === 1 ? "form-step-nav active" : "form-step-nav"} />
      <div className={stage === 2 ? "form-step-nav active" : "form-step-nav"} />
    </div>
  );
};

export default CustomStepsBar;
