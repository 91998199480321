import Lottie from "lottie-react";
import BoldTextLanding from "../../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../../components/atoms/LandingText/LightTextLanding";
import LandingContainer from "../../../../components/atoms/landingContainer/landingContainer";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";
import dateAnimation from "../../../../assets/animations/dates 1/dates white lines/data.json";

import "../home.scss";
import { timeSlot } from "../../../../assets/landing";
function TimeSlots() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="container_inside timeSlots">
      <div className="boldFont timeSlotHeading">
        <div>
          {Returntext(contentManagementData, "home_timeSlot_header", lang)
            .split(" ")
            .map((word, index) => {
              if (index === 2) {
                return (
                  <span key={index} style={{ color: "#6AD67D" }}>
                    {" " + word}
                  </span>
                );
              }
              return <span key={index}> {word}</span>;
            })}
        </div>
      </div>
      <div className="flex_start_between_reverce" style={{ paddingTop: "5%" }}>
        <div
          className="flex_col_between texts_timeSlot"
          style={{ width: "60%", height: "100%" }}
        >
          <LandingContainer>
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "home_timeSlot_text1",
                lang
              )}
              text_color="#000000"
              text_size="24px"
            />
            <LightTextLanding
              text_color="#000000"
              text={Returntext(
                contentManagementData,
                "home_timeSlot_body1",
                lang
              )}
              text_size="16px"
            />
          </LandingContainer>
          <LandingContainer customStyles={{ marginTop: "4%" }}>
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "home_timeSlot_text2",
                lang
              )}
              text_color="#000000"
              text_size="24px"
            />
            <LightTextLanding
              text_color="#000000"
              text={Returntext(
                contentManagementData,
                "home_timeSlot_body2",
                lang
              )}
              text_size="16px"
            />
          </LandingContainer>
          <LandingContainer customStyles={{ marginTop: "4%" }}>
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "home_timeSlot_text3",
                lang
              )}
              text_color="#000000"
              text_size="24px"
            />
            <LightTextLanding
              text_color="#000000"
              text={Returntext(
                contentManagementData,
                "home_timeSlot_body3",
                lang
              )}
              text_size="16px"
            />
          </LandingContainer>
        </div>

        <div
          style={{
            backgroundImage: `url(${timeSlot})`,
          }}
          className="image_cover"
        >
          <Lottie
            animationData={dateAnimation}
            style={{
              width: "80%",
              margin: "auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TimeSlots;
