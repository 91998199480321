import React, { useMemo } from "react";
import "./sidebarBtn.scss";
import { useNavigate, useLocation } from "react-router-dom";

const SidebarBtn = ({ svg, svgActive, svgDisabled, txt, disabled, paths }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const active = useMemo(() => {
    return (
      Array.isArray(paths) &&
      paths.some((path) => location.pathname.endsWith(path))
    );
  }, [location, paths]);

  const handleClick = () => {
    if (!disabled && paths.length > 0) {
      navigate(paths[0]);
    }
  };

  return (
    <div
      className={`sidebar-btn ${disabled ? "disabled" : ""} ${
        active ? "active" : ""
      }`}
      onClick={handleClick}
    >
      <div className="icon-container">{svg}</div>
      <span className="txt">{txt}</span>
    </div>
  );
};

export default SidebarBtn;
