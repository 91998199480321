import React from "react";
import "./onboardFormHeader.scss";
import OnboardFormTextLg from "../../atoms/OnboardFormTextLg/OnboardFormTextLg";
import OnboardFormTextMd from "../../atoms/OnboardFormTextMd/OnboardFormTextMd";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useNavigate } from "react-router-dom";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const OnboardFormHeader = ({ maintxt, subTxt, stage }) => {
  const navigate = useNavigate();
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="onboard-form-header">
      <div className="top">
        <div className="txts-o">
          <OnboardFormTextLg txt={maintxt} />
          <OnboardFormTextMd txt={subTxt} />
        </div>

        {stage === 1 && (
          <BtnStandardIconText
            mode={""}
            txt={Returntext(contentManagementData, "Skip", lang)}
            disabled={false}
            size={"sm"}
            icon={false}
            mainColor={"grayBorder"}
            onClick={() => navigate("/orders/AWAITING")}
          />
        )}
      </div>
      <div className="bottom">
        <div
          className={stage === 0 ? "form-step-nav active" : "form-step-nav"}
        />
        <div
          className={stage === 1 ? "form-step-nav active" : "form-step-nav"}
        />
      </div>
    </div>
  );
};

export default OnboardFormHeader;
