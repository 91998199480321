import React, { useState, useRef } from "react";
import "./inputGz.scss";

const InputGz = ({
  type,
  value,
  setter,
  label,
  nid,
  disabled,
  errorM,
  style,
  noBorder,
  onKeyDown,
  hasLabel,
}) => {
  const [lbClick, setLbClick] = useState(false);
  const inputRef = useRef(null);

  // Manually focus input on label click for mobile Safari
  const handleLabelClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={
        lbClick || value.length !== 0
          ? `input-gz-wrap  ${!hasLabel && "focused"} ${
              noBorder && "no-border"
            } ${disabled ? "disabled" : ""} ${errorM ? "error" : ""}`
          : `input-gz-wrap ${noBorder && "no-border"} ${
              disabled ? "disabled" : ""
            } ${errorM ? "error" : ""}`
      }
      onFocus={() => {
        if (!hasLabel) {
          setLbClick(true);
        }
      }}
      onBlur={() => {
        if (!hasLabel) {
          setLbClick(false);
        }
      }}
    >
      <input
        name={nid}
        ref={inputRef} // Attach the ref to the input
        className="input-gz"
        type={type}
        value={value}
        onChange={(e) => setter(e.target.value)}
        disabled={disabled}
        style={style}
        onKeyDown={onKeyDown}
        autoFocus={false}
      />

      {!hasLabel && (
        <div
          className="label"
          onClick={handleLabelClick} // Ensure input is focused on label click
        >
          {label}
        </div>
      )}
    </div>
  );
};

export default InputGz;
