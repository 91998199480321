import React from "react";
import "./addOrderBar.scss";
import BtnStandardIconText from "../BtnStandardIconText/BtnStandardIconText";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";
import { useParams } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../icons/add.svg"; // Import the SVG as a React component

const AddOrderBar = ({ onClick }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const { status } = useParams();

  return (
    <div className="add-order-bar">
      <div className="left">
        {Returntext(contentManagementData, "orders_add_order_bar_txt", lang)}
      </div>
      {status === "AWAITING" && (
        <BtnStandardIconText
          mode=""
          txt={Returntext(
            contentManagementData,
            " orders_add_order_bar_button_txt",
            lang
          )}
          disabled={false}
          size={"standard"}
          icon={true}
          svg={<AddIcon />} // Use the SVG component here
          iconPosition={"left"}
          mainColor={"green"}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default AddOrderBar;
