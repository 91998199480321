import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";
import AutocompleteInput from "../AutocompleteInput/AutocompleteInput";
import useCategoryArray from "../DeclareBodies/category";
import InputGz from "../InputGz/InputGz";
import "./productDrawerBody.scss";
import React, { useState } from "react";

const ProductDrawerBody = ({
  itemCategory,
  setItemCategory,
  amount,
  setAmount,
  amountError,
  itemCategoryError,
}) => {
  const [sActive, setSActive] = useState(false);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const categories = useCategoryArray();

  return (
    <div className="product-drawer-body">
      <div className="pricing">
        <div className="txt-head">
          <div className="main">
            {Returntext(contentManagementData, "ProductDetails", lang)}
          </div>
          <div className="sub">
            {Returntext(contentManagementData, "paiDesc", lang)}
          </div>
        </div>

        <AutocompleteInput
          type={"text"}
          value={itemCategory}
          setter={setItemCategory}
          label={Returntext(contentManagementData, "chooseCategory", lang)}
          nid={"category"}
          disabled={false}
          errorM={itemCategoryError}
          sActive={sActive}
          setSActive={setSActive}
          data={categories}
        />

        <div className="amount">
          <InputGz
            type={"number"}
            value={amount}
            setter={(newValue) => {
              // Use regular expression to replace non-digit and non-decimal point characters with empty string
              const sanitizedValue = newValue.replace(/[^0-9.]/g, "");
              // Update the amount state with the sanitized value
              setAmount(sanitizedValue);
            }}
            label={Returntext(contentManagementData, "amount", lang)}
            nid={"amount"}
            disabled={false}
            errorM={amountError}
          />

          {/* <div className="s-msg">
            <div className="left">You will need to pay 18% VAT</div>
            <div className="right">Tell me more</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductDrawerBody;
