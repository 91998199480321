import React from "react";
import "./tableBtn.scss";

const TableBtn = ({
  mode,
  txt,
  svg,
  svgDisabled,
  disabled,
  mainColor,
  icon,
  iconLeft,
  iconRight,
  onClick,
  rightDoubleIcon,
  rightSvg,
  rightSvgDisabled,
  count,
  fullWidth,
}) => {
  return (
    <button
      disabled={disabled}
      className={
        !disabled
          ? `table-btn ${mainColor} 
          ${iconLeft && iconRight ? "icons-d" : ""} ${mode} ${
              fullWidth && "full-width"
            }`
          : `table-btn ${mainColor}           
          ${iconLeft && iconRight ? "icons-d" : ""} ${mode} disabled ${
              fullWidth && "full-width"
            }`
      }
      onClick={onClick}
    >
      {icon && iconLeft && (disabled ? svgDisabled : svg)}
      <span className="txt">{txt}</span>
      {rightDoubleIcon ? (
        <div className={"right-icon-double"}>
          {icon && iconRight && (disabled ? rightSvgDisabled : rightSvg)}
          <div className="red-c">{count}</div>
        </div>
      ) : (
        icon && iconRight && (disabled ? rightSvgDisabled : rightSvg)
      )}
    </button>
  );
};

export default TableBtn;
