import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react";
import BoldTextLanding from "../LandingText/BoldtextLanding";
import LightTextLanding from "../LandingText/LightTextLanding";
import "./deliveryBox.scss";

function DeliveryBox({ logoName, text1, text2, customStyles }) {
  const [isVisible, setIsVisible] = useState(false);
  const deliveryBoxRef = useRef(null);

  useEffect(() => {
    const checkIfVisible = () => {
      if (deliveryBoxRef.current) {
        const rect = deliveryBoxRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;

        if (isVisible) {
          setIsVisible(true);
          // Remove listener after the component becomes visible
          window.removeEventListener("scroll", checkIfVisible);
        }
      }
    };

    // Add scroll event listener to window
    window.addEventListener("scroll", checkIfVisible);
    // Check visibility without scroll (e.g., on initial load)
    checkIfVisible();

    // Clean-up function to remove event listener
    return () => window.removeEventListener("scroll", checkIfVisible);
  }, []);

  return (
    <div
      ref={deliveryBoxRef}
      className={`delivery_box ${isVisible ? "visible" : ""}`}
      style={{ ...customStyles }}
    >
      {isVisible && (
        <div style={{ width: "90px", margin: "0" }}>
          <Lottie animationData={logoName} />
        </div>
      )}
      <BoldTextLanding text={text1} text_color="#000000" text_size="24px" />
      <LightTextLanding text={text2} text_color="#000000" text_size="14px" />
    </div>
  );
}

export default DeliveryBox;
