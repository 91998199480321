import { useNavigate } from "react-router-dom";
import BoldTextLanding from "../../../atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../atoms/LandingText/LightTextLanding";
import { ReactComponent as ArrowIcon } from "../../../../icons/landing/common/burger/arrow.svg";
import BtnStandardLanding from "../../../../ds/BtnStandardLanding/BtnStandardLanding";
import LanguageBtn from "../../../atoms/LanguageBtn/LanguageBtn";
import NavbarLanding from "../../../molecules/NavbarLanding/NavbarLanding";
import HeaderLogoAuthed from "../../../atoms/Logo/HeaderLogoAuthed";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../../../pages/Landings/common/returnText";
import HeaderLogo from "../../../atoms/Logo/HeaderLogo";
import HeaderLine from "../../HeaderLine/headerLine";

const Burger = ({
  data,
  showNavbarButtons = false,
  setBurgerShow,
  burgerShow,
}) => {
  const navigate = useNavigate();

  const showBurger = () => {
    setBurgerShow(!burgerShow);
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const userData = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <div className="burger_menu">
        {/* <HeaderLine /> */}
        <header className={`header_container`}>
          <HeaderLogo />
          <NavbarLanding />
          {/* web view */}
          <div className="buttonDivs">
            <LanguageBtn />
            {!userData ? (
              <BtnStandardLanding
                mode="active"
                txt={Returntext(contentManagementData, "navbar_btn", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"green"}
                onClick={() => navigate("/sign-in")}
              />
            ) : (
              <BtnStandardLanding
                mode="active"
                txt={Returntext(contentManagementData, "dashboard_text", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"green"}
                onClick={() => navigate("/orders/AWAITING")}
              />
            )}
          </div>
          {/* mobile view */}

          <div className="burger_bar">
            {!userData ? (
              <BtnStandardLanding
                mode="active"
                txt={Returntext(contentManagementData, "navbar_btn", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"green"}
                onClick={() => navigate("/sign-in")}
              />
            ) : (
              <BtnStandardLanding
                mode="active"
                txt={Returntext(contentManagementData, "dashboard_text", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"green"}
                onClick={() => navigate("/sign-in")}
              />
            )}
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={showBurger}
            >
              <img
                alt=""
                src="/images/icons/landing/common/burger.svg"
                width={"20px"}
                height={"20px"}
              />
            </div>
          </div>
        </header>

        {data.item1 && (
          <BurgerContainer
            name="Directory"
            data={data.item1}
            setBurgerShow={setBurgerShow}
          />
        )}
        {data.item2 && (
          <BurgerContainer
            name="Legal"
            data={data.item2}
            setBurgerShow={setBurgerShow}
          />
        )}
        {data.item3 && (
          <BurgerContainer
            name="Language"
            data={data.item3}
            setBurgerShow={setBurgerShow}
          />
        )}
      </div>
    </>
  );
};

export default Burger;

function BurgerContainer({ name, data, setBurgerShow }) {
  return (
    <div className="burger_menu_cont">
      <BoldTextLanding text={name} text_color="#000000" text_size="16px" />
      {data.map((item, index) => (
        <BurgerItem key={index} item={item} setBurgerShow={setBurgerShow} />
      ))}
    </div>
  );
}

function BurgerItem({ item, setBurgerShow }) {
  const navigate = useNavigate();
  return (
    <div
      className="burger_menu_item"
      onClick={() => {
        if (item.link) {
          navigate(item.link);
          setBurgerShow(false);
        }
      }}
    >
      <div className="burger_menu_item_child">
        {item.image ? item.image : item.component}

        <LightTextLanding
          text={item.name}
          text_color="#000000"
          text_size="14px"
        />
      </div>
      <ArrowIcon className="lang-icon" /> {/* Use the SVG component here */}
    </div>
  );
}
