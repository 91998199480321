import React from "react";
import TipsBody from "../../molecules/TipsBody/TipsBody";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import { ReactComponent as BackIcon } from "../../../icons/back.svg";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const TipsContent = ({ cancelClick, setTipsDrawerActive }) => {
  return (
    <div className="drawer-wrap">
      <div className="header">
        <div className="left"></div>

        <div className="middle">
          <BackIcon
            className="icon"
            onClick={() => setTipsDrawerActive(false)}
          />
          Custom tips
        </div>
        <BtnCircular
          size={"sm"}
          mainColor={"white"}
          svg={<CloseIcon />} // Use the SVG component here
          disabled={false}
          mode={"active"}
          onClick={cancelClick}
        />
      </div>

      <div className="body lg">
        <TipsBody />
      </div>
    </div>
  );
};

export default TipsContent;
