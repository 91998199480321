import React from "react";
import "./breakOr.scss";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
const BreakOr = () => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="break-or">
      <div className="line"></div>
      <span className="or">
        {Returntext(contentManagementData, "Or", lang)}
      </span>
      <div className="line"></div>
    </div>
  );
};

export default BreakOr;
