import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";
import refreshAccessToken from "./refresh";

export const useOrders = (userId, status) => {
  const { isLoading, error, data, refetch } = useQuery(
    ["orders", userId, status],
    async () => {
      try {
        const access_token = Cookies.get("access_token");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/orders/filterByStatys/${status}?accessToken=${access_token}`
        );
        return response.data;
      } catch (err) {
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          refreshAccessToken();
          const access_token = Cookies.get("access_token");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/orders/filterByStatys/${status}?accessToken=${access_token}`
          );
          return response.data;
        } else {
          console.log("Error:", err);
          localStorage.setItem("user", null);
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          return [];
        }
      }
    }
  );

  let orders = data ? data : [];

  return {
    orders,
    ordersLoading: isLoading,
    ordersError: error,
    refetchOrders: refetch,
  };
};

export const useDeleteOrder = (userId) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (orderId) => {
      const access_token = Cookies.get("access_token");
      try {
        console.log(orderId);
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/orders/delete/soft/userSide?accessToken=${access_token}`,
          {
            ids: [orderId],
          }
        );
        return response.data;
      } catch (err) {
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          await refreshAccessToken();
          const new_access_token = Cookies.get("access_token");
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/orders/delete/soft/userSide?accessToken=${new_access_token}`,
            {
              ids: [orderId],
            }
          );
          return response.data;
        } else {
          console.log("Error:", err);
          localStorage.setItem("user", null);
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          throw err;
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orders", userId]);
      },
    }
  );

  return {
    deleteOrderById: mutation.mutate,
    deleteOrderLoading: mutation.isLoading,
    deleteOrderError: mutation.error,
    deleteOrderSuccess: mutation.isSuccess,
  };
};

export const useUnpaidOrders = (userId) => {
  const { isLoading, error, data, refetch } = useQuery(
    ["orders", userId],
    async () => {
      try {
        const access_token = Cookies.get("access_token");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/orders/getUnpaidOrders/${userId}?accessToken=${access_token}`
        );
        return response.data;
      } catch (error) {
        // console.log(`Failed to fetch orders: ${error.message}`);
        return [];
      }
    }
  );

  let unpadidOrders = data ? data : [];

  return {
    unpadidOrders,
    ordersLoading: isLoading,
    ordersError: error,
    refetchUnpaidOrders: refetch,
  };
};

export const useAddOrder = () => {
  const queryClient = useQueryClient();
  const access_token = Cookies.get("access_token");

  const addOrderMutation = useMutation(
    (data) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/orders?accessToken=${access_token}`,
        data
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("orders");
        queryClient.invalidateQueries("statusQuantity");

        return response;
      },
    }
  );

  const handleAddOrder = async (data) => {
    try {
      return await addOrderMutation.mutateAsync(data);
    } catch (err) {
      console.error("Error adding order:", err);
    }
  };

  return {
    handleAddOrder,
    isAddingOrder: addOrderMutation.isLoading,
    addOrderError: addOrderMutation.error,
    refetchOrdersOnSuccess: queryClient.invalidateQueries.bind(
      queryClient,
      "orders"
    ),
  };
};

export const useCreateDeclaration = () => {
  const queryClient = useQueryClient();
  const access_token = Cookies.get("access_token");

  const createDeclarationMutation = useMutation(
    (data) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/declarations?accessToken=${access_token}`,
        {
          order_id: data.order_id,
          website: data.website,
          category: data.category,
          price: data.price,
          status: data.status,
        }
      ),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("orders");
        return response;
      },
    }
  );

  const handleCreateDeclaration = async (data) => {
    try {
      const res = await createDeclarationMutation.mutateAsync(data);
      return res;
    } catch (err) {
      console.error("Error adding order:", err);
      return err;
    }
  };

  return {
    handleCreateDeclaration,
    isCreatingDeclaration: createDeclarationMutation.isLoading,
    createDeclarationError: createDeclarationMutation.error,
    refetchDeclarationsOnSuccess: queryClient.invalidateQueries.bind(
      queryClient,
      "declarations"
    ),
  };
};

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();
  const access_token = Cookies.get("access_token");

  const updateOrderMutation = useMutation(
    ({ orderId, data }) =>
      axios.put(
        `${process.env.REACT_APP_API_URL}/orders/${orderId}?accessToken=${access_token}`,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("orders");
      },
    }
  );

  const handleUpdateOrder = (orderId, data) =>
    updateOrderMutation.mutateAsync({ orderId, data });

  return {
    handleUpdateOrder,
    isUpdatingOrder: updateOrderMutation.isLoading,
    updateOrderError: updateOrderMutation.error,
    refetchOrdersOnSuccess: queryClient.invalidateQueries.bind(
      queryClient,
      "orders"
    ),
  };
};

export const useStatusQuantity = (userId) => {
  const access_token = Cookies.get("access_token");
  const { isLoading, error, data, refetch } = useQuery(
    ["statusQuantity", userId],
    () =>
      axios.get(
        `${process.env.REACT_APP_API_URL}/orders/statusQuantity/${userId}?accessToken=${access_token}`
      )
  );

  const statusQuantity = data ? data.data : {};

  return {
    statusQuantity,
    statusQuantityLoading: isLoading,
    statusQuantityError: error,
    refetchQ: refetch,
  };
};

export const useUpdateDeclaration = () => {
  const queryClient = useQueryClient();
  const access_token = Cookies.get("access_token");

  const updateDeclarationMutation = useMutation(
    ({ declarationId, data }) =>
      axios.put(
        `${process.env.REACT_APP_API_URL}/declarations/${declarationId}?accessToken=${access_token}`,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("declarations");
      },
    }
  );

  const handleUpdateDeclaration = (declarationId, data) =>
    updateDeclarationMutation.mutateAsync({ declarationId, data });

  return {
    handleUpdateDeclaration,
    isUpdatingDeclaration: updateDeclarationMutation.isLoading,
    updateDeclarationError: updateDeclarationMutation.error,
    refetchDeclarationsOnSuccess: queryClient.invalidateQueries.bind(
      queryClient,
      "declarations"
    ),
  };
};

export const useFileUpload = () => {
  const queryClient = useQueryClient();
  const access_token = Cookies.get("access_token");

  const fileUploadMutation = useMutation(
    (data) => {
      // Create FormData object and append files and other necessary fields
      const formData = new FormData();
      data.files.forEach((file) => {
        formData.append("file", file);
      });

      return axios.post(
        `${
          process.env.REACT_APP_API_URL
        }/s3/upload/declarationFile?orderID=${encodeURIComponent(
          data.orderId
        )}&type=${encodeURIComponent(
          data.fileType
        )}&accessToken=${access_token}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        // Optionally invalidate queries that might need updating after file upload
        queryClient.invalidateQueries("orders");
        queryClient.invalidateQueries("statusQuantity");

        return response;
      },
    }
  );

  const handleFileUpload = async (data) => {
    try {
      return await fileUploadMutation.mutateAsync(data);
    } catch (err) {
      console.error("Error uploading file:", err);
    }
  };

  return {
    handleFileUpload,
    isUploadingFile: fileUploadMutation.isLoading,
    fileUploadError: fileUploadMutation.error,
  };
};
