import React, { useEffect, useLayoutEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import pinIcon from "./pin.png";

import axios from "axios";

const MapWithAutocomplete = ({
  setStreetName,
  currentLocation,
  setCurrentLocation,
  mapStyles,
}) => {
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     const { latitude, longitude } = position.coords;
  //     setCurrentLocation({ lat: latitude, lng: longitude });
  //   });
  //   setLoading(false);
  // }, []);

  const mapStylesDef = {
    height: "124px",
    width: "100%",
    borderRadius: "16px",
  };

  const darkMapStyles = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const handleMarkerDragEnd = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    setCurrentLocation({ lat: newLat, lng: newLng });

    // Fetch the street name using geocoding
    fromLatLng(newLat, newLng)
      .then((response) => {
        console.log(response);
        const address = response.data.results[0].formatted_address;
        setStreetName(address);
      })
      .catch((error) => {
        console.error("Error fetching street name:", error);
      });
  };

  const fromLatLng = (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    return axios.get(url);
  };

  const georgiaBounds = {
    south: 41.054962,
    west: 40.023943,
    north: 43.586548,
    east: 46.736505,
  };

  return (
    <div className="map-container">
      <GoogleMap
        mapContainerStyle={!mapStyles ? mapStylesDef : mapStyles}
        zoom={15}
        center={currentLocation}
        options={{
          // styles: darkMapStyles,
          fullscreenControl: false,
          disableDefaultUI: true,
          restriction: {
            latLngBounds: {
              north: 43.8, // Northernmost point of Georgia
              south: 41.5, // Southernmost point of Georgia
              west: 40.0, // Westernmost point of Georgia
              east: 46.8, // Easternmost point of Georgia
            },
          },
        }}
      >
        {currentLocation && (
          <>
            <Marker
              position={currentLocation}
              icon={{
                url: pinIcon,
                scaledSize: new window.google.maps.Size(48, 48),
              }}
              draggable={true} // Allow the marker to be dragged
              onDragEnd={(e) => handleMarkerDragEnd(e)} // Handle drag end event
            />
          </>
        )}
      </GoogleMap>
    </div>
  );
};

export default MapWithAutocomplete;
