import React, { useEffect, useState } from "react";
import "./paymentDrawer.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import PaymentSummary from "../../molecules/PaymentSummary/PaymentSummary";
import axios from "axios";
import Cookies from "js-cookie";
import { useCustomerAddresses } from "../../../hooks/address";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import { useContent, useLanguages } from "../../../hooks/content";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../../icons/work.svg";
import ListItem from "../../../ds/ListItem/ListItem";
import LoaderAnimation from "../../../assets/animations/Loaders/1 - Green/data.json";
import PayedOrders from "../../molecules/PayedOrders/PayedOrders";
import { ReactComponent as TimeSlotIcon } from "../../../icons/ts-sc.svg";
import Lottie from "lottie-react";
import useMobileVhFix from "../../../hooks/height";

const PayedDrawer = ({ cancelClick, activeOrder, setActiveOrder }) => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [paymentSuccess, setPaymentSucces] = useState(false);

  const [totalValue, setTotalValue] = useState(0);

  const access_token = Cookies.get("access_token");

  useEffect(() => {
    const fetchUnpaidOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/transactions/${activeOrder?.payments[0]?.id}?accessToken=${access_token}`
        );
        setActiveOrder(
          response?.data?.orders.length > 0 ? response?.data?.orders[0] : []
        );
        setSelectedOrders(response?.data?.orders);
        setTotalValue(response.data.amount);
      } catch (error) {
        console.error("Error fetching unpaid orders:", error);
      }
    };

    fetchUnpaidOrders();
  }, []);

  const { customerAddresses } = useCustomerAddresses();

  const [address, setAddress] = useState(
    customerAddresses !== undefined && customerAddresses.length !== 0
      ? customerAddresses.find((address) => address.isDefault === true)
      : ""
  );

  const nextClick = () => {
    setPaymentSucces(true);
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const airwayDate = activeOrder?.order_date;

  let month = "";
  let day = "";

  if (airwayDate) {
    const dateObject = new Date(airwayDate);
    month = dateObject.toLocaleString("default", { month: "short" });
    day = dateObject.getDate();
  }

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  useMobileVhFix();

  return (
    <div className={"pod-drawer"}>
      <div className="shadow" onClick={cancelClick}></div>
      <div className="drawer-wrap">
        <div className="header">
          <div className="left"></div>

          {Returntext(contentManagementData, "PT", lang)}

          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />} // Use the SVG component here
            disabled={false}
            mode={"active"}
            onClick={cancelClick}
          />
        </div>

        {loader && (
          <div className="pd-loader">
            <Lottie animationData={LoaderAnimation} className="animation" />
          </div>
        )}

        {selectedOrders?.length > 0 && (
          <div className="body-p">
            <div className="d-wrap">
              <div className="txts">
                <div className="main">
                  {Returntext(contentManagementData, "PDT", lang)}
                </div>
              </div>
              {activeOrder.timeSlot !== null && (
                <div className="ts-details">
                  <ListItem
                    hasBorder={true}
                    hasPadding={true}
                    hasIcon={true}
                    headline={
                      activeOrder?.orderDetail?.title !== ""
                        ? activeOrder?.orderDetail?.title
                        : activeOrder?.orderDetail?.tag
                    }
                    description={`${activeOrder?.orderDetail?.address}, ${activeOrder?.orderDetail?.city}`}
                    icon={
                      activeOrder?.orderDetail?.tag === "home" ? (
                        <HomeIcon />
                      ) : (
                        <WorkIcon />
                      )
                    }
                  />

                  <ListItem
                    hasBorder={true}
                    hasPadding={true}
                    hasIcon={true}
                    headline={Returntext(
                      contentManagementData,
                      "date_time",
                      lang
                    )}
                    description={`${
                      month &&
                      day &&
                      `${day} ${Returntext(
                        contentManagementData,
                        month?.toLowerCase(),
                        lang
                      )}`
                    }, ${activeOrder.timeSlot}`}
                    icon={<TimeSlotIcon />}
                  />
                </div>
              )}
            </div>

            <PayedOrders
              setSelectedOrders={setSelectedOrders}
              selectedOrders={selectedOrders}
              activeOrder={activeOrder}
              main={Returntext(contentManagementData, "DRO", lang)}
              totalValue={totalValue}
              setTotalValue={setTotalValue}
              all={true}
            />

            <PaymentSummary
              nextClick={nextClick}
              selectedOrders={selectedOrders}
              totalValue={totalValue}
              setActiveOrder={setActiveOrder}
              activeOrder={activeOrder}
              address={address}
              cancelClick={cancelClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PayedDrawer;
