import { useQuery } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";

const refreshAccessToken = () => {
  // Function to refresh access token
};

export const useTransactions = () => {
  const { isLoading, error, data, refetch } = useQuery(
    "transactions",
    async () => {
      try {
        const access_token = Cookies.get("access_token");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/transactions/customer/get?accessToken=${access_token}`
        );
        return response.data;
      } catch (err) {
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          refreshAccessToken();
          const access_token = Cookies.get("access_token");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/transactions?accessToken=${access_token}`
          );
          return response.data;
        } else {
          console.error("Error:", err);
          localStorage.setItem("user", null);
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          return [];
        }
      }
    }
  );

  let transactions = data ? data : [];

  return {
    transactions,
    transactionsLoading: isLoading,
    transactionsError: error,
    refetchTransactions: refetch,
  };
};
