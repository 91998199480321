import LightTextLanding from "../../../components/atoms/LandingText/LightTextLanding";
import Slider from "../../../components/atoms/Slider/Slider";
import "../landingGlobal.scss";
import DeliverySettings from "./helper/DeliverySettings";
import Starter from "./helper/Starter";
import TimeSlots from "./helper/TimeSlots";
import AskedQuestions from "../Delivery/helpers/AskedQuestions";
import "../Delivery/delivery.scss";
import KeyPoints from "../../../components/molecules/keyPoints/keyPoints";
// import MeetOurTeam from "../../../components/molecules/meetOurTeam/meetOurTeam";
import CookiesPolicy from "../cookies/CookiesPolicy";
import { useLanguages, useContent } from "../../../hooks/content";
import { Returntext } from "../common/returnText";
import Lottie from "lottie-react";
import deliveryIMG from "../../../assets/animations/dates 1/lines/data.json";
import LineMobile from "../../../assets/animations/Line - Mobile/data.json";

import { hifive } from "../../../assets/landing";

function Home() {
  const { sliderData } = useContent();
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <>
      <div style={{ backgroundColor: "#121D25" }}>
        <div className="main_wrapper">
          <Starter />
          <div className="slider_wrapper">
            <Slider data={sliderData} />
          </div>
        </div>
        <DeliverySettings />
        <div
          style={{
            backgroundColor: "#FFFDFA",
          }}
        >
          <KeyPoints />
          <div className="homeBGImage">
            <Lottie
              animationData={deliveryIMG}
              style={{ width: "100%", position: "absolute" }}
              className="showOnWeb"
            />
            <Lottie
              animationData={LineMobile}
              style={{ width: "100%", position: "absolute"}}
              className="showOnMobile"
            />
            <img src={hifive} alt="" width="30%" />

            <div className="homeBGImageText">
              <LightTextLanding
                text_color="#000000"
                text={Returntext(
                  contentManagementData,
                  "home_text_with_image_hi5",
                  lang
                )}
                text_size="20px"
              />
            </div>
          </div>

          <TimeSlots />
          {/* <MeetOurTeam /> */}
          <AskedQuestions />
        </div>
      </div>
      <CookiesPolicy />
    </>
  );
}

export default Home;
