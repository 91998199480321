import React from "react";
import "./selectAddress.scss";
import ListItem from "../../../ds/ListItem/ListItem";
import { ReactComponent as PinIcon } from "../../..//icons/pin.svg";
import { ReactComponent as ArrowRightIcon } from "../../..//icons/arrow-r.svg";

const SelectAddress = ({ setAddressDrawer }) => {
  return (
    <div className="select-address">
      <ListItem
        hasBorder={false}
        hasPadding={true}
        hasIcon={true}
        hasHover={true}
        icon={<PinIcon />} // Use the SVG component here
        description={`Add new address`}
        buttons={<ArrowRightIcon />} // Use the SVG component here
        style={{ backgroundColor: "#F3F1EC" }}
        onClick={() => setAddressDrawer(true)}
      />
    </div>
  );
};

export default SelectAddress;
