import axios from "axios";
import BtnCircular from "../BtnCircular/BtnCircular";
import BtnStandardIconText from "../BtnStandardIconText/BtnStandardIconText";
import InputGz from "../InputGz/InputGz";
import "./declareDrawerBody.scss";
import React, { useEffect, useState } from "react";
import UploadBox from "../UploadBox/UploadBox";
import Toogle from "../../components/atoms/Toogle/Toogle";
import ListItem from "../ListItem/ListItem";
import AutocompleteInput from "../AutocompleteInput/AutocompleteInput";
import { useFileUpload, useOrders } from "../../hooks/orders";
import { useDeleteFileAndUpdateOrder } from "../../hooks/files";

import { Returntext } from "../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../hooks/content";
import Cookies from "js-cookie";
import { ReactComponent as BoxIcon } from "../../icons/box-gc.svg";
import { ReactComponent as BoxGreenIcon } from "../../icons/box-g.svg";
import { ReactComponent as ShieldIcon } from "../../icons/shield.svg";
import { ReactComponent as CloseIcon } from "../../icons/x.svg";

const DeclareDrawerBody = ({
  website,
  setWebsite,
  drawerBody,
  btn,
  activeOrder,
  deleteItem,
  setDeleteItem,
  setFiles,
  files,
  activeDec,
  websiteError,
  setActiveOrder,
  setDeleteFile,
  setActiveFile,
  deleteFile,
  activeFile,
}) => {
  const [newName, setNewName] = useState(
    activeOrder?.name ? activeOrder?.name : ""
  );
  const [onAction, setOnAction] = useState(false);
  const [sActive, setSActive] = useState(false);
  const [websitesData, setWebsitesData] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));
  const { orders, refetchOrders } = useOrders(user.id, "AWAITING");

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const access_token = Cookies.get("access_token");
  const { handleFileUpload } = useFileUpload();

  const { deleteFileAndUpdateOrder } = useDeleteFileAndUpdateOrder();

  const handleDelete = (activeFile) => {
    deleteFileAndUpdateOrder(activeFile.id);
    refetchOrders();
  };

  const updateOrder = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/orders/${activeOrder.id}?accessToken=${access_token}`,
        {
          name: newName,
        }
      );
    } catch (error) {
      console.error("Error updating order:", error.message);
    }
  };

  const deleteDeclaration = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/declarations/${activeDec.id}?accessToken=${access_token}`
      );
      if (activeOrder?.declaration.length === 1) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/orders/${activeOrder.id}?accessToken=${access_token}`,
          {
            declaration_status: null,
          }
        );
      }
      refetchOrders();
    } catch (error) {
      console.error("Error updating order:", error.message);
    }
  };

  useEffect(() => {
    const getShop = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/shops/getShops?accessToken=${access_token}`
        );

        setWebsitesData(res.data);
      } catch (error) {
        console.error("Error updating order:", error.message);
      }
    };

    getShop();
  }, []);

  useEffect(() => {
    const handleFilse = async () => {
      try {
        const dataFiles = {
          files: files,
          orderId: activeOrder.id,
          fileType: "DECLARATION",
        };
        await handleFileUpload(dataFiles);
        await refetchOrders();
        setFiles([]);
      } catch (err) {
        console.log(err);
        refetchOrders();
      } finally {
        const updatedOrder = orders.find(
          (order) => order.id === activeOrder.id
        );
        console.log("first");
        if (updatedOrder) {
          console.log("second");
          setActiveOrder(updatedOrder);
        }
      }
    };
    if (files?.length > 0) {
      handleFilse();
    }
  }, [files]);

  const DeleteConfirmation = (
    <div className="delete-item">
      <div className="delete-item-box">
        <div className="top">
          <div className="title">
            {Returntext(contentManagementData, "delete_Parcel_1", lang)}
          </div>
          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />}
            disabled={false}
            mode={"active"}
            onClick={() => setDeleteItem(false)}
          />
        </div>

        <div className="middle">
          <div className="txt">
            {Returntext(contentManagementData, "delete_Parcel_2", lang)}
          </div>
        </div>

        <div className="bottom">
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "cancel", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"white"}
            onClick={() => setDeleteItem(false)}
          />
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "delete", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"red"}
            onClick={() => {
              deleteDeclaration();
              setDeleteItem(false);
            }}
          />
        </div>
      </div>
    </div>
  );

  const DeleteFileConfirmation = (
    <div className="delete-item">
      <div className="delete-item-box">
        <div className="top">
          <div className="title">
            {Returntext(contentManagementData, "delete_Parcel_1", lang)}
          </div>
          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />}
            disabled={false}
            mode={"active"}
            onClick={() => {
              setDeleteFile(false);
              setActiveFile([]);
            }}
          />
        </div>

        <div className="middle">
          <div className="txt">
            {Returntext(contentManagementData, "delete_Parcel_2", lang)}
          </div>
        </div>

        <div className="bottom">
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "cancel", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"white"}
            onClick={() => {
              setDeleteFile(false);
              setActiveFile([]);
            }}
          />
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "delete", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"red"}
            onClick={() => {
              handleDelete(activeFile);
              setDeleteFile(false);
              setActiveFile([]);
            }}
          />
        </div>
      </div>
    </div>
  );

  const OrderInfoBox = (
    <div className="order-info-box">
      <ListItem
        hasBorder={false}
        hasPadding={false}
        hasIcon={true}
        headline={activeOrder.name}
        description={`${activeOrder.tracking_number} ${
          activeOrder?.weight !== null
            ? `(${activeOrder?.weight} ${Returntext(
                contentManagementData,
                "Kg",
                lang
              )})`
            : ""
        }`}
        icon={<BoxIcon className="icon" />} // Use the SVG component here
        buttons={
          !onAction ? (
            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "EditTitle", lang)}
              disabled={!activeOrder?.buttons?.declaration_enable}
              size={"standard"}
              icon={false}
              mainColor={"grayBorder"}
              onClick={() => setOnAction(true)}
            />
          ) : (
            <BtnStandardIconText
              mode=""
              txt={Returntext(contentManagementData, "cancel", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"white"}
              onClick={() => setOnAction(false)}
            />
          )
        }
      />
    </div>
  );

  const OnActionContent = (
    <div className="sub-wrap">
      <InputGz
        type={"text"}
        value={newName}
        setter={setNewName}
        label={Returntext(contentManagementData, "enterNewName", lang)}
        nid={"newname"}
        disabled={false}
        errorM={false}
      />
      <BtnStandardIconText
        mode=""
        txt={Returntext(contentManagementData, "updateTitle", lang)}
        disabled={false}
        size={"standard"}
        icon={false}
        mainColor={"black"}
        onClick={() => {
          activeOrder.name = newName;
          updateOrder();
          setOnAction(false);
          setNewName("");
        }}
        style={{ width: "max-content" }}
      />
      <div className="line" />
      <div className={`change-name-blur ${onAction && "on-action"}`} />
    </div>
  );

  const ProductDetailsTitle = (main, sub) => (
    <div className="txt-head">
      <div className="main">{main}</div>
      <div className="sub">{sub}</div>
    </div>
  );

  //
  const OrderTitleDetails = (
    <div className="order-title-details">
      {OrderInfoBox}
      {onAction && OnActionContent}
      {ProductDetailsTitle(
        Returntext(contentManagementData, "productDetails", lang),
        Returntext(contentManagementData, "productDetailsS", lang)
      )}
      <AutocompleteInput
        type={"text"}
        value={website}
        setter={setWebsite}
        label={Returntext(contentManagementData, "chooseWebsite", lang)}
        nid={"Choose website"}
        disabled={!activeOrder?.buttons?.declaration_enable}
        errorM={websiteError}
        sActive={sActive}
        setSActive={setSActive}
        data={websitesData}
      />
    </div>
  );
  //

  const PricingSection = (
    <div className="pricing">
      {ProductDetailsTitle(
        Returntext(contentManagementData, "pai", lang),
        Returntext(contentManagementData, "paiDesc", lang)
      )}

      {drawerBody}
      {activeOrder?.buttons?.declaration_enable && btn}

      <div className="line" />

      {ProductDetailsTitle(
        Returntext(contentManagementData, "AttachInvoice", lang),
        Returntext(contentManagementData, "InvoiceDesc", lang)
      )}

      <UploadBox
        setFiles={setFiles}
        files={files}
        activeOrder={activeOrder}
        type={"DECLARATION"}
        declareShow={!activeOrder?.buttons?.declaration_enable}
        setDeleteItem={setDeleteFile}
        setActiveFile={setActiveFile}
      />
    </div>
  );
  //
  const DeclareServicesSection = (
    <div className="declare-services">
      {ProductDetailsTitle(
        Returntext(contentManagementData, "ActiveServices", lang)
      )}

      <ListItem
        hasBorder={false}
        hasPadding={true}
        hasIcon={true}
        headline={Returntext(
          contentManagementData,
          "prices_benefits_box2_title",
          lang
        )}
        icon={<ShieldIcon className="icon" />} // Use the SVG component here
        buttons={
          <Toogle
            disabled={true}
            active={true}
            style={{ backgroundColor: "#d1cec7" }}
          />
        }
      />

      <ListItem
        hasBorder={false}
        hasPadding={true}
        hasIcon={true}
        headline={Returntext(
          contentManagementData,
          "prices_benefits_box1_title",
          lang
        )}
        icon={<BoxGreenIcon className="icon" />} // Use the SVG component here
        buttons={
          <Toogle
            disabled={true}
            active={true}
            style={{ backgroundColor: "#d1cec7" }}
          />
        }
      />
    </div>
  );
  //
  return (
    <div className={`declare-drawer-body ${onAction && "on-action"}`}>
      {deleteItem && DeleteConfirmation}
      {deleteFile && DeleteFileConfirmation}

      {OrderTitleDetails}
      {PricingSection}
      {DeclareServicesSection}
    </div>
  );
};

export default DeclareDrawerBody;
