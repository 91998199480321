import React, { useState } from "react";
import "./copyToClipBoard.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import { ReactComponent as CopyIcon } from "../../../icons/cp.svg";
import { ReactComponent as CopyTextIcon } from "../../../icons/cp-txt.svg";

const CopyToClipBoard = ({ textToCopy }) => {
  const [showSuccess, setShowSuccess] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setShowSuccess(true);

      setTimeout(() => {
        setShowSuccess(false);
      }, 1500);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="copy-to-clip-board">
      <BtnCircular
        size={"sm"}
        mainColor={"grayBorder"}
        svg={<CopyIcon />}
        disabled={false}
        onClick={handleCopy}
      />

      {showSuccess && (
        <CopyTextIcon className={`cp-txt ${!showSuccess && "hide"}`} />
      )}
    </div>
  );
};

export default CopyToClipBoard;
