import React from "react";
import "./orderTableProduct.scss";
import { ReactComponent as Wero } from "../../icons/wero.svg"; // Adjust the path as needed

const OrderTableProduct = () => {
  return (
    <div className="order-table-product">
      <Wero className="product" />
    </div>
  );
};

export default OrderTableProduct;
