import React from "react";
import "./navbarMobileAuth.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const NavbarMobileAuth = ({ cancelClick }) => {
  return (
    <div className="navbar">
      <img src="/images/icons/gz-lg.png" alt="" className="gz-icon" />

      <BtnCircular
        size={"md"}
        mainColor={"white"}
        svg={<CloseIcon />}
        disabled={false}
        mode={""}
        onClick={cancelClick}
      />
    </div>
  );
};

export default NavbarMobileAuth;
