import React from "react";
import "./priceItem.scss";
const PriceItem = ({ left, right }) => {
  return (
    <div className="price-item">
      <div className="left">{left}</div>
      <div className="right">{right}</div>
    </div>
  );
};

export default PriceItem;
