import React, { useState } from "react";
import "./autocomplete.scss";
import { ReactComponent as UpIcon } from "../../icons/up.svg";
import { ReactComponent as DownIcon } from "../../icons/down.svg"; // Import the SVG as a React component
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";

const Autocomplete = ({
  type,
  value,
  setter,
  label,
  nid,
  disabled,
  errorM,
  style,
  noBorder,
  sActive,
  setSActive,
  data,
}) => {
  const [lbClick, setLbClick] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = data.filter((country) => {
    return Object.values(country).some((value) =>
      String(value).toLowerCase().startsWith(searchTerm.toLowerCase())
    );
  });

  const cleanUp = () => {
    setSActive(false);
    setSearchTerm("");
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className={`autocomplete-ds ${sActive && "active"}`}>
      <div
        className={
          lbClick || value.length !== 0
            ? `autocomplete-gz-wrap focused ${noBorder && "no-border"} ${
                disabled ? "disabled" : ""
              } ${errorM ? "error" : ""}`
            : `autocomplete-gz-wrap ${noBorder && "no-border"} ${
                disabled ? "disabled" : ""
              } ${errorM ? "error" : ""}`
        }
        onFocus={() => setLbClick(true)}
        onBlur={() => setLbClick(false)}
        onClick={() => setSActive(!sActive)}
      >
        <div
          id={nid}
          name={nid}
          className="input-gz"
          type={type}
          value={value}
          disabled={disabled}
          style={style}
        >
          {value}
          {sActive ? (
            <UpIcon className="icon" />
          ) : (
            <DownIcon className="icon" />
          )}
        </div>
        <label htmlFor={nid} className="label">
          {label}
        </label>
      </div>

      {sActive && (
        <div className="select">
          <input
            className="search"
            placeholder="search ..."
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {searchTerm === "" && (
            <>
              <div className="item-group">
                <div className="title">
                  {Returntext(contentManagementData, "suggested", lang)}
                </div>
                <div className="items">
                  {filteredData.slice(0, 3).map((dt) => (
                    <div
                      className="item"
                      key={dt.code}
                      onClick={() => {
                        setter(`${dt.name} ${dt.dial_code}`);
                        cleanUp();
                      }}
                    >
                      <div className="wrap">
                        <img
                          className="left"
                          alt=""
                          src={`/images/flags/${dt.code.toLowerCase()}.png`}
                        />
                        <div className="middle">
                          <div className="code">{dt.dial_code}</div>
                          <div className="country">{dt.name}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="item-group">
                {Returntext(contentManagementData, "suggested", lang)}
                <div className="items">
                  {filteredData.slice(3).map((dt) => (
                    <div
                      className="item"
                      key={dt.code}
                      onClick={() => {
                        setter(`${dt.name} ${dt.dial_code}`);
                        cleanUp();
                      }}
                    >
                      <div className="wrap">
                        <img
                          className="left"
                          alt=""
                          src={`/images/flags/${dt.code.toLowerCase()}.png`}
                        />
                        <div className="middle">
                          <div className="code">{dt.dial_code}</div>
                          <div className="country">{dt.name}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {searchTerm !== "" && (
            <div className="item-group">
              <div className="title">Search Result</div>
              <div className="items">
                {filteredData.map((dt) => (
                  <div
                    className="item"
                    key={dt.code}
                    onClick={() => {
                      setter(`${dt.name} ${dt.dial_code}`);
                      cleanUp();
                    }}
                  >
                    <div className="wrap">
                      <img
                        className="left"
                        alt=""
                        src={`/images/flags/${dt.code.toLowerCase()}.png`}
                      />
                      <div className="middle">
                        <div className="code">{dt.dial_code}</div>
                        <div className="country">{dt.name}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
