import Lottie from "lottie-react";
import BoldTextLanding from "../../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../../components/atoms/LandingText/LightTextLanding";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";
import animationLocation from "../../../../assets/animations/location.json";
import animationDate from "../../../../assets/animations/dates.json";
import {
  deliver_img,
  orderAndDelivertFirstFrame,
} from "../../../../assets/landing";
import Toogle from "../../../../components/atoms/Toogle/Toogle";
import { useEffect, useRef, useState } from "react";

function OrderAndDelivery() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const [toggleActive, setToggleActive] = useState(false);

  const carRef = useRef(null);
  const [loopCar, setLoopCar] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !loopCar) {
            setLoopCar(true);
            setTimeout(() => {
              setLoopCar(false);
            }, 10);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (carRef.current) observer.observe(carRef.current);

    return () => observer.disconnect();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setToggleActive((prevToggleActive) => !prevToggleActive);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex_col delivery_container">
      <div className="small_cont flex_start_between ">
        <div
          className="flex_col max_width_responsive"
          style={{ gap: "24px", width: "50%" }}
        >
          <BoldTextLanding
            text={Returntext(
              contentManagementData,
              "delivery_orderAndDelivery_firstBox_title",
              lang
            )}
            text_color="#FFFDFA"
            text_size="48px"
          />
          <LightTextLanding
            text={Returntext(
              contentManagementData,
              "delivery_orderAndDelivery_firstBox_text",
              lang
            )}
            text_color="#FFFDFA"
            text_size="18px"
          />
        </div>

        <div
          className="animation-container max_width_responsive"
          style={{
            position: "relative",
            width: "50%",
            height: "50%",
            backgroundImage: `url(${orderAndDelivertFirstFrame})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            padding: "100px 20px 0px",
          }}
        >
          <Lottie
            animationData={animationDate}
            style={{
              width: "45%",
              height: "50%",
            }}
          />
          <div className="image_abs" ref={carRef}>
            <Lottie animationData={animationLocation} loop={loopCar} />
          </div>
        </div>
      </div>

      <div className="small_cont reverced_smappContainer flex_start_between">
        <div
          className="animation-container max_width_responsive secondAnimations_responsive"
          style={{
            backgroundImage: `url(${deliver_img})`,
          }}
        >
          <div className="image_abs_second">
            <div>
              <BoldTextLanding
                text={Returntext(
                  contentManagementData,
                  "Delivery - switcher_header",
                  lang
                )}
                text_color="#FFFDFA"
                text_size="16px"
              />
              <LightTextLanding
                text={Returntext(
                  contentManagementData,
                  "Delivery - switcher_text",
                  lang
                )}
                text_color="#FFFDFA"
                text_size="14px"
              />
            </div>
            <Toogle active={toggleActive} />
          </div>
        </div>
        <div
          className="flex_col max_width_responsive"
          style={{ gap: "24px", width: "50%" }}
        >
          <BoldTextLanding
            text={Returntext(
              contentManagementData,
              "delivery_orderAndDelivery_secondBox_title",
              lang
            )}
            text_color="#FFFDFA"
            text_size="48px"
          />
          <LightTextLanding
            text={Returntext(
              contentManagementData,
              "delivery_orderAndDelivery_secondBox_text",
              lang
            )}
            text_color="#FFFDFA"
            text_size="18px"
          />
        </div>
      </div>
    </div>
  );
}

export default OrderAndDelivery;
