import Lottie from "lottie-react";
import {
  arrowDown,
  usa,
  turkey,
  china,
} from "../../../assets/landing";
import BoldTextLanding from "../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../components/atoms/LandingText/LightTextLanding";
import TryOut from "../../../components/atoms/TryOut/TryOut";
import { useContent, useLanguages } from "../../../hooks/content";
import AskedQuestions from "../Delivery/helpers/AskedQuestions";
import { Returntext } from "../common/returnText";
import "../landingGlobal.scss";
import Benefits from "./helpers/Benefits";
import SimpleDelivery from "./helpers/SimpleDelivery";
import WhyUs from "./helpers/WhyUs";
import "./prices.scss";
import arrows from "../../../assets/animations/Arrow/data.json";
function Prices() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <>
      <div className="container_inside prices">
        <Lottie
          animationData={arrows}
          loop={false}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        />
        <div className="prices_texts">
          <LightTextLanding
            text={Returntext(contentManagementData, "prices_title", lang)}
            text_color="#000000"
            text_size="48px"
          />
          {/* <BoldTextLanding
            text_color="#000000"
            text={Returntext(contentManagementData, "prices_subtitle", lang)}
            text_size="92px"
          /> */}
          <div className="flex_start_between cent animation_container">
            <div className="flagItem">
              <img alt="" src={china} width={"72px"} loading="lazy" />
              {/* <BoldTextLanding
                text="$7.3"
                text_color="#000000"
                text_size="48px"
              /> */}
              <LightTextLanding
                text_color="#000000"
                text={Returntext(
                  contentManagementData,
                  "prices_flags_country_china",
                  lang
                )}
                text_size="24px"
              />
              <div className="soon">
                <LightTextLanding
                  text_color="#ffffff"
                  text={Returntext(contentManagementData, "prices_soon", lang)}
                  text_size="12px"
                />
              </div>
            </div>
            <div className="flagItem" style={{ backgroundColor: "#6AD67D" }}>
              <img alt="" src={usa} width={"72px"} loading="lazy" />
              <BoldTextLanding
                text={Returntext(
                  contentManagementData,
                  "prices_USD_price",
                  lang
                )}
                text_color="#FFFFFF"
                text_size="48px"
              />
              <LightTextLanding
                text_color="#FFFFFF"
                text={Returntext(
                  contentManagementData,
                  "prices_flags_country_usa",
                  lang
                )}
                text_size="24px"
              />
            </div>
            <div className="flagItem">
              <img alt="" src={turkey} width={"72px"} loading="lazy" />
              {/* <BoldTextLanding
                text="$7.3"
                text_color="#000000"
                text_size="48px"
              /> */}
              <LightTextLanding
                text_color="#000000"
                text={Returntext(
                  contentManagementData,
                  "prices_flags_country_turkey",
                  lang
                )}
                text_size="24px"
              />
              <div className="soon">
                <LightTextLanding
                  text_color="#ffffff"
                  text={Returntext(contentManagementData, "prices_soon", lang)}
                  text_size="12px"
                />
              </div>
            </div>
          </div>
        </div>
        <img alt="" src={arrowDown} loading="lazy" />
      </div>
      <WhyUs />
      <Benefits />
      <SimpleDelivery />
      <AskedQuestions showGreenPart={false} />

      <TryOut />
    </>
  );
}

export default Prices;
