import React from "react";
import BoldTextLanding from "../../atoms/LandingText/BoldtextLanding";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { Link } from "react-router-dom";

function HeaderLine() {
  const { contentManagementData } = useContent();
  const { lang } = useLanguages();

  return (
    <div
      style={{
        borderTop: "0.5px solid #343F46",
        padding: "16px",
        textAlign: "center",
        backgroundColor: "#121D25",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      {/* <Link to="http://usa.gzavnili.com/" relative="path" target='_blank' > */}
      <BoldTextLanding
        text_color="#FFFDFA"
        text={"26 ოქტომბერი Gzavnili.com-ში არასამუშაო დღეა 💚"}
        text_size="14px"
      />
      {/* </Link> */}
    </div>
  );
}

export default HeaderLine;
