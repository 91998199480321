import React, { useState } from "react";
import CustomBodyS1 from "../../molecules/CustomBodyS1/CustomBodyS1";
import CustomFooterS1 from "../../molecules/CustomFooterS1/CustomFooterS1";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import PaymentPopUp from "../../molecules/PaymentPopUp/PaymentPopUp";
import { useUpdateOrder } from "../../../hooks/orders";
import useUpdateOrderByNusxa from "../../../hooks/custom";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const CustomContentS1 = ({
  cancelClick,
  setTipsDrawerActive,
  setPInfoDrawer,
  activeOrder,
  setStep,
}) => {
  const [notPaid, setNotPaid] = useState(true);

  const { refetchOrdersOnSuccess } = useUpdateOrder();

  const { updateOrderByNusxa } = useUpdateOrderByNusxa();

  const handleUpdateManual = async () => {
    try {
      await updateOrderByNusxa({
        id: activeOrder?.id,
        newData: { custom_status: 2, DeclarationPaymentAuto: false },
      });
      refetchOrdersOnSuccess();
      setStep("2");
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateAuto = async () => {
    try {
      await updateOrderByNusxa({
        id: activeOrder?.id,
        newData: { custom_status: 2, DeclarationPaymentAuto: true },
      });
      refetchOrdersOnSuccess();
      setStep("2");
    } catch (error) {
      console.log(error);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="drawer-wrap">
      <div className="header">
        <div className="left"></div>

        <div className="middle">
          {Returntext(contentManagementData, "customDrawerT", lang)}
        </div>

        <BtnCircular
          size={"sm"}
          mainColor={"white"}
          svg={<CloseIcon />} // Use the SVG component here
          disabled={false}
          mode={"active"}
          onClick={cancelClick}
        />
      </div>

      <div className="body">
        <CustomBodyS1
          main={Returntext(contentManagementData, "customDrawerStep1MH", lang)}
          step={1}
          setTipsDrawerActive={setTipsDrawerActive}
          notPaid={notPaid}
          setNotPaid={setNotPaid}
          activeOrder={activeOrder}
        />
      </div>

      <div className="footer">
        <CustomFooterS1
          notPaid={notPaid}
          setPInfoDrawer={setPInfoDrawer}
          cancelClick={cancelClick}
          handleUpdateManual={handleUpdateManual}
          handleUpdateAuto={handleUpdateAuto}
        />
      </div>
    </div>
  );
};

export default CustomContentS1;
