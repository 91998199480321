import ActionTab from "../../ds/ActionTab/ActionTab";
import AddOrderBar from "../../ds/AddOrderBar/AddOrderBar";
import OrderTable from "../../ds/OrderTable/OrderTable";
import "./orders.scss";

import React from "react";

import NameBarMobile from "../../components/molecules/NameBarMobile/NameBarMobile";
import EmptyOrders from "../../components/molecules/EmptyOrders/EmptyOrders";

import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../Landings/common/returnText";
import { useOrders } from "../../hooks/orders";
import NoItems from "../../ds/NoItems/NoItems";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams } from "react-router-dom";

import { ReactComponent as OrdersEmptyIcon } from "../../icons/orders-empty.svg"; // Adjust the path as needed
import ActionTabV2 from "../../ds/ActionTabV2/ActionTabV2";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomDrawer,
  setDeclareDrawerActive,
  setDeliveryDrawer,
  setPaymentDrawer,
  setTrackingDrawerActive,
  setTrackingWay,
} from "../../redux/actions";

const Orders = ({
  setActiveOrder,
  setOrderStatusF,
  setVatDrawer,
  setActiveItem,
  setDeleteItem,
  setDeleteOrder,
  deleteOrder,
  payedDrawer,
  setPayedDrawer,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const { status } = useParams();

  const user = JSON.parse(localStorage.getItem("user"));
  const { orders, ordersLoading } = useOrders(user?.id, status);

  const vatClick = () => {
    setVatDrawer(true);
  };
  const trackingDrawerActive = useSelector(
    (state) => state.trackingDrawerActive
  );

  const declareDrawerActive = useSelector((state) => state.declareDrawerActive);
  const customDrawer = useSelector((state) => state.customDrawer);
  const paymentDrawer = useSelector((state) => state.paymentDrawer);
  const deliveryDrawer = useSelector((state) => state.deliveryDrawer);
  const trackingWay = useSelector((state) => state.trackingWay);

  const dispatch = useDispatch();

  const DrawerTrigger = (setDrawerActive, drawerActive) => {
    setDrawerActive(!drawerActive);
  };

  return (
    <div className="orders">
      <div className="main-content">
        <MobileView>
          <ActionTab setOrderStatusF={setOrderStatusF} />
        </MobileView>

        <BrowserView>
          <ActionTabV2 setOrderStatusF={setOrderStatusF} />
        </BrowserView>

        <AddOrderBar
          onClick={() =>
            dispatch(setTrackingDrawerActive(!trackingDrawerActive))
          }
        />
        <NameBarMobile trackingClick={() => dispatch(!trackingDrawerActive)} />
        {orders.length === 0 && !ordersLoading ? (
          <>
            <BrowserView>
              <NoItems
                icon={OrdersEmptyIcon}
                main={
                  lang === "ka"
                    ? `${Returntext(
                        contentManagementData,
                        status + "_TAB",
                        lang
                      )} ${Returntext(
                        contentManagementData,
                        "empty_orders_main",
                        lang
                      )}`
                    : `${Returntext(
                        contentManagementData,
                        "empty_orders_main",
                        lang
                      )} ${Returntext(
                        contentManagementData,
                        status + "_TAB",
                        lang
                      )}`
                }
                sub={Returntext(
                  contentManagementData,
                  "empty_orders_sub",
                  lang
                )}
                positionAbsolute={true}
              />
            </BrowserView>
            <MobileView>
              <EmptyOrders
                icon={<OrdersEmptyIcon />}
                main={Returntext(
                  contentManagementData,
                  "empty_orders_main",
                  lang
                )}
                sub={Returntext(
                  contentManagementData,
                  "empty_orders_sub",
                  lang
                )}
                onClick={() =>
                  dispatch(setTrackingDrawerActive(!trackingDrawerActive))
                }
              />
            </MobileView>
          </>
        ) : (
          <OrderTable
            orders={orders}
            declareClick={() =>
              dispatch(setDeclareDrawerActive(!declareDrawerActive))
            }
            setActiveOrder={setActiveOrder}
            trackingCick={() =>
              dispatch(setTrackingDrawerActive(!trackingDrawerActive))
            }
            customClick={() => dispatch(setCustomDrawer(!customDrawer))}
            paymentClick={() => dispatch(setPaymentDrawer(!paymentDrawer))}
            payedClick={() => DrawerTrigger(setPayedDrawer, payedDrawer)}
            deliveryClick={() => dispatch(setDeliveryDrawer(!deliveryDrawer))}
            trackingWayClick={() => dispatch(setTrackingWay(!trackingWay))}
            vatClick={() => vatClick()}
            setActiveItem={setActiveItem}
            setDeleteItem={setDeleteItem}
            setDeleteOrder={setDeleteOrder}
            deleteOrder={deleteOrder}
          />
        )}
      </div>
    </div>
  );
};

export default Orders;
