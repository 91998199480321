import DeliveryBox from "../../../components/atoms/DeliveryBox/DeliveryBox";
import DeliveryMap from "../../../components/atoms/DeliveryBox/DeliveryMap";
import BoldTextLanding from "../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../components/atoms/LandingText/LightTextLanding";
import BtnStandardLanding from "../../../ds/BtnStandardLanding/BtnStandardLanding";
import "./delivery.scss";
import "../landingGlobal.scss";
import OrderAndDelivery from "./helpers/OrderAndDelivery";
import AskedQuestions from "./helpers/AskedQuestions";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../common/returnText";
import animationData1 from "../../../assets/animations/icons/1/data.json";
import animationData2 from "../../../assets/animations/icons/2/data.json";
import animationData3 from "../../../assets/animations/icons/3/data.json";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import deliveryMainAnimation from "../../../assets/animations/Header Image/data.json";
import Toogle from "../../../components/atoms/Toogle/Toogle";
import { useEffect, useState } from "react";

function Delivery() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const navigate = useNavigate();
  const [toggleActive, setToggleActive] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setToggleActive((prevToggleActive) => !prevToggleActive);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="deliveryContainer">
        <div className="textBoxes">
          <div className="texts">
            <BoldTextLanding
              text={Returntext(contentManagementData, "delivery_h1", lang)}
              text_color="#fffdfa"
              text_size="56px"
            />
            <LightTextLanding
              text_color="#fffdfa"
              text={Returntext(contentManagementData, "delivery_h2", lang)}
              text_size="20px"
            />
            <div>
              <BtnStandardLanding
                mode="active"
                txt={Returntext(
                  contentManagementData,
                  "delivery_first_btn",
                  lang
                )}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"green"}
                onClick={() => navigate("/sign-up")}
              />
            </div>
          </div>
          <div className="imageCoverCont">
            <div className={`image_abs_second `}>
              <div>
                <BoldTextLanding
                  text={Returntext(
                    contentManagementData,
                    "Delivery - switcher_header",
                    lang
                  )}
                  text_color="#000000"
                  text_size="16px"
                />
                <LightTextLanding
                  text={Returntext(
                    contentManagementData,
                    "Delivery - switcher_text",
                    lang
                  )}
                  text_color="#000000"
                  text_size="14px"
                />
              </div>
              <Toogle active={toggleActive} />
            </div>
            <Lottie
              animationData={deliveryMainAnimation}
              className="mainIMG"
              loop={false}
            />
          </div>
        </div>
        <div className="time_container flex_center">
          <div className="container_inside">
            <div>
              <BoldTextLanding
                text={Returntext(
                  contentManagementData,
                  "delivery_boxes_title",
                  lang
                )}
                text_color="#000000"
                text_size="56px"
              />
            </div>
            <div className="deliveryBoxes flex_between">
              <DeliveryBox
                logoName={animationData1}
                text1={Returntext(
                  contentManagementData,
                  "delivery_first_box_title",
                  lang
                )}
                text2={Returntext(
                  contentManagementData,
                  "delivery_first_box_text",
                  lang
                )}
              />
              <DeliveryBox
                logoName={animationData2}
                text1={Returntext(
                  contentManagementData,
                  "delivery_second_box_title",
                  lang
                )}
                text2={Returntext(
                  contentManagementData,
                  "delivery_second_box_text",
                  lang
                )}
              />

              <DeliveryBox
                logoName={animationData3}
                text1={Returntext(
                  contentManagementData,
                  "delivery_third_box_title",
                  lang
                )}
                text2={Returntext(
                  contentManagementData,
                  "delivery_third_box_text",
                  lang
                )}
              />
            </div>
            <DeliveryMap
              text={Returntext(
                contentManagementData,
                "delivery_map_text",
                lang
              )}
            />
          </div>
        </div>
        <OrderAndDelivery />
        <AskedQuestions />
      </div>
    </>
  );
}
export default Delivery;
