import React from "react";
import "./actionTabV2.scss";
import ActionTabBtnV2 from "../ActionTabBtnV2/ActionTabBtnV2";
import { useStatusQuantity } from "../../hooks/orders";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ClockIcon } from "../../icons/aw.svg";
import { ReactComponent as ClockActiveIcon } from "../../icons/aw-active.svg";
import { ReactComponent as BoxIcon } from "../../icons/iw.svg";
import { ReactComponent as BoxActiveIcon } from "../../icons/iw-active.svg";
import { ReactComponent as RoadIcon } from "../../icons/wr.svg";
import { ReactComponent as RoadActiveIcon } from "../../icons/wr-active.svg";
import { ReactComponent as Arr } from "../../icons/arw.svg";
import { ReactComponent as Arrac } from "../../icons/arw-active.svg";
import { ReactComponent as ShelfIcon } from "../../icons/rc.svg";
import { ReactComponent as ShelfActiveIcon } from "../../icons/rc-active.svg";

const ActionTabV2 = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { status } = useParams();
  const { statusQuantity } = useStatusQuantity(user?.id);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const navigate = useNavigate();

  const tabs = [
    {
      txt: Returntext(contentManagementData, "AWAITING_TAB", lang),
      value: "AWAITING",
      svg: ClockIcon,
      svgActive: ClockActiveIcon,
      count:
        statusQuantity.AWAITING !== undefined ? statusQuantity.AWAITING : 0,
    },
    {
      txt: Returntext(contentManagementData, "RECEIVED_TAB", lang),
      value: "RECEIVED",
      svg: BoxIcon,
      svgActive: BoxActiveIcon,
      count:
        statusQuantity.RECEIVED !== undefined ? statusQuantity.RECEIVED : 0,
    },
    {
      txt: Returntext(
        contentManagementData,
        "ON_THE_WAY_FROM_US_WAREHOUSE_TAB",
        lang
      ),
      value: "ON_THE_WAY_FROM_US_WAREHOUSE",
      svg: RoadIcon,
      svgActive: RoadActiveIcon,
      count:
        statusQuantity.ON_THE_WAY_FROM_US_WAREHOUSE !== undefined
          ? statusQuantity.ON_THE_WAY_FROM_US_WAREHOUSE
          : 0,
    },
    {
      txt: Returntext(
        contentManagementData,
        "RECEIVED_IN_TBILISI_WAREHOUSE_TAB",
        lang
      ),
      value: "RECEIVED_IN_TBILISI_WAREHOUSE",
      svg: Arr,
      svgActive: Arrac,
      count:
        statusQuantity.RECEIVED_IN_TBILISI_WAREHOUSE !== undefined
          ? statusQuantity.RECEIVED_IN_TBILISI_WAREHOUSE
          : 0,
    },
    {
      txt: Returntext(contentManagementData, "DELIVERED_TAB", lang),
      value: "DELIVERED",
      svg: ShelfIcon,
      svgActive: ShelfActiveIcon,
      count:
        statusQuantity.DELIVERED !== undefined ? statusQuantity.DELIVERED : 0,
    },
  ];

  return (
    <div className="action-tab-2">
      {tabs.map((item, i) => (
        <ActionTabBtnV2
          key={item.value}
          txt={item.txt}
          svg={item.svg}
          svgActive={item.svgActive}
          count={item.count}
          active={status === item.value}
          onClick={() => navigate("/orders/" + item.value)}
          inactive={i === 4 || i === 0 ? true : false}
        />
      ))}
    </div>
  );
};

export default ActionTabV2;
