import BoldTextLanding from "../LandingText/BoldtextLanding";
import "./tryout.scss";
import BtnStandardLanding from "../../../ds/BtnStandardLanding/BtnStandardLanding";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { arrow } from "../../../assets/landing";
import { useNavigate } from "react-router-dom";
function TryOut() {
  const { lang } = useLanguages();
  const navigate = useNavigate();
  const { contentManagementData } = useContent();
  return (
    <div className="container_inside subscribeBox">
      <div className="green_cont">
        <div className="flex_between">
          <BoldTextLanding
            text={Returntext(
              contentManagementData,
              "about_delivery_tryBox_text",
              lang
            )}
            text_color="#000000"
            text_size="24px"
          />

          <div style={{}} className="arrow_button">
            <img src={arrow} alt="" />
            <BtnStandardLanding
              mode="active"
              txt={Returntext(
                contentManagementData,
                "about_delivery_tryBox_btn",
                lang
              )}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"black"}
              onClick={() => navigate("/sign-up")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TryOut;
