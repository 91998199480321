import { benefits_box1, benefits_box2 } from "../../../../assets/landing";
import BoldTextLanding from "../../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../../components/atoms/LandingText/LightTextLanding";
import { useContent, useLanguages } from "../../../../hooks/content";
import { Returntext } from "../../common/returnText";

function Benefits() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="container_inside benefits">
      <BoldTextLanding
        text_color="#000000"
        text={Returntext(contentManagementData, "prices_benefits_title", lang)}
        text_size="56px"
      />
      <div className="container_inside benefit_boxes">
        <div className="benefits_box1">
          <img alt="" src={benefits_box1} width="100%" loading="lazy" />
          <div>
            <BoldTextLanding
              text={Returntext(
                contentManagementData,
                "prices_benefits_box1_title",
                lang
              )}
              text_size="32px"
            />
            <LightTextLanding
              text={Returntext(
                contentManagementData,
                "prices_benefits_box1_text",
                lang
              )}
              text_size="18px"
            />
          </div>
        </div>
        <div className="benefits_box2">
          <img alt="" src={benefits_box2} width="100%" loading="lazy" />
          <div>
            <BoldTextLanding
              text_color="#FFFDFA"
              text={Returntext(
                contentManagementData,
                "prices_benefits_box2_title",
                lang
              )}
              text_size="32px"
            />
            <LightTextLanding
              text_color="#FFFDFA"
              text={Returntext(
                contentManagementData,
                "prices_benefits_box2_text",
                lang
              )}
              text_size="18px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
