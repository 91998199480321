import React, { useState } from "react";
import "./signUpForm.scss";
import SignUpEnterEmail from "../../molecules/SignUpScreens/SignUpEnterEmail";
import SignUpOtp from "../../molecules/SignUpScreens/SignUpOtp";
import SignUpSetPassword from "../../molecules/SignUpScreens/SignUpSetPassword";

const SignUpForm = ({
  screenNumber,
  setScreenNumber,
  email,
  setEmail,
  phone,
  setPhone,
  sMethod,
  SetSMethod,
  activeMode,
  setActiveMode,
  countryData,
  setCountryData,
  password,
  setPassword,
  passwordCheck,
  setPasswordCheck,
}) => {
  const [cSelectActive, setCSelectActive] = useState(false);

  return (
    <div className="sign-up-form">
      {screenNumber === 0 && (
        <SignUpEnterEmail
          email={email}
          setEmail={setEmail}
          sMethod={sMethod}
          SetSMethod={SetSMethod}
          phone={phone}
          setPhone={setPhone}
          activeMode={activeMode}
          setActiveMode={setActiveMode}
          countryData={countryData}
          setCountryData={setCountryData}
          setScreenNumber={setScreenNumber}
          cSelectActive={cSelectActive}
          setCSelectActive={setCSelectActive}
        />
      )}
      {screenNumber === 1 && (
        <SignUpOtp
          email={email}
          sMethod={sMethod}
          phone={phone}
          setEmail={setEmail}
          cSelectActive={cSelectActive}
          setCSelectActive={setCSelectActive}
          setScreenNumber={setScreenNumber}
        />
      )}
      {screenNumber === 2 && (
        <SignUpSetPassword
          email={email}
          phone={phone}
          password={password}
          setPassword={setPassword}
          passwordCheck={passwordCheck}
          setPasswordCheck={setPasswordCheck}
          activeMode={activeMode}
        />
      )}
    </div>
  );
};

export default SignUpForm;
