import React from "react";
import "./deliverySettingsBody.scss";
import AddressesBox from "../../molecules/AddressesBox/AddressesBox";

const DeliverySettingsBody = ({
  setActiveItem,
  activeItem,
  deleteItem,
  setDeleteItem,
  setAddAddressPopUp,
  setEditAddressPopUp,
}) => {
  return (
    <div className="delivery-settings-body">
      <AddressesBox
        setActiveItem={setActiveItem}
        activeItem={activeItem}
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        setAddAddressPopUp={setAddAddressPopUp}
        setEditAddressPopUp={setEditAddressPopUp}
      />
      {/* <DeliverySettingsDetails /> */}
    </div>
  );
};

export default DeliverySettingsBody;
