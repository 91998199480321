import React from "react";
import "./walletBody.scss";
import WalletBox from "../../molecules/WalletBox/WalletBox";
import TransactionsBody from "../../molecules/TransactionsBody/TransactionsBody";

const WalletBody = ({
  setActiveItem,
  activeItem,
  deleteItem,
  setDeleteItem,
  setShowModal,
  saveCard,
  setPDrawerActive,
}) => {
  return (
    <div className="wallet-body">
      <WalletBox
        setActiveItem={setActiveItem}
        activeItem={activeItem}
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        setShowModal={setShowModal}
        saveCard={saveCard}
        setPDrawerActive={setPDrawerActive}
      />
      <TransactionsBody />
    </div>
  );
};

export default WalletBody;
