import React from "react";
import "./actionTabCircularBtnMobile.scss";

const ActionTabCircularBtnMobile = ({
  active,
  count,
  svg: SvgIcon,
  inactive,
  svgActive: SvgActiveIcon,
}) => {
  return (
    <div className={`action-tab-circular-btn-m ${active ? "active" : ""} `}>
      {active ? (
        <SvgActiveIcon className="icon" />
      ) : (
        <SvgIcon className="icon" />
      )}{" "}
      <div
        className={`count-circle ${!active && count > 0 && inactive && "ca"}`}
      >
        {count}
      </div>
    </div>
  );
};

export default ActionTabCircularBtnMobile;
