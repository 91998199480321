import React, { useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";
import InputGz from "../../../ds/InputGz/InputGz";
import MapWithAutocomplete from "../GMap/MapWithAutocomplete";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import "./addressDrawerBody.scss";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../../icons/work.svg";
import { ReactComponent as OtherIcon } from "../../../icons/other.svg";
import axios from "axios";

const AddressDrawerBody = ({
  tag,
  setTag,
  title,
  setTitle,
  setAutocomplete,
  address,
  setAddress,
  city,
  setCity,
  instructions,
  setInstructions,
  setCurrentLocation,
  currentLocation,
  autocomplete,
}) => {
  const mapStyles = {
    height: "344px",
    width: "100%",
    borderRadius: "16px",
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  useEffect(() => {
    if (autocomplete) {
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Place not found");
          return;
        }
        const { formatted_address } = place;
        setAddress(formatted_address);
        setCurrentLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      });
    }
  }, [autocomplete, address]);

  useEffect(() => {
    // Fetch city using reverse geocoding
    const fetchCity = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );

        if (response.data.results.length > 0) {
          const addressComponents = response.data.results[0].address_components;
          for (let component of addressComponents) {
            if (component.types.includes("locality")) {
              setCity(component.long_name);
              break;
            }
          }
        }
      } catch (error) {
        console.error("Error fetching city:", error);
      }
    };

    fetchCity();
  }, [currentLocation]);

  return (
    <div className="address-drawer-body">
      <div className="tags">
        <BtnStandardIconText
          mode={tag === "home" ? "active" : ""}
          txt={Returntext(contentManagementData, "home", lang)}
          disabled={false}
          size={"sm"}
          icon={true}
          svg={<HomeIcon />}
          iconPosition={"left"}
          mainColor={"grayBorder"}
          onClick={() => setTag("home")}
        />
        <BtnStandardIconText
          mode={tag === "work" ? "active" : ""}
          txt={Returntext(contentManagementData, "work", lang)}
          disabled={false}
          size={"sm"}
          icon={true}
          svg={<WorkIcon />}
          iconPosition={"left"}
          mainColor={"grayBorder"}
          onClick={() => setTag("work")}
        />
        <BtnStandardIconText
          mode={tag === "other" ? "active" : ""}
          txt={Returntext(contentManagementData, "other", lang)}
          disabled={false}
          size={"sm"}
          icon={true}
          svg={<OtherIcon />}
          iconPosition={"left"}
          mainColor={"grayBorder"}
          onClick={() => setTag("other")}
        />
      </div>

      <div className="form-wrap">
        <div className="inputs">
          <InputGz
            type={"text"}
            value={title}
            setter={setTitle}
            label={Returntext(contentManagementData, "Title", lang)}
            nid={"title"}
            disabled={false}
            errorM={false}
            noBorder={true}
            style={{ borderBottom: "1px solid #d1cec7" }}
          />
          <Autocomplete
            onLoad={(autocomplete) => setAutocomplete(autocomplete)}
            options={{
              placeholder: "",
              componentRestrictions: { country: "ge" },
            }}
            className="autocomplete"
          >
            <InputGz
              type={"text"}
              value={address}
              setter={setAddress}
              nid={Returntext(contentManagementData, "address", lang)}
              disabled={false}
              errorM={false}
              noBorder={true}
              hasLabel={true}
            />
          </Autocomplete>
          <InputGz
            type={"text"}
            value={city}
            setter={setCity}
            label={Returntext(contentManagementData, "city", lang)}
            nid={"city"}
            disabled={true}
            errorM={false}
            noBorder={true}
            style={{ borderBottom: "1px solid #d1cec7" }}
          />
          <InputGz
            type={"text"}
            value={instructions}
            setter={setInstructions}
            label={Returntext(
              contentManagementData,
              "AdditionalInformation",
              lang
            )}
            nid={"Additional instructions"}
            disabled={false}
            errorM={false}
            noBorder={true}
          />
        </div>
      </div>

      <div className="mp">
        <MapWithAutocomplete
          setStreetName={setAddress}
          setCurrentLocation={setCurrentLocation}
          currentLocation={currentLocation}
          mapStyles={mapStyles}
        />
      </div>
    </div>
  );
};

export default AddressDrawerBody;
