import React, { useState, useEffect, useRef } from "react";
import "./popOverOrder.scss";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as PopOverIcon } from "../../../icons/popOver.svg";
import { ReactComponent as DeleteIcon } from "../../../icons/delete-r.svg";

const OrderPopOver = ({ item, setActiveItem, setDeleteItem }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const [active, setActive] = useState(false);
  const popOverRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem("user"));

  const handleClickOutside = (event) => {
    if (popOverRef.current && !popOverRef.current.contains(event.target)) {
      setActive(false);
    }
  };

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [active]);

  return (
    <div className="pop-over-order" ref={popOverRef}>
      <PopOverIcon className="icon" onClick={() => setActive(!active)} />

      {active && (
        <div className="actions">
          <div
            className="p-btn red"
            onClick={() => {
              setActiveItem(item);
              setDeleteItem(true);
              setActive(!active);
            }}
          >
            <DeleteIcon />
            <span>{Returntext(contentManagementData, "delete", lang)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderPopOver;
