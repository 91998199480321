import { useState, useEffect } from "react";
import "./cookies.scss"; // Import your SCSS file for styling
import BoldTextLanding from "../../../components/atoms/LandingText/BoldtextLanding";
import LightTextLanding from "../../../components/atoms/LandingText/LightTextLanding";
import BtnStandardLanding from "../../../ds/BtnStandardLanding/BtnStandardLanding";
import Toogle from "../../../components/atoms/Toogle/Toogle";

function CookiesPolicy() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPrefferences, setPrefferences] = useState(false);
  const [toggleActive, setToggleActive] = useState({
    item1: true,
    item2: false,
    item3: false,
  });

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem("hasAcceptedCookies");
    if (!hasAcceptedCookies) {
      setModalOpen(true);
    }
  }, []);

  const closeModal = () => {
    setModalOpen(false);
    setPrefferences(false);
    document.body.style.overflow = "";
    localStorage.setItem("hasAcceptedCookies", "true");
  };

  const prefferences = () => {
    setModalOpen(false);
    setPrefferences(true);
  };

  const saveSelected = () => {
    setPrefferences(false);
    localStorage.setItem("hasAcceptedCookies", "true");
    document.body.style.overflow = "";
  };
  const handleDrawerToggle = (itemName) => {
    setToggleActive((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));
  };
  const content = [
    {
      subheader: "სავალდებულო",
      text: "ფუნქციური ქუქი-ფაილები გვეხმარება გარკვეული დავალებების შესრულებაში, როგორიცაა ვებგვერდის კონტენტის გაზიარება სოციალური მედიის პლატფორმებზე, უკუკავშირის შეგროვება და მესამე მხარის სხვა ფუნქციების გამოყენება.",
    },
    {
      subheader: "ანალიტიკური",
      text: "ანალიტიკური ქუქიები გამოიყენება იმის გასაანალიზებლად, თუ როგორი სახის ინტერაქციები აქვთ ვიზიტორებს ვებ.გვერდთან. ეს ქუქი-ფაილები გვეხმარებიან ისეთი ინფორმაციის შეგროვებაში როგორიცაა: ვიზიტორთა რაოდენობა, უკან დაბრუნების მაჩვენებელი, ტრაფიკის წყარო და ა.შ.",
    },

    {
      subheader: "სამუშაო",
      text: "სამუშაო ქუქიები გამოიყენება ვებსაიტის მუშაობის ძირითადი ინდექსების გასაანალიზებლად, რაც გვეხმარება შევქმნათ უკეთესი სამომხმარებლო გამოცდილება ვიზიტორებისთვის.",
    },
  ];

  return (
    <div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">
              {/* <span className="close" onClick={closeModal}> */}

              <BoldTextLanding
                text="დაეთანხმე Cookies"
                text_color="#000000"
                text_size="18px"
              />
              <LightTextLanding
                text="ჩვენ ვიყენებთ ქუქიებს სამომხმარებლო გამოცდილების გაუმჯობესებისა და ტრაფიკის გასაანალიზებლად. თანხმობის ღილაკზე დაჭერით, თქვენ ეთანხმებით ქუქიების გამოყენების წესებს."
                text_color="#000000"
                text_size="16px"
              />
              <div className="cookiebtn">
                <BtnStandardLanding
                  mode="active"
                  txt={"ვეთანხმები"}
                  disabled={false}
                  size={"standard"}
                  icon={false}
                  mainColor={"green"}
                  styles={{
                    padding: "20px",
                  }}
                  onClick={closeModal}
                />
                <BtnStandardLanding
                  mode="active"
                  txt={"პრეფერენციები"}
                  disabled={false}
                  size={"standard"}
                  icon={false}
                  mainColor={"white"}
                  styles={{
                    backgroundColor: "white",
                    border: "1px solid #D1CEC7",
                    padding: "20px",
                  }}
                  onClick={prefferences}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isPrefferences && (
        <div className="modal-overlay">
          <div className="modalPref">
            <div className="modal-content">
              {/* <span className="close" onClick={closeModal}> */}

              <BoldTextLanding
                text="დაეთანხმე Cookies"
                text_color="#000000"
                text_size="18px"
              />
              <LightTextLanding
                text='ჩვენ ვიყენებთ ქუქიებს, რათა დაგეხმაროთ ვებსაიტზე ეფექტურად ნავიგაციასა და სხვადასხვა ფუნქციის შესრულებაში. თქვენ თითოეული კატეგორიის მიხედვით იხილავთ დეტალურ ინფორმაციას ყველა ქუქის შესახებ.
                ქუქიები, რომლებიც კატეგორიზებულია, როგორც "აუცილებელი" ინახება თქვენს ბრაუზერში, რადგან ისინი საჭიროა საიტის ძირითადი ფუნქციების გამოსაყენებლად.'
                text_color="#000000"
                text_size="14px"
              />
              {content.map((i, index) => {
                return (
                  <div className="toggleItem">
                    <div key={index} className="typeOfCookies">
                      <BoldTextLanding
                        text={i.subheader}
                        text_color="#000000"
                        text_size="14px"
                      />
                      <LightTextLanding
                        text={i.text}
                        text_color="#000000"
                        text_size="14px"
                      />
                    </div>
                    <Toogle
                      active={toggleActive[`item${index + 1}`]}
                      onClick={() => handleDrawerToggle(`item${index + 1}`)}
                    />
                  </div>
                );
              })}
              <div className="cookiebtn">
                <BtnStandardLanding
                  mode="active"
                  txt={"დაეთანხმე ყველას"}
                  disabled={false}
                  size={"standard"}
                  icon={false}
                  mainColor={"green"}
                  styles={{
                    padding: "20px",
                  }}
                  onClick={closeModal}
                />
                <BtnStandardLanding
                  mode="active"
                  txt={"არჩეულის შენახვა"}
                  disabled={false}
                  size={"standard"}
                  icon={false}
                  mainColor={"white"}
                  styles={{
                    backgroundColor: "white",
                    border: "1px solid #D1CEC7",
                    padding: "20px",
                  }}
                  onClick={saveSelected}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CookiesPolicy;
