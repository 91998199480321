import React, { useEffect, useState } from "react";
import "./languageBtn.scss";
import { useLanguages } from "../../../hooks/content";

const LanguageBtn = ({ burgerComp = false }) => {
  const initialLanguage = localStorage.getItem("language") || "ka";
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
  const { refetchLang } = useLanguages();
  const changeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ka" : "en";
    setCurrentLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };
  useEffect(() => {
    refetchLang();
    // eslint-disable-next-line
  }, [currentLanguage]);

  return burgerComp ? (
    <div
      style={{ cursor: "pointer", display: "flex", gap: "10px", width: "100%" }}
      onClick={changeLanguage}
    >
      <img className="lang-icon" alt="" src="/images/icons/lang-icon.png" />
      <button style={{ backgroundColor: "white", border: "none" }}>
        {currentLanguage === "en" ? "English (United States)" : "ქართული"}
      </button>
    </div>
  ) : (
    <div
      className="language_change"
      style={{ cursor: "pointer" }}
      onClick={changeLanguage}
    >
      <img className="lang-icon" alt="" src="/images/icons/lang-icon.png" />
      <button>{currentLanguage === "en" ? "en" : "ქა"}</button>
    </div>
  );
};

export default LanguageBtn;
