import { useQuery } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";
import refreshAccessToken from "./refresh";

export const useTimeSlot = () => {
  const { isLoading, error, data, refetch } = useQuery(
    ["timeSlots"],
    async () => {
      try {
        const access_token = Cookies.get("access_token");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/district/timeSlots?accessToken=${access_token}`
        );
        return response.data;
      } catch (err) {
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          refreshAccessToken();
          const access_token = Cookies.get("access_token");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/district/timeSlots?accessToken=${access_token}`
          );
          return response.data;
        } else {
          console.log("Error:", err);
          localStorage.setItem("user", null);
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          return [];
        }
      }
    }
  );

  let timeSlots = data ? data : [];

  return {
    timeSlots,
    timeSlotsLoading: isLoading,
    timeSlotsError: error,
    refetchTimeSlots: refetch,
  };
};
